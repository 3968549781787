<template>
  <v-card  elevation="2" style="min-height:800px;" ref="test">
    <h2 class="mx-3" style="margin-top:8px;">{{ uma_name }}</h2>
    <v-divider class="mx-2"></v-divider>
    <v-col cols="12">

      <v-row>
        <v-col class="py-1" v-if="res" v-html="getBlead()">
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-table>
            <thead>
              <tr>
                <th v-for="head in tableHeaders" class="text-center" :key="head" style="height:44px;min-width:150px;">{{ head }}</th>
              </tr>
            </thead>
            <tbody v-if="res">
              <tr v-for="row in 16" :key="row">
                <template v-for="col in 5" :key="col">
                  <td v-if="rowSpans[row-1][col-1]" :rowspan="rowSpans[row-1][col-1]" v-html="getCol(row,col)" class="text-center" style="height:44px" ></td>
                </template>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>

    </v-col>
  </v-card>
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

export default {
  name: 'Data-horse-blad',
  props:{
    umaId:{
      default:"",
    },
    umaName:{
      default:"",
    },
    isDialog:{
      default:false,
    },
  },
  components: {
    
  },
  data: () => ({
    value:value.VALUE,
    uma_id:"",
    uma_name:"",
    res:null,
    inbliead:[],
    same:{},
    tableHeaders:["1代前","2代前","3代前","4代前","5代前"],
    rowSpans:[],
    kettous:[],
    keturyou:[50.0,25.0,12.5,6.25,3.125],
  }),
  watch:{

  },
  created:function(){
    if(this.$route.params.umaId)this.uma_id = this.$route.params.umaId;
    else this.uma_id = this.umaId;

    if(this.$route.params.umaName)this.uma_name = this.$route.params.umaName;
    else this.uma_name = this.umaName;

    for(let n=0;n<16;n++){
      this.rowSpans.push([]);
      for(let i=0;i<5;i++){
        this.rowSpans[n].push(this.getSpan(i,n));
      }
    }

    this.fetchData();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    getSpan(x,y){
      var rowSpan = 0;
      switch(x){
        case 0:
          if(y%8.0 == 0.0)rowSpan = 8;
          else rowSpan = 0;
          break;
        case 1:
          if(y%4.0 == 0.0)rowSpan = 4;
          else rowSpan = 0;
          break;
        case 2:
          if(y%2.0 == 0.0)rowSpan = 2;
          else rowSpan = 0;
          break;
        default:
          rowSpan = 1;
      }

      return rowSpan;
    },
    getBlead(){
      if(this.res == null){
        console.log("null");
        return "";
      }
      console.log(this.same);
      var same = this.same;

      if(Object.keys(same) == 0){
        return '<p>アウトブリード<p>';
      }
      else{
        var res = "";

        for(let key in same){
          let indexs = same[key];
          let str = "";
          let val = 0.0;

          if(key == "-1")return "";
          
          for(let i=0;i<indexs.length;i++){
            val += this.keturyou[indexs[i]-1];
            if(str == "")str += ""+indexs[i];
            else str += "x"+indexs[i];
          }

          let redCss = "";
          if(val == 18.75)redCss = "text-red font-weight-bold";

          res += `<p class="${redCss}">${key} ${val.toFixed(2)}% ${str}</p>`;
        }
        return res;
      }
    },
    getCol(row,col){

      const y = row-1;
      const x = col-1;

      var num = 0;

      switch(x){
        case 0:
          if(y == 0)num = 0;
          else num = 1;
          break;
        case 1:
          num = y/4+2;
          break;
        case 2:
          num = y/2+6;
          break;
        case 3:
          num = y+14
          break;
        case 4:
          num = y*2+30;
          break;
        default:
          return "";
      }

      if(this.kettous.length > num){
        let kettou = this.kettous[num].kettou;
        if(x != 4){
          if(num%2 == 0){
            return `<p class="osu">${kettou}</p>`;
          }
          else{
            return `<p class="mesu">${kettou}</p>`;
          }
        }
        else{
          let str = `<p class="osu">${kettou}</p>`;
          if(this.kettous.length > num+1)str += `<p class="mesu">${this.kettous[num+1].kettou}</p>`;
          return str;
        }
      }

      return "";
      
    },
    fetchData(){
      var params = new URLSearchParams();
      params.append("uma_id", this.uma_id);

      axios.post(process.env.VUE_APP_POST_URL+"/race/get_keitou_detaile.php",params)      
        .then(response => {
          
        if(response.data.length == 0){
          this.res = null;
          return;
        }

        this.res = response.data;

        this.kettous = this.res.kettou;

        var sameId = [];
        var dic = {};

        for(let i=0;i<this.kettous.length;i++){
          let id = this.kettous[i].kettou;
          if(id && id != ""){
            if(dic[id] == undefined)dic[id] = 0;
              dic[id] += 1;
              if(dic[id] == 2)sameId.push(id);
            }
          else{
            sameId.push("-1");
            continue;
          }
        }

        this.inbliead = sameId;

        let same = {};
        for(let i=0;i<sameId.length;i++){
          let id = sameId[i];
          if(id == "-1"){
            same[id] = [-1];
            continue;
          }

          for(let m=0;m<62;m++){
            if(this.kettous.length > m){
              let name = this.kettous[m].kettou;
              if(name && id == name){
                let index = 1;
                if(m > 29)index = 5;
                else if(m > 13)index = 4;
                else if(m > 5)index = 3;
                else if(m > 1)index = 2;

                if(same[name] == undefined)same[name] = [];
                same[name].push(index);
              }
            }
          }
        }

        this.same = same;
      })
      .catch(error => {
        console.log(error);
      });

    },
  },
  computed:{
  }
}

</script>

<style scoped>
:deep(.osu){
  font-weight:bold;
  color: #4cc9ff;
}
:deep(.mesu){
  font-weight:bold;
  color: #ff67c2;
}

table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}
</style>