// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VCalendar } from 'vuetify/labs/VCalendar'

const theme = {
  primary: "#03a9f4",
  secondary: "#00bcd4",
  accent: "#f44336",
  error: "#ffeb3b",
  warning: "#ff5722",
  info: "#607d8b",
  success: "#8bc34a"
};

export default createVuetify({
  components: {
    VCalendar,
  },
  theme:{
    themes:{
      light:theme,
      dark:theme,
    },
  },
})
