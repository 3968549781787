<template>
  <v-card  elevation="2">
    <h2 class="mx-3" style="margin-top:8px;">{{ kisyu_name }}</h2>
    <v-divider class="mx-2"></v-divider>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="5">
          <v-carousel :key="images" height="300" show-arrows="hover" hide-delimiter-background style="background-color:darkgray;">
            <template v-for="image in images" :key="image">
              <v-carousel-item contain :src="getImage(image)"></v-carousel-item>
            </template>
          </v-carousel>
          <div class="d-flex justify-end d-none">
            <v-btn class="my-1" @click="postDialog = true;">写真投稿</v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <v-sheet class="w-100" elevation="2">
            <div class="px-10 w-100 d-flex justify-space-between">
              <v-btn @click="clickArrow(-1)" icon="mdi-chevron-left"></v-btn>
              <h3 class="align-self-center">{{ date }}年</h3>
              <v-btn @click="clickArrow(1)" icon="mdi-chevron-right"></v-btn>
            </div>
            <v-row>
              <v-col cols="6">
                <div class="w-100">
                  <Doughnut class="ch_title ch_label" style="height:240px;" data-unit="%" :data="tanData" :options="chartOptions" />
                </div>
                <div class="w-100 my-2 text-center font-weight-bold">
                  <p>単勝回収率</p>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="w-100">
                  <Doughnut class="ch_title ch_label" style="height:240px;" data-unit="%" :data="hukuData" :options="chartOptions" />
                </div>
                <div class="w-100 my-2 text-center font-weight-bold">
                  <p>複勝回収率</p>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet class="w-100 pa-2" elevation="2">
                <v-table density="compact" class="text-center w-100">
                  <thead>
                    <tr>
                      <th style="width:25%;"></th>
                      <th colspan="3">
                        <div class="d-flex justify-space-around text-center font-weight-bold">
                          <div>単勝率</div>
                          <div>連対率</div>
                          <div>複勝率</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="index in 4" :key="index">
                      <td>
                        <div class="d-flex flex-column">
                          <p class="font-weight-bold">{{ statsTitles[index-1] }}</p>
                          <p style="font-size: 12px;color: darkgray;">{{ stats[index-1][3] }}レース</p>
                        </div>
                      </td>
                      <td colspan="3">
                        <div class="w-100 flex-column">
                          <div class="d-flex w-100 justify-space-around">
                            <div v-for="i in 3" class="text-center" :key="i">{{ stats[index-1][i-1] ? stats[index-1][i-1].toFixed(1)+"%" : "" }}</div>
                          </div>
                          <div class="w-100" style="position:relative;height:10px;">
                            <div v-for="i in 4" class="anim" :style="`position:absolute;top:0;left:0;height:100%;width:${stats[index-1][4-i]}%;background-color:${cols[4-i]};`" :key="i"></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
            
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet class="w-100 pa-2" elevation="2">
            <v-tabs class="w-100 mb-tabs" v-model="tab_corse" color="#03a9f4" density="compact" >
              <v-tab @click="clickCorse(0)" value="0">芝</v-tab>
              <v-tab @click="clickCorse(1)" value="1">ダート</v-tab>
              <v-tab @click="clickCorse(2)" value="2">障害</v-tab>
            </v-tabs>
            <v-table density="compact" class="border_tabl w-100">
              <thead>
                <tr>
                  <th class="font-weight-bold text-center" style="width:25%;">枠順</th>
                  <th colspan="3">
                    <div class="d-flex justify-space-around text-center font-weight-bold">
                      <div>単勝率</div>
                      <div>連対率</div>
                      <div>複勝率</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="index in value.WAKU_COLOR.length-1" class="text-center" :key="index">
                  <td :style="wakuColor(index)" style="height:44px;">{{ index }}枠</td>
                  <td  colspan="3" style="height:44px;">
                    <div class="d-flex flex-column" style="height:44px;">
                      <div class="d-flex justify-space-around text-center" style="height: 26px;">
                        <div v-for="n in 3" :key='index+","+n' class="align-self-center">{{ wakus[index-1][n-1] ? (wakus[index-1][n-1]).toFixed(1)+"%" : "" }}</div>
                      </div>
                      <div class="w-100" style="position:relative">
                        <template v-for="n in 3">
                          <div v-if="wakus[index-1][3-n]" class="anim" :style='"position:absolute;left:0;top:0;height:10px;background-color:"+cols[3-n]+";width:"+wakus[index-1][3-n]+"%;"' :key='index+","+n'></div>
                        </template>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-sheet>
        </v-col>
        
      </v-row>


    </v-col>
  </v-card>

  <v-dialog v-model="postDialog" class="w-dialog">
    <v-card class="w-100" >
      <Coming />
    </v-card>
  </v-dialog>

</template>

<script>
import Coming from "../Coming.vue";

import value from "../../global.js"
import axios from 'axios'
import { useHead } from '@unhead/vue'
import { Doughnut} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
  name: 'Data-horse-blad',
  props:{
    kisyuId:{
      default:"",
    },
    kisyuName:{
      default:"",
    },
    isDialog:{
      default:false,
    },
  },
  components: {
    Doughnut,
    Coming,
  },
  data: () => ({
    value:value.VALUE,
    kisyu_id:"",
    kisyu_name:"",
    res:null,
    postDialog:false,
    date:"",
    images:[],
    tab_corse:0,
    statsTitles:["合計","芝","ダート","障害"],
    chartOptions:{
      responsive: true,
      maintainAspectRatio: false,
      plugins:{
        legend: {
          display:false,
        },
      },
    },
    emptyData : {
      labels: ["",""],
      datasets: [{
        label: "",
        data: [],
        hoverOffset: 4,
      }],
    },
    tanData:{},
    hukuData:{},
    stats:[[],[],[],[]],
    wakus:[],
    cols:["#7ffefd","#81e9ff","#7fbafe","#ff7e7f"],
  }),
  watch:{
    kisyuId(val){
      this.kisyu_id = val;
      this.initial();
    },
    kisyuName(val){
      this.kisyu_name = val;
      if(!this.isDialog){
        useHead(this.getHead());
      }
    },
  },
  created:function(){
    if(this.$route.params.kisyuId && this.$route.params.kisyuId != -1){
      this.kisyu_id = this.$route.params.kisyuId;
      this.kisyu_name = this.$route.params.kisyuName;
    }
    else{
      this.kisyu_id = this.kisyuId;
      this.kisyu_name = this.kisyuName;
    }
    if(!this.isDialog){
      useHead(this.getHead());
    }
    this.initial();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){
      this.tanData = this.emptyData;
      this.hukuData = this.emptyData;

      for(let n=0;n<8;n++){
        this.wakus.push([]);
        for(let i=0;i<3;i++){
          this.wakus.push([0,0,0]);
        }
      }

      this.date = new Date().getFullYear();

      this.fetchData();
      this.fetchImage();
    },
    getHead(){
      return {
        title: this.kisyu_name+"のデータ",
        meta:[
          { name:"description",content:"騎手の詳細データ。年代別に、コース別勝率、回収率や枠別傾向を表示。" },
          { property: "og:type", content: "website" },
          { property: "og:url", content: process.env.VUE_APP_DOMAIN+"/stats/kisyu/"+this.kisyu_id+"/"+this.kisyu_name },
          { property: "og:title", content: this.uma_name+"のデータ" },
          { property: "og:description", content: "騎手の詳細データ。年代別に、コース別勝率、回収率や枠別傾向を表示。" },
          { property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
          { property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
        ],
      };
    },
    clickArrow(del){
      this.date += del;
      if(this.date < 2010)this.date += 1;
      if(this.date > new Date().getFullYear())this.date -= 1;
      else{
        this.fetchData();
      }
    },
    getImage(image){
      if(image == -1){
        return require("@/assets/image/no_image.webp");
      }
      
      return process.env.VUE_APP_DOMAIN+"/post_images"+image.path;
    },
    clickCorse(knd){
      this.tab_corse = knd;
      this.setData();
    },
    setData(){
      if(this.res ==  null)return;

      this.tanData = this.emptyData;
      this.hukuData = this.emptyData;
      this.stats = [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]];

      this.wakus = [];
      for(let n=0;n<8;n++){
        this.wakus.push([0,0,0]);
      }

      let sum = 0;
      let sumTan = 0;
      let sumRen = 0;
      let sumHuku = 0;
      let kaiTan = 0;
      let kaiHuku = 0;

      var base = [this.res.siba,this.res.dart,this.res.syou];
      for(let n=0;n<3;n++){
        var tan = 0;
        var ren = 0;
        var huku = 0;
        if(base[n].count){
          sum += base[n].count;
          tan += base[n].rank1;
          ren += base[n].rank1+base[n].rank2;
          huku += base[n].rank1+base[n].rank2+base[n].rank3;

          sumTan += tan;
          sumRen += ren;
          sumHuku += huku;


          if(base[n].tan)kaiTan += base[n].tan-0.0;
          if(base[n].huku1)kaiHuku += base[n].huku1-0.0;
          if(base[n].huku2)kaiHuku += base[n].huku2-0.0;
          if(base[n].huku3)kaiHuku += base[n].huku3-0.0;

          this.stats[n+1] = [tan/base[n].count*100,ren/base[n].count*100,huku/base[n].count*100,base[n].count];
        }
      }

      if(sum != 0){
        let tan = kaiTan/sum;
        let vals = [0,0];
        let labels = ["",""]
        if(tan < 100){
          labels = ["1着","2着~"]
          vals = [Math.floor(tan*10)/10,Math.floor((100-tan)*10)/10]; 
        }
        else{
          labels["1着"];
          vals = [100];
        }

        this.tanData = {
          labels: labels,
          datasets: [{
            label: tan.toFixed(1)+"%",
            data: vals,
            hoverOffset: 4,
            backgroundColor:["#7ffffe","#ff7e7f"],
          }],
        };

        let huku = Math.floor(kaiHuku/sum*10)/10;
        vals = [0,0];
        labels = ["",""]
        if(huku < 100){
          labels = ["~3着","4着~"];
          vals = [Math.floor(huku*10)/10,Math.floor((100.0-huku)*10)/10]; 
        }
        else{
          labels = ["~3着"];
          vals = [100];
        }

        this.hukuData = {
          labels: labels,
          datasets: [{
            label: huku.toFixed(1)+"%",
            data: vals,
            hoverOffset: 4,
            backgroundColor:["#7ffffe","#ff7e7f"],
          }],
        };

        this.stats[0] = [sumTan/sum*100,sumRen/sum*100,sumHuku/sum*100,sum];
      }


      var wakus = null;
      if(this.tab_corse == 0){
        wakus = this.res.siba.waku;
      }else if(this.tab_corse == 1){
        wakus = this.res.dart.waku;
      }
      else{
        wakus = this.res.syou.waku;
      }

      //console.log(wakus);
      if(wakus.length > 0){
        for(let n=0;n<8;n++){
          if(wakus[n] && wakus[n].length >= 3){
            this.wakus[n] = [wakus[n][0]*100,wakus[n][1]*100,wakus[n][2]*100];
          }
        }
      }

    },
    fetchData(){
      var params = new URLSearchParams();
      params.append("kisyu_id", this.kisyu_id);
      params.append("date", this.date);

      axios.post(process.env.VUE_APP_POST_URL+"/race/get_kisyu_detail.php",params)      
        .then(response => {
          
        if(response.data.length == 0){
          this.res = null;
          return;
        }

        this.res = response.data;

        this.setData();
      })
      .catch(error => {
        console.log(error);
      });

    },
    fetchImage(){
      var params = new URLSearchParams();
      params.append("kisyu_id", this.kisyu_id);

      axios.post(process.env.VUE_APP_POST_URL+"/race/get_kisyu_image.php",params)      
        .then(response => {
          
        if(response.data.length == 0){
          return;
        }

        this.images = response.data.images;
        if(this.images == null || this.images == undefined || this.images.length <= 0){
          this.images = [-1];
        }
        console.log(this.images);
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  computed:{
    wakuColor(){
      return (index) => {
        if(index == 2){
          return {"background-color":this.value.WAKU_COLOR[index],"color":"#fff"}
        }
        return {"background-color":this.value.WAKU_COLOR[index],"color":"#000" }
      };
    },
  }
}

</script>

<style scoped>

.anim{
  transition: .3s all;
}

:deep(.osu){
  font-weight:bold;
  color: #4cc9ff;
}
:deep(.mesu){
  font-weight:bold;
  color: #ff67c2;
}

.border_table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
.border_table thead th:last-child {
  border-right : 1px solid #CCC;
}

.border_table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
.border_table tbody td:last-child{
  border-right : 1px solid #CCC;
}
</style>