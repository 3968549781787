<template>
  <v-card  elevation="2" style="overflow-y: scroll;">
    <RaceListItem :race="currentRace" />
    <v-tabs class="" v-model="tab_baken" color="#03a9f4" density="compact" >
      <v-tab @click="clickBaken" value="0">単勝</v-tab>
      <v-tab @click="clickBaken" value="1">複勝</v-tab>
    </v-tabs>
    <v-divider class="mx-2"></v-divider>
    
    <v-col cols="12">
      <v-row>
        <v-col class="" cols="12">
          <Line :data="data" :options="lineOptions" class="line-chart w-100" />
        </v-col>      
      </v-row>

      <v-row>
        <v-col class="" cols="12">
          <v-tabs class="w-100 mb-tabs" v-model="tab_knd" color="#03a9f4" density="compact" >
            <v-tab @click="clickKnd" value="0">オッズ</v-tab>
            <v-tab @click="clickKnd" value="1">得票数</v-tab>
            <v-tab @click="clickKnd" value="2">人気</v-tab>
          </v-tabs>
          <v-tabs class="w-100 mb-tabs" v-model="tab_period" color="#03a9f4" density="compact" >
            <v-tab @click="clickPeriod" :disabled="!this.user.isPurchase" value="0">5分</v-tab>
            <v-tab @click="clickPeriod" :disabled="!this.user.isPurchase" value="1">30分</v-tab>
            <v-tab @click="clickPeriod" value="2">1時間</v-tab>
            <v-tab @click="clickPeriod" value="3">3時間</v-tab>
          </v-tabs>
          <span v-if="!user.isPurchase" class="ml-5 align-self-center" style="font-size: 14px;">5,30分間隔はプレミアム機能</span>
          
        </v-col>
        <v-col cols="12">
          <v-table class="w-100" density="compact">
            <thead class="">
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="horse in horses" :key="horse" class="" style="padding:0;">
                <td class="py-0" style="overflow-y: hidden;">
                  <div class="w-100 d-flex justify-space-between py-0" >
                    <div class="d-flex justify-start py-0">
                      <div class="text-center font-weight-bold waku h-100" :style='[horse.waku ? wakuColor(horse.waku) : "" ]' >
                        <p style="line-height: 42px;">{{ horse.umaban ? horse.umaban : "" }}</p>
                      </div>
                      <p class="text-center align-self-center" :style='[ horse.mark && horse.mark.isRed ? "color:red;width:30px;" : "width:30px;"]'>{{ horse.mark ? decodeURI(horse.mark.mark) : "-" }}</p>
                      <p class="align-self-center">{{ horse.name }}</p>
                    </div>
                    <div class="d-flex justify-end">
                      <div class="text-center">
                        <p>{{ horse.ninki ? horse.ninki+"人気" : "" }}</p>
                        <p>{{ horse.odds ? horse.odds : "" }}</p>
                      </div>
                      <div>
                        <v-checkbox class="ml-4" density="compact" @change="clickCheck(horse)" :model-value="horse.isSelect" style="height:42px;"></v-checkbox>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>      
      </v-row>

    </v-col>
  </v-card>
</template>

<script>
import value from "../../global.js"
import axios from 'axios'
import zoom from 'chartjs-plugin-zoom';

import RaceListItem from "./RaceListItem.vue";

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';



ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale,zoom);


export default  {
  name: 'Odds-corse',
  props:{
    currentRace:{
      default:{},
    },
    Horses:{
      default:[],
    },
  },
  components: {
    RaceListItem,
    Line,
  },
  data: () => ({
    value:value.VALUE,
    user:{},
    resTan:null,
    reshuku:null,
    horses:[],
    tab_baken:0,
    tab_knd:0,
    tab_period:0,

    data:null,

    tanData:{
      datas : [],
      times : [],
    },
    hukuData:{
      datas : [],
      times : [],
    },
    times:[],
    
    lineOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:10, boxHeight:10,font:{size:12}},
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: 'xy',
          }
        }
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          min:0,
          ticks:{
            stepSize:1,
          },
        },
        y:{
          id:"y",
          position:"right",
          min:0,
          scaleLabel:{
            display:true,
            fontColor:"#ff0000",
          },
          ticks:{
            stepSize:30,
            callback:(value) => value,
          },
        },
      },
    },
  }),
  watch:{
  },
  created:function(){
    this.user = this.$store.getters.user;

    this.horses = JSON.parse(JSON.stringify(this.Horses));
    this.horses = this.horses.sort(function(a,b){
      return (a.umaban < b.umaban ? -1 : 1);
    });
    this.horses.map(function(elm){
      elm.isSelect = true;
    });

    if(!this.user.isPurchase){
      this.tab_period = 2;
    }

    this.data = {
        labels: [""],
        datasets: [{
          label: "",
          data: [],
          hoverOffset: 4,
        }],
      };

    this.fecthData();
  },
  mounted:function(){
  },
  methods:{
    initial(){

    },
    clickCheck(horse){
      horse.isSelect = !horse.isSelect;
      this.drawTan();
    },
    clickBaken(){
      if(this.tab_baken == 0){
        this.drawTan();
      }
      else{
        if(this.hukuData.datas.length == 0){
          this.fecthHukuData();
        }
        else this.drawTan();
      }
    },
    clickKnd(){
      this.drawTan();
    },
    clickPeriod(){
      this.drawTan();
    },
    getXaxis(value){
      if(value > this.times.length){
        return this.times[value];
      }
      return "";
    },
    dataSetTan(){
      if(this.resTan == null){
        this.data = {
          labels: [""],
          datasets: [{
            label: "",
            data: [],
            hoverOffset: 4,
          }],
        };
      }
      else{
        let oddsData = [];
        let ninkiData = [];
        let hyouData = [];
        let times = [];

        for(let n=0;n<this.horses.length;n++){
          oddsData.push([]);
          ninkiData.push([]);
          hyouData.push([]);
        }

        const count = this.resTan.odds.split("!");
        let x = 0;
        for(let n=0;n<count.length;n++){
          let detail = count[n].split("#");
          if(detail.length > 2){
            let od = detail[2].split(",");
            let totalNum = 0.0;
            let sum = 0.0;
            let ninki = [];

            if(!isNaN(detail[1])){
              totalNum = detail[1]-0.0;
            }

            for(let i=0;i<od.length;i++){
              let uma = od[i].split("&");
              if(uma.length > 1){
                let index = uma[0]-1;

                let val = 0.0;
                if(uma[1] != ""){
                  if(!isNaN(uma[1])){
                    val = uma[1]-0.0;
                  }
                }

                oddsData[index].push({x:x,y:val});
                ninki.push({odds:val,ban:index});

                sum += 1.0/val;
              }
            }

            ninki.sort(function(a,b){
              return (a.odds < b.odds ? -1 : 1);
            });

            for(let i=0;i<ninki.length;i++){
              ninkiData[ninki[i].ban].push({x:x,y:i+1});
            }

            let r = 100/sum*0.01;
            for(let i=0;i<od.length;i++){
              let uma = od[i].split("&");
              if(uma.length > 1){
                let index = uma[0]-1;

                let val = 0.0;
                if(uma[1] != ""){
                  if(!isNaN(uma[1])){
                    val = 1/uma[1]*r*totalNum;
                  }
                }

                hyouData[index].push({x:x,y:val});
              }
            }
            times.push(detail[0]);
            x += 1;
          }
        }

        this.tanData = {
          datas : [oddsData,hyouData,ninkiData],
          times : times,
        };

        this.drawTan();
        
      }
    },
    dataSetHuku(){
      if(this.resHuku == null){
        this.data = {
          labels: [""],
          datasets: [{
            label: "",
            data: [],
            hoverOffset: 4,
          }],
        };
      }
      else{
        let oddsData = [];
        let ninkiData = [];
        let hyouData = [];
        let times = [];

        for(let n=0;n<this.horses.length;n++){
          oddsData.push([]);
          ninkiData.push([]);
          hyouData.push([]);
        }

        const count = this.resHuku.odds.split("!");
        let x = 0;
        for(let n=0;n<count.length;n++){
          let detail = count[n].split("#");
          if(detail.length > 2){
            let od = detail[2].split(",");
            let totalNum = 0.0;
            let sum = 0.0;
            let ninki = [];

            if(!isNaN(detail[1])){
              totalNum = detail[1]-0.0;
            }

            for(let i=0;i<od.length;i++){
              let uma = od[i].split("&");
              if(uma.length > 1){
                let index = uma[0]-1;

                let val = 0.0;
                if(uma[1] != ""){
                  let ar = uma[1].split("-")
                  if(ar.length > 1){
                    if(!isNaN(ar[0])){
                      val = ar[0];
                    }
                  }
                }

                oddsData[index].push({x:x,y:val});
                ninki.push({odds:val,ban:index});

                sum += 1.0/val;
              }
            }

            ninki.sort(function(a,b){
              return (a.odds < b.odds ? -1 : 1);
            });

            for(let i=0;i<ninki.length;i++){
              ninkiData[ninki[i].ban].push({x:x,y:i+1});
            }

            let r = 100/sum*0.01;
            for(let i=0;i<od.length;i++){
              let uma = od[i].split("&");
              if(uma.length > 1){
                let index = uma[0]-1;

                let val = 0.0;
                if(uma[1] != ""){
                  let ar = uma[1].split("-")
                  if(ar.length > 1){
                    if(!isNaN(ar[0])){
                      val = 1/ar[0]*r*totalNum;
                    }
                  }
                }

                hyouData[index].push({x:x,y:val});
              }
            }
            times.push(detail[0]);
            x += 1;
          }
        }

        this.hukuData = {
          datas : [oddsData,hyouData,ninkiData],
          times : times,
        };

        this.drawTan();
        
      }
    },
    drawTan(){
      var data = [];
      if(this.tab_baken == 0){
        data = this.tanData.datas[this.tab_knd];
        this.times = this.tanData.times;
      }
      else {
        data = this.hukuData.datas[this.tab_knd];
        this.times = this.hukuData.times;
      }

      const interval = [0,4,9,27];
      const inter = interval[this.tab_period];

      let drawData = [];

      for(let n=0;n<this.horses.length;n++){
        drawData.push([]);
        for(let i=0;i<data[n].length;i++){
          if(this.tab_period == 0 || i%inter == 0){
            drawData[n].push(data[n][i]);
          }
        }
      }
      

      let dataSet = [];

      for(let n=0;n<this.horses.length;n++){
        let horse = this.horses[n];
        if(horse.isSelect){
          let DataSet = {
            label: horse.name.slice(0,3),
            data: drawData[n],
            borderColor: this.value.WAKU_COLOR[horse.waku],
            backgroundColor:this.value.WAKU_COLOR[horse.waku],
            yAxisID:"y",
            borderWidth:1,
            pointStyle:false,
          };
          dataSet.push(DataSet);
        }
        
      }

      const max = [[150,null,this.horses.length],[30,null,this.horses.length]];
      const stepY = [10,100,1];

      let opt = JSON.parse(JSON.stringify(this.lineOptions));



      opt.scales.y.max = max[this.tab_baken][this.tab_knd];
      opt.scales.y.ticks = {
        stepSize:stepY[this.tab_knd]
      };
      opt.scales.xAxis.ticks = {
        stepSize:10,
        callback:(value) => {
          if(value > this.times.length){
            return this.times[value];
          }
          return "";
        },
      };

      this.lineOptions = opt;


      this.data = {
        datasets: dataSet,
      };      
            
    },
    fecthData(){
      var params = new URLSearchParams();
      params.append("race_id", this.currentRace.id);
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_time_odds.php",params)      
        .then(response => {
        
          const res = response.data;

          //console.log(res);

          if(res){
            this.resTan = res;
            this.dataSetTan();
          }
                
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
    fecthHukuData(){
      var params = new URLSearchParams();
      params.append("race_id", this.currentRace.id);
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_time_odds_fuku.php",params)      
        .then(response => {
        
          const res = response.data;

          //console.log(res);

          if(res){
            this.resHuku = res;
            this.dataSetHuku();
          }
                
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
  },
  computed:{
    wakuColor(){
      return (waku) => {
        if(waku == 1){
          return {"background-color":"transparent","color":"#000"};
        }
        else if(waku == 2){
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#fff"};
        }
        else{
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#000"};
        }
      }
    },
    options(){
      return this.lineOptions;
    }
  }
}

</script>

<style scoped>

.waku{
  width:30px;
}

table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  /*border-left : 1px solid #CCC;*/
  padding:4px !important;
}
table thead th:last-child {
  /*border-right : 1px solid #CCC;*/
}

table tbody td {
  border-top : 1px solid #CCC;
  /*border-left : 1px solid #CCC;*/
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}


.line-chart{
  height:500px !important;
  margin:0 auto;
  background-color: lightGray;
}

/*@media screen and (max-width: 600px) {
  .line-chart{
    height:500px !important;
  }
}*/

</style>