<template>
  <div class="d-flex w-100 justify-space-between" style="height:44px;min-height:44px;">
    <div class="collItem align-self-center">
      <p class="font-weight-bold">{{ kaime.ban }}</p>
    </div>
    <div class="collItem align-self-center text-center" style="width:70%;">
      <p :class="getFontSize(baken)" v-html="kaime.name"></p>
    </div>
    <div class="collItem d-flex flex-column text-right">
      <p class="font-weight-bold">{{ kaime.odd }}</p>
      <p style="font-size:12px;">{{ kaime.ninki }}人気</p>
    </div>
  </div>
  <v-divider class="mx-0 my-0"></v-divider>
</template>
<script>
import value from "../../global.js"

export default {
  name: 'Baken-item-odds',
  props:{
    kaime:{
      default:{},
    },
    horName:{
      default:"",
    },
    baken:{
      default:0,
    },
  },
  data: () => ({
    value:value.VALUE,
  }),
  created:function(){
    this.initial();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
  },
  computed:{
    getFontSize(){
      return (knd) => {
        if(knd == 2){
          return "mb-font";
        }
        else{
          return "normal-font";
        }
      }
    }
  }
};

</script>


<style scoped>

.normal-font{
  font-size:12px;
}
.mb-font{
    font-size:12px;
}
@media screen and (max-width: 600px) {
  .mb-font{
    font-size:10px;
  }
}
.collItem{
  width: 33%;
}
</style>
