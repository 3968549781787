<template>
	<v-list>
    <template v-for="(comment,index) in res" :key="comment">
      <CommentItem @clickComment="clickComment(comment)" @clickIcon="clickIcon(comment)" :IsNew="true" :index="index" :comment="comment"/>
    </template>
  </v-list>

  <!-- race diaolog -->
  <v-dialog v-model="raceDialog" class="w-dialog pa-0">
    <div class="d-flex justify-end">
      <v-btn class="mb-2" icon @click="raceDialog = false" >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <RaceDetaileContainer :CurrentRace="modalRace" :IsModal="true" :TabKnd="6" :key="modalRace"/>
  </v-dialog>

</template>

<script>
import axios from 'axios';

import CommentItem from './CommentItem.vue';

import RaceDetaileContainer from "@/components/race/RaceDetaileContainer.vue"

export default {
	name: 'Board-list',
  props:{
  },
	components: {
		CommentItem,
    RaceDetaileContainer,
	},
	data: () => ({
    res:[],
    modalRace:null,
    raceDialog:false,
	}),
  created:function(){
    this.fetchComment();
  },
	methods:{
    clickComment(comment){
      this.modalRace = comment.race;
      this.raceDialog = true;
    },
    clickIcon(comment){
      this.$emit("clickIcon",comment);
    },
		fetchComment:function(){
			var params = new URLSearchParams();
      params.append("page", 0);

         
      axios.post(process.env.VUE_APP_POST_URL+"/news/get_new_race_board_v3.0.0.php",params)      
        .then(response => {
          
        const res = response.data;
        this.res = res;
        console.log(res);

      })
      .catch(error => {
        console.log(error);
      });
		}
	}
}
</script>