
<template>
  <v-container>
    <v-col cols="12">
      <v-card class="mx-auto" elevation="8">
        <v-card-title class="text-h3 my-2">Coming soon</v-card-title>
        <v-img height="250" src="@/assets/image/coming.webp" ></v-img>
        
        <v-card-text>
          現在開発中です。もうしばらくお待ちください。
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>

</template>

<script>
export default {
	name: 'Coming-',
}


</script>