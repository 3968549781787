<template>
  <div class="d-flex w-100 justify-space-between" style="min-height:56px;">
    <div class="d-flex" style="width:50%;">
      <div class="collItem">
        <div class="d-flex align-center h-100">
          <div class="h-100" style="padding: 16px 4px;" :style="wakuColor(Waku.waku)">
            <p class="font-weight-bold align-self-center">{{ Waku.waku }}</p>
          </div>
          <div class="align-self-center ml-1">
            <p v-for="name in Waku.name" style="width:50px;font-size:12px;text-align:center;" :key="name">{{ name }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-start" style="width:50%;" >
      <div v-for="index in 3" :class='[ isShow[index-1] == 2 ? "round-check align-self-center" : "align-self-center"]' style="width:33%;" :key="index" >
        <v-checkbox v-if="isShow[index-1] > 0" v-model:model-value="checks[index-1]" @change="clickCheck(index-1)" rounded="xl" class="d-flex align-center" color="#03a9f4"></v-checkbox>
      </div>
    </div>
  </div>
  <v-divider class="mx-0 my-0"></v-divider>
</template>
<script>
import value from "../../global.js"

export default {
  name: 'Baken-waku-item-',
  emits:["clickCheck"],
  props:{
    Waku:{
      default:{},
    },
    baken:{
      default:0,
    },
    knd:{
      default:0,
    },
    index:{
      default:0,
    },
    select:{
      default:[false,false,false,false]
    },
    isShow:{
      default:[0,0,0],
    }
  },
  data: () => ({
    value:value.VALUE,
    checks:[false,false,false],
  }),
  created:function(){
    this.initial();
  },
  mounted:function(){
    
  },
  watch:{
    select(){
      this.checks = this.select;
    },
  },
  methods:{
    initial(){
      this.checks = this.select;
    },
    clickCheck(col){
      this.$emit("clickCheck",this.baken,this.knd,this.index,col,this.checks[col]);
    },
  },
  computed:{
    wakuColor(){
      return (waku) => {
        if(waku == 1){
          return {"background-color":"transparent","color":"#000"};
        }
        else if(waku == 2){
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#fff"};
        }
        else{
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#000"};
        }
      }
    },
  }
};

</script>


<style scoped>
.collItem{
  width: 20%;
}
</style>

<style>
.round-check .mdi-checkbox-blank-outline::before {
  content: "\F0130";
}


.round-check .mdi-checkbox-marked::before {
  content: "\F0133";
}

</style>