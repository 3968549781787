<template>
  <v-card elevation="2" style="overflow-y: scroll;">
    <v-col class="px-6 pt-5" cols="12">
      <v-row>
        <div class="d-flex w-100" style="height: 96px;">
          <div style="width:96px;position:relative;">
            <v-img class="rounded-circle icon" contain :src="getIcon(user.icon,user.myid)" />
            <v-img class="rounded-circle waku" contain :src="getFrame(user.frameName)" />
          </div>
          <p class="pa-2 ml-2">{{ decodeURI(user.intro) }}</p>
          <div>
          </div>
        </div>
        <p class="px-2">{{ decodeURI(user.userName) }}</p>
        <v-divider class="mx-2 my-2"></v-divider>
        <div class="mt-2 mb-6 d-flex flex-wrap justify-start">
          <div v-for="arc in archives" class="archive mr-1 mb-2" :key="arc">
            <v-img contain class="archive_medal" :src="getMedal(arc)"/>
            <v-img contain class="archive_medal" :src="getArchive(arc)"/>
            <img class="archive_obi" :src="getObi(arc)"/>
            <p class="archive_title text-center text-white font-weight-bold">{{ getTitle(arc) }}</p>
          </div>
        </div>
      </v-row>

    </v-col>
  </v-card>
  
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

const ArchiveKey = Object.freeze({
        MAN100:0,
        MAN10:1,
        MAN1:2,
        PREMIUM:3,
        SUSHI:4,
        KOKOKARA:5,
        KAKUSAN:6,
        COMMENT:7,
        PHOTO:8,
        DOPU:9,
        HURI:10,
});

const archiveBase = [
        ["gold","1000_gold","100万馬券","払戻しが100万以上の馬券を当てる<br>IPAT投票限定","<br><br>かなり嬉しい",false,ArchiveKey.MAN100,5],
        ["silver","10_silver","10万馬券","払戻しが10万以上の馬券を当てる<br>IPAT投票限定","<br><br>なかなか嬉しい",false,ArchiveKey.MAN10,3],
        ["bronze","1_bronze","1万馬券","払戻しが1万以上の馬券を当てる<br>IPAT投票限定","<br><br>ぼちぼち嬉しい",false,ArchiveKey.MAN1,1],
        ["gold","huri","今を生きる","累計投資が100万を超える<br>IPAT投票限定","<br><br>過去は過去",false,ArchiveKey.HURI,5],
        ["silver","numa","どっぷり","累計投資が50万を超える<br>IPAT投票限定","<br><br>引き返せない",false,ArchiveKey.DOPU,3],
        ["bronze","beginer","ここから","累計投資が10万を超える<br>IPAT投票限定","<br><br>まだまだスタートライン",false,ArchiveKey.KOKOKARA,1],
        ["gold","god","神様","pakaraプレミアムサービスを利用","<br><br>ありがとうございます",false,ArchiveKey.PREMIUM,5],
        ["silver","sushi","寿司競馬","1日の収支が+1万を3日達成<br>IPAT投票限定","<br><br>今日は寿司を食べよう",false,ArchiveKey.SUSHI,3],
        ["bronze","speaker","拡散者","プロフィールをシェアし、そのURLが開かれる","<br><br>ナイスシェア",false,ArchiveKey.KAKUSAN,1],
        ["bronze","comment","コメンター","掲示板の投稿で累計10いいねもらう","<br><br>ナイスコメント",false,ArchiveKey.COMMENT,1],
        ["bronze","photo","写真家","投稿した馬や騎手の写真が採用される","<br><br>ナイスショット",false,ArchiveKey.PHOTO,1],
  ];

const images = ["a","b","c","d","e","f"];

export default {
  name: 'Other-acount-',
  props:{
    UserId:{
      default:"",
    },
  },
  components: {
    
  },
  data: () => ({
    value:value.VALUE,
    archives:[],
    user:{
      myid:"0",
      userName:"",
      archives:[],
      frameName:"",
      icon:"",
      intro:"",
      isAcount:false,
    },
  }),
  watch:{
    User(){
      this.initial();
    }
  },
  created:function(){
    this.initial();
    this.fetchAcount(this.UserId);
    //
  },
  mounted:function(){
    
  },
  methods:{
    initial(){
      this.archives = [];
      this.user.myid = this.UserId;

      for(let n=0;n<archiveBase.length;n++){
        this.archives.push(archiveBase[n]);
        let arc = this.user.archives.find(elm => {
          if(elm.key == archiveBase[n][6])return true;
          else return false;
        });
        if(arc && arc.isGet){
          this.archives[n][5] = true;
        }
        else{
          this.archives[n][5] = false;
        }
      }
    },
    getIcon(icon,id){
      if(icon == ""){
        const pre = id.slice(0,1);
        const hex = parseInt(pre,16);

        var num = hex%images.length;
        if(isNaN(num)){
          num = 0;
        }
        return require("@/assets/image/icon/"+images[num]+".png");
      }
      else{
        return process.env.VUE_APP_DOMAIN+"/post_images/icon/"+icon;
      }
    },
    getFrame(frame){
      if(frame == "" || frame == undefined){
        return "";
      }
      else{
        return require("@/assets/image/archive/"+frame+".png");
      }
    },
    getMedal(arc){
      if(arc[5])return require("@/assets/image/archive/"+arc[0]+".png");
      else return require("@/assets/image/archive/black.png");
    },
    getArchive(arc){
      if(arc[5])return require("@/assets/image/archive/"+arc[1]+".png");
      else return "";
    },
    getObi(arc){
      if(arc[5])return require('@/assets/image/archive/obi.png');
      else return require('@/assets/image/archive/obiBlack.png');
    },
    getTitle(arc){
      if(arc[5])return arc[2];
      else return "？？？";
    },
    getExplain(arc){
      if(arc[5])return arc[3]+arc[4];
      else return arc[3];
    },
    fetchAcount(id){
      console.log(id);
      var params = new URLSearchParams();
      params.append("user_id",id);

      axios.post(process.env.VUE_APP_POST_URL+"/acount/get_profile.php",params)      
        .then(response => {
          
        console.log(response.data);
        if(response.data.isAcount){
          let res = response.data;
          this.user = res;
          this.initial();
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
  computed:{
  }
}

</script>

<style scoped>
:deep(.icon){
  width:80px;
  height:80px;
  top:8px;
  left:8px;
  background-color:#f3f2f9;
}

:deep(.waku){
  width:96px;
  height:96px;
  position:absolute;
  left:0px;
  top:0px;
}

:deep(.archive){
  width: 80px;
  height: 80px;
  position:relative;
}
:deep(.archive_medal){
  width: 70px;
  height: 70px;
  top:0px;
  left:5px;
  position: absolute;
}
:deep(.archive_obi){
  width: 100%;
  height: 30px;
  bottom:0px;
  left:0px;
  position: absolute;
}
:deep(.archive_title){
  width: 100%;
  height: 18px;
  bottom:0px;
  left:0px;
  position: absolute;
  font-size:11px;
}

:deep(.archive_bg){
  /*margin:0 auto;*/
  width: 160px;
  height: 160px;
  position:relative;
}
:deep(.archive_medal_bg){
  width: 140px;
  height: 140px;
  top:0px;
  left:10px;
  position: absolute;
}
:deep(.archive_obi_bg){
  width: 160px;
  height: 60px;
  bottom:0px;
  left:0px;
  position: absolute;
}
:deep(.archive_title_bg){
  width: 160px;
  height: 36px;
  bottom:0px;
  left:0px;
  position: absolute;
  font-size:22px;
}
</style>