<template>
  <v-card  elevation="2" style="overflow-y: scroll;">
    <h3 class="mx-3" style="margin-top:8px;">コーナー順位</h3>
    <v-divider class="mx-2"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-list v-if="res">
          <v-list-item v-for="(corner,index) in res.corners" :key="corner">
            <p>{{ (index+1)+"コーナー" }}</p>
            <div class="corner_cont" :style="getBackImage" v-html="decodeCorner(corner)">

            </div>
          </v-list-item>
        </v-list>
        <div class="w-100 mx-5 d-flex flex-wrap">
          <div v-for="horse in horses" class="d-flex mr-4" :key="horse">
            <div class="horse_list text-center" style="" :style="wakuColor(horse.waku)">{{ horse.umaban }}</div>
            <p class="font-weight-bold">{{ horse.name.slice(0,3) }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

export default {
  name: 'Data-corse',
  props:{
    CurrentRace:{
      default:{},
    },
    Horses:{
      default:[],
    }
  },
  components: {
    
  },
  data: () => ({
    value:value.VALUE,
    res:null,
    horses:[],
  }),
  watch:{

  },
  created:function(){
    this.horses = JSON.parse(JSON.stringify(this.Horses));
    this.horses = this.horses.sort(function(a,b){
      return (a.umaban < b.umaban ? -1 : 1);
    });
    this.fecthData();
  },
  mounted:function(){
    
  },
  methods:{
    decodeCorner(corner){

      const ary = corner.split("");
      if(ary.length == 0)return "";
      

      var parse = {};
      var umaban = "";
      var del = 0.0;
      var waku = 0.0;
      var isGroup = false;
      var isSenkou = false;
      var isLoop = true;
      var isFirst = true;

      for(let n=0;n<ary.length;n++){
        let ar = ary[n];
        if(!isLoop)break;

        if(!isNaN(ar)){
          umaban += ar;
        }
        else{
          if(umaban != ""){
            let cor = {};
            cor.waku = waku;
            if(isSenkou)cor.dis = del-0.5;
            else cor.dis = del;
            
            parse[umaban] = cor;
            isSenkou = false;
            isFirst = false;
            umaban = "";
          }

          switch(ar){
            case "(":
              if(!isFirst)del += 1.5;
              isGroup = true;
              break;
            case ")":
              del += 1;
              waku = 0;
              isGroup = false;
              break;
            case "*":
              isSenkou = true;
              break;
            case ",":
              if(!isGroup)del += 1.5;
              else waku += 1;
              break;
            case "-":
              del += 2;
              break;
            case "=":
              del += 5;
              break;
            case " ":
              isLoop = false;
              break;
            default:
              break;
          }
        }
      }

      if(umaban != ""){
        let cor = {};
        cor.waku = waku;
        if(isSenkou)cor.dis = del-0.5;
        else cor.dis = del;

        parse[umaban] = cor;
      }

      var html = "";

      for(let n=0;n<this.horses.length;n++){
        let hor = this.horses[n];
        let co = parse[hor.umaban];
        if(co){
          let wakuColor = this.wakuColor(hor.waku);
          wakuColor += `left:${co.dis*4+5}%;top:${(co.waku)*16+5}px;`;
          html += `<div class="umaban text-center font-weight-bold" style="${wakuColor}">${hor.umaban}</div>`;
        }
      }

      return html;

    },
    fecthData(){

      var params = new URLSearchParams();
      params.append("id", this.CurrentRace.id);
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_corner.php",params)      
        .then(response => {
          const res = response.data;
          
          //console.log(res);
          if(res.corners && res.corners.length){
            this.res = res;
          }
                
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
  },
  computed:{
    getBackImage(){
      return {"background-image":"url("+require("@/assets/image/siba.png")+")"};
    },
    wakuColor(){
      return (index) => {
        if(index == 2){
          return "background-color:"+this.value.WAKU_COLOR[index]+";color:#fff;";
        }
        return "background-color:"+this.value.WAKU_COLOR[index]+";color:#000;";
      };
    },
  }
}

</script>

<style scoped>

:deep(.corner_cont){
  width:100%;
  height:80px;
  position:relative;
  background-repeat: repeat;
}
:deep(.umaban){
  width: 16px;
  height:16px;
  border-radius: 8px;
  position:absolute;
  font-size: 12px;
}

:deep(.horse_list){
  width:20px;
  height:20px;
  font-size: 13px;
  border-radius:10px;
  border:1px solid lightgray;
}

.anim{
  transition: .3s all;
}
.cusssion_grad {
  background: linear-gradient(to right, #4cb7ad,#eaf8f8 37.5%,#eaf8f8 62.5%, #4cb7ad);
}
.gansui_grad {
  background:linear-gradient(to right, #ffe98b,#9894fe);
}

table thead th {
  border-top : 1px solid #CCC;
  /*border-left : 1px solid #CCC;*/
  padding:4px !important;
}
table thead th:first-child {
  border-left : 1px solid #CCC;
  border-right : 1px solid #CCC;
}

table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

</style>