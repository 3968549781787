<template>
  <v-card elevation="2" class="pa-2">

    <v-calendar class="w-100" ref="calendar" locale="ja-jp" :hide-week-number="true" :weekdays="weekday" view-mode="month"
        v-model="now" :events="events" @change="getEvents" @click:event="clickEvent" @click:day="clickDay" @click:date="clickDay" >
    </v-calendar>
  </v-card>
</template>


<script>
import axios from 'axios';
//import { useDate } from 'vuetify'

import value from "../../global.js"

export default {
	name: 'Race-calender',
	components: {
	},
	data: () => ({
    value:value.VALUE,
    now:[new Date()],
    weekday: [1, 2, 3, 4, 5, 6,0],
    events: [],
	}),
	created:function(){
	},
  mounted(){
    this.getEvents();

    this.$watch(
      ()=> {
        return this.$refs.calendar.daysInWeek;
      },
      (val) => {
        console.log(val);
        this.getEvents();
      }
    )
  },
	methods:{
    clickEvent(){
      console.log(",");
    },
    clickDay(){
      console.log("day");
    },
    click(eve){
      console.log(eve);
      console.log(event)
    },
    getEvents() {
      let date = this.$refs.calendar.daysInWeek[0].date;
      let mon = date.getMonth()+1;
      let da = ""+date.getFullYear();
      if(mon < 10){
        da += "0"+mon;
      }
      else{
        da += mon;
      }
      
      this.events = [];

      var params = new URLSearchParams();
			params.append('date', da);
							
			axios.post(process.env.VUE_APP_POST_URL+"/race/get_race_data_month.php",params).then(response => {
        const res = response.data;
        if(res.length){
          for(let n=0;n<res.length;n++){
            let places = res[n].places;
            for(let i=0;i<places.length;i++){
              let place = places[i].place;
              let time = new Date(res[n].date.slice(0,4)+"/"+res[n].date.slice(4,6)+"/"+res[n].date.slice(-2)+" 00:00");
              let eve = {
                title:this.value.PLACE_NAME[place],
                start:time,
                end:time,
                color:this.value.PLACE_COLORS[place],
                timed:false,
              };
              this.events.push(eve);
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
			});
    },
	}
}
</script>

<style scoped>
.v-chip.v-chip--size-default{
  padding-left:0 !important;
  padding-right: 8px;
}

</style>