import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import router from "./router"
import store from "./store"

import { VueHeadMixin,createHead } from '@unhead/vue'

import VueGtag from "vue-gtag";



loadFonts()
const head = createHead();

createApp(App)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(head)
  .use(
    VueGtag,
    {
      config: { id: "G-PL4ET2WGE0" },
    },
    router
  )
  .mixin(VueHeadMixin)
  .mount('#app')
