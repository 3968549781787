<template>
  <v-divider class="mx-2"></v-divider>
  <v-list-item @click="clickRace" class="pl-2">
    <div class="d-flex justify-start">
      <div class="my-1">
        <v-btn class="rounded-lg d-block place_btn" style="" :style="backColor">
          <p class="text-center text-subtitle-2 font-weight-bold" :class="[isFuture ? 'text-black' : 'text-white']">
            {{ race["round"] }}R<br>{{ value.PLACE_NAME[race.place] }}
          </p>
          <p v-if='race.grade' class="rounded-pill text-white font-weight-bold" style="position:absolute;bottom:-4px;right:-6px;padding:0px 4px;background-color:red;" :style="gradeColor(race.grade)">
            G{{ race.grade }}
          </p>
        </v-btn> 
      </div>
      <div class="d-flex flex-column ml-2 align-self-center">
        <div class="">
          <span style="font-size: 16px;">{{ race.race_name }}</span>
          <span v-if="race.isWin" class="mx-3 px-1 py-1 font-weight-bold d-inline-block text-white rounded-pill" style="background-color:green;font-size: 12px;;height:20px;line-height: 14px;">
            Win5
					</span>
        </div>
				<div class="" style="font-size: 14px">
					<span :style="courseColor" style="font-weight: bold;">
						{{ race.course }}
					</span>
					<span>
						{{ race.distance }} {{ race.count }}頭 {{ race.hand }} 馬場:{{ race.baba }} {{ race.weather }}{{ value.WEATHER[race.weather] }} {{ race.time }}
					</span>
				</div>
      </div>
    </div>
	</v-list-item>
</template>

<script>
  import value from "../../global.js"

  //const color = ref(this.value.PLACE_COLORS[this.props.race["place"]]);
    
  export default {
		name: 'Race-list-item',
    emits: ['clickRace'],
    props:{
      race:{
        required:true,
        default:{},
      },
    },
    components: {
        
    },
    data: () => ({
      value:value.VALUE,
      isFuture:false,
    }),
    methods:{
      clickRace:function(){
        this.$emit("clickRace");
      },
    },
    created:function(){
      const now = new Date();
      const date = this.race.date.slice(0,4)+"/"+this.race.date.slice(4,6)+"/"+this.race.date.slice(-2)+" "+this.race.time+":00";
      const raceDate = new Date(date);

      if(now > raceDate){
        this.isFuture = false;
      }
      else{
        this.isFuture = true;
      }
    },
    computed:{
      backColor(){
        return { "background-color":this.value.PLACE_COLORS[this.race.place] };
      },
      courseColor(){
        return { "color":this.value.COURSE_COLOR[this.race.course] };
      },
      gradeColor(){
        return (grade) => {
          return {"background-color":this.value.GRADE_COLOR[grade]};
        };
      },
    }
	}
</script>

<style scoped>
.place_btn{
  min-width:50px;
  width:50px;
  height:50px;
	/*padding: 0px;
	font-size:14px;position:relative;
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .5);*/
}
</style>