<template>
	<v-list>
    <template v-for="news in res" :key="news">
      <NewsItem @click="clickNews(news)" :News="news"/>
    </template>
  </v-list>
</template>

<script>
import axios from 'axios';

import NewsItem from "../../components/news/NewsItem.vue"

export default {
	name: 'News-list',
  props:{
    Url:{
      default:"",
    }
  },
	components: {
		NewsItem,
	},
	data: () => ({
    res:[],
	}),
  created:function(){
    this.fetchNews();
  },
	methods:{
    clickNews(news){
      this.$emit("clickNews",news);
    },
		fetchNews:function(){
			var params = new URLSearchParams();
      params.append("page", 0);
         
      axios.post(process.env.VUE_APP_POST_URL+this.Url,params)      
        .then(response => {
          
        const res = response.data;
        this.res = res;

      })
      .catch(error => {
        console.log(error);
      });
		}
	}
}
</script>