<template>
    <v-app-bar color="light-blue" :elevation="2" title="" scroll-behavior="elevate"
    scroll-threshold="20">
        
        <v-app-bar-nav-icon>
          <v-img contain class="mx-2" max-width="40" min-width="40" :src="require('../assets/logo.webp')" alt="icon"></v-img>
        </v-app-bar-nav-icon>
        <v-app-bar-title>
            <router-link to="/" class="toolbar-title" style="text-decoration:none;">
                <h1 class="text-white">Pakara<span class="d-none d-md-inline-block mx-1 text-subtitle-1">競馬の予想、投票と収支管理</span></h1>
            </router-link>
        </v-app-bar-title>
            
        <!--
        <v-btn @click="clickLive()" stacked>
          <div class="icon_container">
            <v-icon class="icon" color="#f44336">mdi-movie-outline</v-icon>
          </div>
          <p class="mt-">ライブ映像</p>
        </v-btn> -->

        <v-btn @click="clickAcount()" stacked>
          <div class="icon_container">
            <v-img :src="getIcon()" class="icon" alt="avator"></v-img>
            <v-img :src="getFrame()" class="waku" alt="frame"></v-img>
          </div>
          <p class="mt-">アカウント</p>
        </v-btn>

        <template v-slot:extension>
            <v-tabs align-with-title density="compact">
                <v-tab to="/" prepend-icon="mdi-horse-variant">レース</v-tab>
                <v-tab to="/stats/race" prepend-icon="mdi-chart-line">統計</v-tab>
                <v-tab to="/expenses" prepend-icon="mdi-wallet-outline">収支</v-tab>
                <v-tab to="/news" prepend-icon="mdi-newspaper-variant-outline">ニュース</v-tab>
            </v-tabs>
        </template>
    </v-app-bar>

	<!-- corse diaolog -->
  <v-dialog v-model="acountDialog" class="w-dialog" style="max-width: 750px;">
    <div class="d-flex justify-end">
      <v-btn class="mb-2" icon @click="acountDialog = false" >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card>
      <Acount :User="user" />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import Acount from './acount/Acount.vue';

export default {
  name: 'Header-',
	components: {
    Acount,
  },
  data: () => ({
		acountDialog:false,
    user:{},
	}),
	created:function(){
    this.user = this.$store.getters.user;
    this.initial();
	},
  mounted:function(){
    this.$store.watch(
      (state,getters) => getters.user,
      (newVal) => {
        this.user = newVal;
      }
    );
  },
	methods:{
    initial(){
      if(this.$store.getters.user.isLogin || this.$store.getters.user.isPurchase){
        this.checkAcount();
      }
    },
    getIcon(){
      if(this.user.icon == ""){
        var num = 0;
        const images = ["a","b","c","d","e","f"];

        if(this.user.myid){
          const pre = this.user.myid.slice(0,1);
          const hex = parseInt(pre,16);

          num = hex%images.length;
          if(isNaN(num)){
            num = 0;
          }
        }
        
        return require("@/assets/image/icon/"+images[num]+".png");
      }
      else{
        return process.env.VUE_APP_DOMAIN+"/post_images/icon/"+this.user.icon;
      }
    },
    getFrame(){
      if(this.user.frameName == ""){
        return "";
      }
      else{
        return require("@/assets/image/archive/"+this.user.frameName+".png");
      }
    },
    clickAcount(){
      this.acountDialog = true;
    },
    clickLive(){
      window.open("https://pakara-keiba.com/race_movie/movie_live.php?lpid=1","live","menubar=0,width=500,height=328,top=50,left=100000");
    },
    checkAcount(){
      var params = new URLSearchParams();
      params.append("user_id", this.user.myid);

      axios.post(process.env.VUE_APP_POST_URL+"/acount/check_acount.php",params,{
          withCredentials:true,
        })      
        .then(response => {
          
        console.log(response.data);
        let res = response.data;
        if(res.isCollect == true){
          if(res.acount.isAcount){
            let user = res.acount;
            user.isLogin = true;
            this.$store.dispatch("setUser",user);
            if(user.isPurchase){
              this.checkPurchase(user.myid);
            }
          }
        }
        else{
          let user = {
            myid:crypto.randomUUID(),
            userName:"",
            archives:[],
            frameName:"",
            isLogin:false,
            icon:"",
            intro:"",
            isPurchase:false,
            plan:0,
            updated:"",
            isAcount:false,
          };
          this.$store.dispatch("setUser",user);
        }
      })
      .catch(error => {
        console.log(error);
      });
      
    },
    checkPurchase(id){
      var params = new URLSearchParams();
      params.append("myid",id);

      axios.post(process.env.VUE_APP_POST_URL+"/purchase/purchase_check_v3.2.1.php",params,{
          withCredentials:true,
        })      
        .then(response => {
          
        console.log(response.data);
        let res = response.data;
        if(res){
          let user = this.$store.getters.user;
          user.isPurchase = res.result;
          user.plan = res.plan;
          this.$store.dispatch("setUser",user);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
}
</script>
<style scoped>
.icon_container{
  width:48px;
  height:48px;
  position: relative;
}
.icon{
  width:40px;
  height:40px;
  top:4px;
  left:4px;
  border-radius:20px;
  background-color:#f3f2f9;
  position:absolute;
}

.waku{
  width:48px;
  height:48px;
  position:absolute;
  left:0px;
  top:0px;
}
@media screen and (max-width: 600px) {
  .v-toolbar-title{
    font-size:1rem;
  }
  .v-btn--stacked.v-btn--size-default{
    padding:0px 4px;
  }
}
</style>