<template>
	<v-container>
		<v-row>
			<v-col cols="12" class="pa-0">
				<v-tabs class="w-100 mb-tabs" v-model="tab_news" color="#03a9f4" >
          <v-tab value="news_0">ニュース</v-tab>
					<v-tab value="news_1">まとめ</v-tab>
					<v-tab value="news_2">掲示板</v-tab>
				</v-tabs>

				<v-window disabled v-model="tab_news" style="min-height: 1000px;">

          <v-window-item value="news_0">
						<NewsList @clickNews="clickNews" :Url='"/news/get_news_v2.6.2.php"' :key="tab_news"/>
					</v-window-item>

          <v-window-item value="news_1">
						<NewsList @clickNews="clickNews" :Url='"/news/get_matome_v2.6.2.php"' :key="tab_news"/>
					</v-window-item>

          <v-window-item value="news_2">
						<BoardList @clickComment="clickComment" @clickIcon="clickIcon" :key="tab_news"/>
					</v-window-item>

				</v-window>
			</v-col>
		</v-row>

	</v-container>

	<!-- acount diaolog -->
  <v-dialog v-model="acountDialog" class="w-dialog" style="max-width: 750px;">
    <div class="d-flex justify-end">
      <v-btn class="mb-2" icon @click="acountDialog = false" >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card>
      <OtherAcount :UserId="userId" />
    </v-card>
  </v-dialog>

</template>

<script>
import NewsList from "../components/news/NewsList.vue"
import BoardList from "@/components/news/BoardList.vue";
import OtherAcount from "@/components/acount/OtherAcount.vue";

export default {
	name: 'News-top',
	components: {
		NewsList,
		BoardList,
		OtherAcount,
	},
	head:{
		title:"競馬ニュース",
		meta:[
			{ name:"description",content:"競馬関連のニュースやまとめサイト、掲示板による情報をお届け" },
			{ property: "og:type", content: "website" },
			{ property: "og:url", content: process.env.VUE_APP_DOMAIN+"/news" },
			{ property: "og:title", content: "pakara - 競馬の総合情報サイト" },
			{ property: "og:description", content: "競馬関連のニュースやまとめサイト、掲示板による情報をお届け" },
			{ property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
			{ property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
		],
	},
	data: () => ({
		tab_news:0,
		acountDialog:false,
		userId:"",
	}),
	created:function(){
		this.tab_news = 0;
	},
	methods:{
		clickNews(news){
			window.open(news.url, '_blank')
		},
		clickComment(comment){
			console.log(comment);
		},
		clickIcon(comment){
			this.userId = comment.user_id;
			this.acountDialog = true;
		}
	}
}
</script>