<template>
    <v-footer color="light-blue footer-cont">
    <v-row justify="center" no-gutters>
        <v-btn href="https://pakara-keiba.com/info/how/" prepend-icon="mdi-chat-question-outline" color="white" variant="text" class="mx-2" rounded="xl">よくある質問</v-btn>
        <v-btn href="https://pakara-keiba.com/info/contact/" prepend-icon="mdi-email-outline" color="white" variant="text" class="mx-2" rounded="xl">お問い合わせ</v-btn>
        <v-btn href="https://twitter.com/KeibaPakara" prepend-icon="mdi-twitter" color="white" variant="text" class="mx-2" rounded="xl">X(twitter)</v-btn>
        <v-btn href="https://pakara-keiba.com/info/terms/" prepend-icon="mdi-note-outline" color="white" variant="text" class="mx-2" rounded="xl">利用規約</v-btn>
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>Rスクエア</strong>
      </v-col>
    </v-row>
  </v-footer>


</template>

<style scoped>

.footer-cont{
  min-height: 92px;
}

@media screen and (max-width: 600px) {
  .footer-cont{
    min-height: 128px;
  }
}

</style>