<template>
  <v-layout>
    <!-- left navigation bar expand-on-hover-->
    <div v-if="isMobile" style="position:fixed;right:20px;bottom:20px;z-index:9999;">
      <v-btn @click="drawer = !drawer" icon="mdi-view-list-outline" color="#03a9f4" style="width:50px;height:50px;"></v-btn>
    </div>
    <v-navigation-drawer v-model="drawer" :expand-on-hover="!isMobile" :rail="rail" permanent style="position: fixed;top:120px;" :width="380">
      
      <div class="d-flex justify-start" style="height:40px;">
        <p class="pa-1 align-self-center" style="font-size:14px;">{{ day ? day.day.slice(0,2)+'/'+day.day.slice(-2)+'' : ''}}</p>
        <v-btn @click="clickRail" v-show="!isMobile" icon="mdi-pin" class="mx-3" style="width:36px;height:36px;"></v-btn>
      </div>

    <!-- navigation content -->
      <!-- tab -->
      <v-tabs v-if="day" ref="tabNav" v-model="tab" fixed-tabs :style="backColor(day.places[currentPlace].place)">
        <template v-for="(place,index) in day.places" :key="place">
          <v-tab :value="index" class="font-weight-bold" :style="backTabColor(index,place.place)" >
            {{ value.PLACE_NAME[place.place] }}
          </v-tab>
        </template>
      </v-tabs>

      <!-- tab content -->
      <v-card-text class="pt-0">
      <v-window disabled v-if="day" v-model="tab">
          <template v-for="(place,index) in day.places" :key="place">
            <v-window-item :value="index" :style="adjustHeight">
              <v-list style="height:100%;overflow-y: scroll;">
                <template v-for="(race,row) in place.races" :key="race">
                  <RaceListItem @clickRace="clickRace(index,row)" :race="race"/>
                </template>
              </v-list>
              <v-tabs-slider />
            </v-window-item>
          </template>
        </v-window>
      </v-card-text>
    </v-navigation-drawer>
    
    <!-- main -->
   <!-- <v-container> -->
      <v-main>
        <v-container>
          <RaceDetaileContainer :CurrentRace="currentRace"/>
        </v-container>
      </v-main>

  </v-layout>
  
</template>

<script>
import RaceDetaileContainer from "@/components/race/RaceDetaileContainer.vue"
import RaceListItem from "../../components/race/RaceListItem.vue"


import value from "../../global.js"
import axios from 'axios'

import { useHead } from '@unhead/vue'


export default {
  name: 'Race-',
  components: {
    RaceDetaileContainer,
    
    RaceListItem,

  },
  data: () => ({
    value:value.VALUE,
    width:960,
    height:1000,
    isMobile:false,
    drawer:true,
    hoverExpand:true,
    rail:true,
    day:null,
    currentRace:null,
    currentPlace:0,
    tab:0,
  }),
  watch:{
    $route(to){
      if(to.name == "Race"){
        //this.day = this.$store.getters.day;
        var date = this.$route.params.date;

        this.isHashira = false;
        this.isKisyuhasira = false;
        this.isBlad = false;
        this.isCyoukyou = false;
        this.isCyoukyouWc = false;
        this.isSisuu = false;

        this.horses = [];

        if(this.day == null || this.day.date != date){   //naver call?
          this.getDays(date);
        }
        else{
          this.initial();
        }
      }
    },
    isMobile(val){
      if(val){
        this.drawer = false;
        this.rail = false;
        this.hoverExpand = false;
      }
      else{
        this.drawer = true;
        this.rail = this.rail = this.$store.getters.isPin;
        this.hoverExpand = true;
      }
    },
  },
  created:function(){
    this.day = this.$store.getters.day;
    var date = this.$route.params.date;
    
    this.rail = this.$store.getters.isPin;

    if(this.day == null || this.day.date != date) {
      this.getDays(date);
    }
    else{
      this.initial();
    }
  },
  mounted:function(){
    window.addEventListener('resize', this.onResize);
    this.onResize();
    if(this.isMobile){
      //this.isMobile = true;
      this.drawer = false;
      this.rail = false;
      this.hoverExpand = false;
    }
    else{
      //this.isMobile = false;
      this.drawer = true;
      this.rail = this.$store.getters.isPin;
      this.hoverExpand = true;
    }
  },
  beforeUnmount:function(){
    window.removeEventListener('resize', this.onResize);
  },
  methods:{
    initial:function(){  
      //this.tab_race = 0;

      var place = this.$route.params.place;
      var round = this.$route.params.round;

      for(let n=0;n<this.day.places.length;n++){
        if(this.day.places[n].place == place){
          this.currentPlace = n;
          this.tab = n;
          break;
        }
      }
        
      this.currentRace = this.day.places[this.tab].races[0];

      if(round != 1){
        var races = this.day.places[this.tab].races;
        for(let n=0;n<races.length;n++){
          if(races[n].round == round){
            this.currentRace = races[n];
            break;
          }
        }
      }

      if(this.currentRace.state < 5){
        this.sortTab = 1;
      }

      useHead(this.getHead());

      //this.getRaceHorses(this.currentRace.id);

    },
    getHead(){
      const date = this.currentRace.date.slice(0,4)+"年"+this.currentRace.date.slice(4,6)+"月"+this.currentRace.date.slice(-2)+"日"+this.currentRace.race_name;
      return {
        title: date+"の出馬表",
        meta:[
          { name:"description",content:"リアルタイムなオッズ、馬柱、血統、調教タイム、スピード指数、コーナー順位、ラップタイムを表示。オッズの変動や統計、レース映像も確認できます。" },
          { property: "og:type", content: "website" },
          { property: "og:url", content: process.env.VUE_APP_DOMAIN+this.$route.fullPath },
          { property: "og:title", content: date+"の出馬表" },
          { property: "og:description", content: "リアルタイムなオッズ、馬柱、血統、調教タイム、スピード指数、コーナー順位、ラップタイムを表示。オッズの変動や統計、レース映像も確認できます。" },
          { property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
          { property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
        ],
      };
    },
    clickRail(){
      this.rail = !this.rail;
      this.$store.dispatch("setIsPin",this.rail);
      //console.log(this.$store.getters.isPin);
    },
    clickRace:function(col,row){
      if(this.isMobile)this.drawer = false;
      this.tab_race = 0;
      this.currentRace = this.day.places[col].races[row];
      this.currentPlace = col;

      this.$router.push({name:"Race",params:{date:this.currentRace.date,place:this.currentRace.place,round:this.currentRace.round,name:this.currentRace.race_name}});
    },
    getDays(date){
      console.log(date);
      var params = new URLSearchParams();
      params.append("date", date);
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_race_day.php",params)      
        .then(response => {
        
          const res = response.data;

          console.log(res);
          if(res){
            this.day = res;
            this.initial();
          }   
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
    onResize(){
      this.height = window.innerHeight-200;
      this.width = window.innerWidth;
      this.isMobile = this.width < 960 || this.isMobileAgent() ? true : false;
    },
    isMobileAgent() {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (
        userAgent.indexOf("iphone") != -1 ||
        userAgent.indexOf("ipad") != -1 ||
        userAgent.indexOf("android") != -1 ||
        userAgent.indexOf("mobile") != -1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed:{
    backColor(){
      return (place) => {
        return {"background-color":this.value.PLACE_COLORS[place]};
      };
    },
    backTabColor(){
      return (index,place) => {
        if(index == this.tab){
          return {"background-color":this.value.PLACE_COLORS[place],"color":"#fff"};
        }
        else{
          return {"background-color":"#fff","color":this.value.PLACE_COLORS[place]};
        }
      };
    },
    adjustHeight(){
      return "height:"+this.height+"px;";
    },
  }
}
</script>

<style scoped>
.w-dialog{
  width:90%;
}

.ads{
  display: none;
}

@media screen and (max-width: 600px) {
  .w-dialog{
    width:100%;
  }
  .v-container{
    padding:0;
  }
}

</style>