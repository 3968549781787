<template>
  <v-table class="mtable table-cont" density="compact">
    <thead>
      <tr>
        <th class="text-center" v-for="head in table_headders" v-html="head[0]" :key="head" :width="head[1]"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="horse in horses" :key="horse" class="text-center">
        <td>{{ getRank(horse.rank) }}</td>
        <td :style="wakuColor(horse.waku)">{{ horse.waku ? horse.waku : "" }}</td>
        <td>{{ horse.umaban ? horse.umaban : "" }}</td>
        <td class="font-weight-bold" :style='[ horse.mark && horse.mark.isRed ? "color:red;" : ""]'>{{ horse.mark ? decodeURI(horse.mark.mark) : "-" }}</td>
        <td class="text-left"><a class="font-weight-bold" href="#" @click.stop.prevent="clickHorse(horse)">{{ horse.name }}</a><br>{{ horse.sex }}{{ horse.age }} {{ horse.weight ? horse.weight : "" }}{{ horse.zougen ? "("+horse.zougen+")":"" }}</td>
        <td class="text-left"><a class="font-weight-bold" href="#" @click.stop.prevent="clickKisyu(horse)">{{ horse.kisyu }}</a><br>{{ horse.kinryou }}</td>
        <td>{{ horse.time }} {{ horse.f3 }}<br>{{ horse.cyakusa }} {{ horse.kyaku }}</td>
        <td>{{ horse.ninki ? horse.ninki+"人気":"" }}<br>{{ horse.odds }}</td>
      </tr>
    </tbody>
  </v-table>

  <v-row class="mt-4 result-cont">
    <v-col v-if="bakens.length" cols="12" md="5">
      <v-table density="compact" class="text-center mtable">
        <thead>
          <tr>
            <th colspan="3">払戻し</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(baken,num) in this.bakens" :key="baken">
            <tr v-for="(ba,index) in baken" :key="ba">
              <td style="width:25%;" v-if="!index" :rowspan="baken.length" class="font-weight-bold text-white" :style="bakenColor(num)">{{ ba.knd }}</td>
              <td style="width:42%;"><span class="font-weight-bold">{{ ba.bangou }}</span><br><span style="color:#8e8e8e">{{ ba.ninki+"人気" }}</span></td>
              <td style="width:42%;" class="font-weight-bold">{{ (ba.price-0).toLocaleString()+"円" }}</td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </v-col>

    <v-col v-if="currentRace.state > 4" cols="12" md="7">

      <v-table class="my-0" density="compact">
        <thead>
          <tr>
            <th :colspan="laps.length" :key="laps.length">ラップタイム</th>
          </tr>
        </thead>
        <tbody :key="laps">
          <tr class="text-center">
            <td v-for="(lap,index) in laps" :key="lap">
              {{ (index+1)*200+"m" }}
            </td>
          </tr>
          <tr class="text-center">
            <td v-for="(lap,index) in laps" :key="lap" v-html="getLap(index)">
            </td>
          </tr>
        </tbody>
      </v-table>

      <v-table v-if="currentRace.state > 4" class="my-2" density="compact">
        <thead>
          <tr>
            <th :colspan="4">前後半3F・4F</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td>前半3F</td>
            <td>前半4F</td>
            <td>後半4F</td>
            <td>後半3F</td>
          </tr>
          <tr class="text-center">
            <td>{{ currentRace.f3_pre ? (currentRace.f3_pre/10).toFixed(1) : "" }}</td>
            <td>{{ currentRace.f4_pre ? (currentRace.f4_pre/10).toFixed(1) : "" }}</td>
            <td>{{ currentRace.f4_rea ? (currentRace.f4_rea/10).toFixed(1) : "" }}</td>
            <td>{{ currentRace.f3_rea ? (currentRace.f3_rea/10).toFixed(1) : "" }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
  </v-row>

</template>

<script>
import value from "../../global.js"

export default {
  name: 'Race-card',
  emits: ["clickHorse","clickKisyu"],
  props:{
    horses:{
      default:[],
    },
    currentRace:{
      default:null,
    },
  },
  components: {

  },
  data: () => ({
    value:value.VALUE,
    table_headders:[["着順","5%"],["枠番","5%"],["馬番","5%"],["印","5%"],["馬名","30%"],["騎手","20%"],["タイム 3F<br>着差 脚質","15%"],["人気","15%"]],
    //horses:[],
    bakens:[],
    laps:[],
  }),
  watch:{
    currentRace:function(){
      this.checkBaken(this.currentRace);
      this.laps = this.currentRace.lap.split('-');
    },
  },
  created:function(){
    this.checkBaken(this.currentRace);
    this.laps = this.currentRace.lap.split('-');
  },
  methods:{
    checkBaken(race){

      this.bakens = [];

      for(let n=0;n<this.value.BAKENS.length;n++){
        let ba = this.value.BAKENS[n];
        if(race[ba.key]){
          let ary = race[ba.key].split("&");
          for(let i=0;i<ary.length;i++){
            let brs = ary[i].split(",")
            if(brs.length >= 3){
              let baken = { knd:ba.name,bangou:brs[0],price:brs[1],ninki:brs[2] }
              if(!this.bakens[ba.knd]){
                this.bakens[ba.knd] = [];
              }
              this.bakens[ba.knd].push(baken);
            }
          }
        }
      }
    },
    clickHorse(horse){
      this.$emit("clickHorse",horse);
    },
    clickKisyu(horse){
      this.$emit("clickKisyu",horse);
    },
    getRank:function(rank){
      if(rank == 0){
        return "";
      }
      else if(rank > 99){
        return this.value.JYOGAI_CODE[""+rank];
      }
      else{
        return ""+rank
      }
    },
    getLap(index){
      var laps = (this.currentRace.lap.split("-"));

      if(laps.length > 0){
        var val = 0;

        if(index > 0){
          for(let n=0;n<=index;n++){
            val += laps[n]-0;
          }
        }
        else{
          val = laps[0]-0;
        }

        val = val/10.0;

        var time = "";
        var m = Math.floor(val/60.0);

        if(m > 0){
          let s = val-m*60.0;
          if(s < 10){
            time = Math.floor(m)+":0"+s.toFixed(1);
          }
          else{
            time = Math.floor(m)+":"+s.toFixed(1);
          }
        }
        else{
          time = val.toFixed(1);
        }

        return "<p>"+time+"</p><p>"+(laps[index]/10).toFixed(1)+"</p>";
      }
      else{
        return "";
      }
    },
  },
  computed:{
    wakuColor(){
      return (waku) => {
        if(waku == 1){
          return {"background-color":"transparent","color":"#000"};
        }
        else if(waku == 2){
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#fff"};
        }
        else{
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#000"};
        }
      }
    },
    bakenColor(){
      return (knd) => {
        return { "background-color":this.value.BAKEN_COLOR[knd] };
      }
    },
  }
}

</script>

<style scoped>
a {
  color:blue;
  text-decoration:none;
}

table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}
.result-cont{
  min-height: 500px;
}
.table-cont{
    min-height: 550px;
  }
@media screen and (max-width: 600px) {
  table tbody td{
    padding: 0 2px !important;
  }
  table thead th{
    padding: 2px !important;
  }
  .v-table {
    font-size: 0.73rem;
  }
  
  .result-cont{
    min-height: 773px;
  }
}

</style>