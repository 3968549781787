<template>
  <v-card elevation="2" style="overflow-y: scroll;">
    <v-col class="px-6" cols="12">
      <v-btn v-if="User.isLogin" @click="isNewImage = false; logoutBottom = true;" icon="mdi-cog-outline" style="position:absolute;top:8px;right:8px;width:40px;height:40px;"></v-btn>
      <v-row class="mt-0">
        <h2>アカウント</h2>
      </v-row>
      <v-row>
        <div class="d-flex w-100" style="height: 96px;">
          <div style="width:96px;position:relative;">
            <v-img class="rounded-circle icon" contain :src="getIcon(User.icon,User.myid)" />
            <v-img class="rounded-circle waku" contain :src="getFrame(User.frameName)" />
          </div>
          <p class="pa-2 ml-2">{{ decodeURI(User.intro) }}</p>
        </div>
        <p class="px-2">{{ User.isLogin ? decodeURI(User.userName) : "ゲスト" }}</p>
        <v-divider class="mx-2 my-2"></v-divider>
        <div class="d-flex mr-2">
          <div class="align-self-center">
            <span class="font-weight-bold">実績</span><span class="ml-2">{{ point }}ポイント</span>
          </div>
          <v-btn v-if="User.isLogin" @click="frameBottom = true;" class="d-block ml-5" style="max-height:25px;">フレーム選択</v-btn>
        </div>
        <div class="mt-2 mb-6 d-flex flex-wrap justify-start">
          <div v-for="(arc,index) in archives" @click="clickArchive(index)" class="archive mr-1 mb-2" style="cursor:pointer;" :key="arc">
            <v-img contain class="archive_medal" :src="getMedal(arc)"/>
            <v-img contain class="archive_medal" :src="getArchive(arc)"/>
            <img class="archive_obi" :src="getObi(arc)"/>
            <p class="archive_title text-center text-white font-weight-bold">{{ getTitle(arc) }}</p>
          </div>
        </div>
        <v-btn v-if="!User.isLogin" @click="clickLogin" class="my-4" prepend-icon="mdi-login" style="margin:0 auto;">ログイン</v-btn>
      </v-row>

    </v-col>
  </v-card>


<!-- archive -->
  <v-bottom-sheet style="" v-model="archiveBottom">
    <v-card-text style="min-height:300px;background-color:#fff;">
    <div style="margin:0 auto;width:fit-content">
      <div class="d-flex">
        <v-btn @click="clickArrow(-1)" icon="mdi-chevron-left" class="align-self-center"></v-btn>
        <div class="archive_bg mx-10">
          <v-img contain class="archive_medal_bg" :src="getMedal(archives[selectedNum])"/>
          <v-img contain class="archive_medal_bg" :src="getArchive(archives[selectedNum])"/>
          <img class="archive_obi_bg" :src="getObi(archives[selectedNum])"/>
          <p class="archive_title_bg text-center text-white font-weight-bold">{{ getTitle(archives[selectedNum]) }}</p>
        </div>
        <v-btn @click="clickArrow(1)" icon="mdi-chevron-right" class="align-self-center"></v-btn>
      </div>
    </div>
    <p class="text-center mt-4" v-html="getExplain(archives[selectedNum])"></p>
  </v-card-text>
  </v-bottom-sheet>

<!-- login -->
  <v-bottom-sheet style="" v-model="loginBottom">
    <v-card-text class="text-center" style="background-color:#fff;">
      <p>ログインするとプロフィールの情報が保存されます</p>
      <p>プレミアムサービスに加入の場合、印や展開予想メモが複数端末間で同期されます</p>
      <div class="my-4" style="margin:0 auto;">
        <v-btn @click="clickSignInApple" class="mx-4 my-2" style="width:150px;overflow: hidden;">
          <v-img src="@/assets/image/apple.webp" width="250" />
        </v-btn>
        <v-btn @click="clickSignInGoogle" class="mx-4 my-2" style="width:150px;overflow: hidden;">
          <v-img src="@/assets/image/google.webp" width="150" />
        </v-btn>
      </div>
    </v-card-text>
  </v-bottom-sheet>

<!-- edit -->
  <v-bottom-sheet v-model="logoutBottom">
    <v-card-text class="text-center" style="background-color:#fff;">
      <div style="max-width: 600px;width:100%;margin:0 auto;position:relative;">
        <div style="width:96px;height:96px;position:relative;margin:0 auto;">
          <v-img ref="mainIcon" class="rounded-circle icon" cover :src="mainIcon" />
          <v-img class="rounded-circle waku" contain :src="getFrame(User.frameName)" />
          
          <v-btn icon="mdi-pencil-outline" elevation="5" style="max-width:30px;max-height:30px;position:absolute;right:0;top:0;"></v-btn>
          <input type="file" accept="image/*" @change="readImage" style="cursor:pointer;width:30px;height:30px;position:absolute;right:0;top:0;opacity:0;" />
          
        </div>
        <!-- <div style="top:0;right:0;position:absolute;">
            <v-btn icon="mdi-trash-can-outline" elevation="5" style="width:30px;height:30px;"></v-btn>
        </div> -->
      
        <v-text-field density="compact" v-model="userName" label="ユーザーネーム" ></v-text-field>
        <v-textarea density="compact" v-model="intro" label="自己紹介" no-resize rows="2"></v-textarea>
        <p class="text-start" style="margin:0 auto;">＊プロフィールは掲示板、ゲームランキングで表示されます<br>＊不適切な画像や文言は、アカウント削除の可能性がありますのでご注意ください</p>
        <div>
          <v-btn @click="clickRegist" class="mx-4 my-4" style="width:100px;">登録</v-btn>
          <v-btn @click="clickLogout" class="mx-4 my-4" style="width:100px;">ログアウト</v-btn>
        </div>  
      </div>
    </v-card-text>
  </v-bottom-sheet>


<!-- frame -->
  <v-bottom-sheet style="" v-model="frameBottom">
    <v-card-text style="min-height:300px;background-color:#fff;">
    <div style="margin:0 auto;width:fit-content">
      <v-list>
        <v-list-item class="px-2" style="border-top:1px solid #f8f8f8;border-bottom:1px solid #f8f8f8;">
          <div class="d-flex justify-start">
            <div v-for="mdl in medals" class="mr-2 d-flex" style="" :key="mdl">
              <div style="position:relative;">
                <v-img :src='require("@/assets/image/archive/"+mdl[0]+".png")' width="40" height="40" ></v-img>
                <p class="font-weight-bold text-center" style="font-size:22px;position:absolute;width:40px;height:40px;left:0;top:5px;">{{ mdl[1] }}</p>
              </div>
              <p class="ml-0" style="margin-top:19px;">x{{ mdl[2] }}</p>
            </div>
            <p class="ml-10 align-self-end"><span class="font-weight-bold" style="font-size:20px;">{{ point }}</span>ポイント</p>
          </div>
        </v-list-item>
        <v-list-item v-for="frame in frames"  @click="clickFrame(frame)" class="px-2" style="border-top:1px solid #f8f8f8;border-bottom:1px solid #f8f8f8;" :key="frame">
          <div class="d-flex justify-space-between">
            <v-img :src="getFrame(frame[0])" class="d-block align-self-center" width="40" height="40" style="max-width: 40px;"></v-img>
            <p class="ml-5 align-self-center">{{ frame[1] }}</p>
            <div class="align-self-center ml-5" style="width:30px;">
              <v-icon v-if="frame[0] == User.frameName" icon="mdi-check-circle" size="x-large" color="#03a9f4"></v-icon>
            </div>
          </div>
        </v-list-item>
      </v-list>  
    </div>
  </v-card-text>
  </v-bottom-sheet>
  
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAH2V8h2A35qWg0m9R_p63vxkpV-bb3HAs",
  authDomain: "pakara-723df.firebaseapp.com",
  projectId: "pakara-723df",
  storageBucket: "pakara-723df.appspot.com",
  messagingSenderId: "756799459848",
  appId: "1:756799459848:web:549141697b57c2bf2037bd",
  measurementId: "G-KQ5GBE3NC5"
};
//kHOViow4RTZgLnpZqTDnmAZGkFe2
initializeApp(firebaseConfig);
//console.log(app);
const auth = getAuth();

const ArchiveKey = Object.freeze({
        MAN100:0,
        MAN10:1,
        MAN1:2,
        PREMIUM:3,
        SUSHI:4,
        KOKOKARA:5,
        KAKUSAN:6,
        COMMENT:7,
        PHOTO:8,
        DOPU:9,
        HURI:10,
});

const archiveBase = [
        ["gold","1000_gold","100万馬券","払戻しが100万以上の馬券を当てる<br>IPAT投票限定","<br><br>かなり嬉しい",false,ArchiveKey.MAN100,5],
        ["silver","10_silver","10万馬券","払戻しが10万以上の馬券を当てる<br>IPAT投票限定","<br><br>なかなか嬉しい",false,ArchiveKey.MAN10,3],
        ["bronze","1_bronze","1万馬券","払戻しが1万以上の馬券を当てる<br>IPAT投票限定","<br><br>ぼちぼち嬉しい",false,ArchiveKey.MAN1,1],
        ["gold","huri","今を生きる","累計投資が100万を超える<br>IPAT投票限定","<br><br>過去は過去",false,ArchiveKey.HURI,5],
        ["silver","numa","どっぷり","累計投資が50万を超える<br>IPAT投票限定","<br><br>引き返せない",false,ArchiveKey.DOPU,3],
        ["bronze","beginer","ここから","累計投資が10万を超える<br>IPAT投票限定","<br><br>まだまだスタートライン",false,ArchiveKey.KOKOKARA,1],
        ["gold","god","神様","pakaraプレミアムサービスを利用","<br><br>ありがとうございます",false,ArchiveKey.PREMIUM,5],
        ["silver","sushi","寿司競馬","1日の収支が+1万を3日達成<br>IPAT投票限定","<br><br>今日は寿司を食べよう",false,ArchiveKey.SUSHI,3],
        ["bronze","speaker","拡散者","プロフィールをシェアし、そのURLが開かれる","<br><br>ナイスシェア",false,ArchiveKey.KAKUSAN,1],
        ["bronze","comment","コメンター","掲示板の投稿で累計10いいねもらう","<br><br>ナイスコメント",false,ArchiveKey.COMMENT,1],
        ["bronze","photo","写真家","投稿した馬や騎手の写真が採用される","<br><br>ナイスショット",false,ArchiveKey.PHOTO,1],
  ];

const images = ["a","b","c","d","e","f"];

export default {
  name: 'Acount-',
  props:{
    User:{
      default:{
        myid:"0",
        userName:"",
        archives:[],
        frameName:"",
        isLogin:false,
        icon:"",
        intro:"",
        isPurchase:false,
        plan:0,
        updated:"",
        isAcount:false,
      }
    },
  },
  components: {
    
  },
  data: () => ({
    value:value.VALUE,
    archiveBottom:false,
    loginBottom:false,
    frameBottom:false,
    logoutBottom:false,
    selectedNum:0,
    archives:[],
    point:0,
    files:[],
    mainIcon:"",
    iconBlob:"",
    isNewImage:false,
    userName:"",
    intro:"",
    frames:[["waku0","25ポイントで解放",25],
        ["waku3","20ポイントで解放",20],
        ["waku2","15ポイントで解放",15],
        ["waku1","10ポイントで解放",10],
        ["waku4","5ポイントで解放",5],
        ["","フレームなし",0]],
    medals:[["gold",5,0],["silver",3,0],["bronze",1,0]],
  }),
  watch:{
    User(){
      this.initial();
      this.mainIcon = this.getIcon(this.User.icon,this.User.myid);
      this.userName = decodeURI(this.User.userName);
      this.intro = decodeURI(this.User.intro);
    }
  },
  created:function(){
    this.initial();
    this.mainIcon = this.getIcon(this.User.icon,this.User.myid);
    this.userName = decodeURI(this.User.userName);
    this.intro = decodeURI(this.User.intro);
  },
  mounted:function(){
    
  },
  methods:{
    initial(){
      this.point = 0;
      this.medals = [["gold",5,0],["silver",3,0],["bronze",1,0]];
      this.archives = [];

      for(let n=0;n<archiveBase.length;n++){
        this.archives.push(archiveBase[n]);
        let arc = this.User.archives.find(elm => {
          if(elm.key == archiveBase[n][6])return true;
          else return false;
        });
        if(arc && arc.isGet){
          this.archives[n][5] = true;
          this.point += this.archives[n][7];
          switch(this.archives[n][7]){
            case 5:
              this.medals[0][2] += 1;
              break;
            case 3:
              this.medals[1][2] += 1;
              break;
            case 1:
              this.medals[2][2] += 1;
              break;
          }
        }
        else{
          this.archives[n][5] = false;
        }
      }
    },
    clickSignInApple(){
      const provider = new OAuthProvider('apple.com');
      provider.addScope("name");
      provider.addScope("email");
      provider.setCustomParameters({
        locale: 'jp'
      });
      signInWithPopup(auth, provider).then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        
        const id = credential.idToken;
        const ary = id.split(".");
        if(ary.length < 2)return;

        var decode = ary[1].replace(/-/g,"+").replace(/_/g,"/");
        
       
        switch(decode.length%4){
          case 2:
            decode += "==";
            break;
          case 3:
            decode += "=";
            break;
          default:
            break;
        }
        
        const deco = decodeURIComponent(atob(decode));
        
        const jwt = JSON.parse(deco);
        
        if(jwt.sub && jwt.email){
          this.fetchAppleAcount(jwt.sub,jwt.email);
        }

      })
      .catch((error) => {
        console.log(error);
      });
    },
    clickSignInGoogle(){
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider).then((result) => {
        this.fetchGoogleAcount(result.user.uid,result.user.email);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    clickLogin(){
      this.loginBottom = true;
    },
    clickRegist(){
      //alert(this.intro);
      var params = new FormData();//new URLSearchParams();
      params.append("myid", this.$store.getters.user.myid);
      params.append("user_name",encodeURI(this.userName));
      params.append("intro",encodeURI(this.intro));
      params.append("is_delete","0");

      if(this.isNewImage){
        console.log("blob");
        params.append("image",this.iconBlob);
      }

      axios.post(process.env.VUE_APP_POST_URL+"/acount/edit_profile.php",params,{
          headers:{
            "Content-Type": "multipart/form-data"
          }
        })      
        .then(response => {
          
        if(response.data){
          let res = response.data;
          if(res.updated){
            let user = this.User;
            user.userName = encodeURI(this.userName);
            user.intro = encodeURI(this.intro);
            user.updated = res.updated;
            if(this.isNewImage){
              user.icon = user.myid+".jpg?"+(new Date()).getTime();
              this.isNewImage = false;
            }
            this.$store.dispatch("setUser",user);

            this.logoutBottom = false;
          }
          else{
//
          }
          return;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    clickLogout(){
      this.logoutBottom = false;
      let user = {
        myid:crypto.randomUUID(),
        userName:"",
        archives:[],
        frameName:"",
        isLogin:false,
        icon:"",
        intro:"",
        isPurchase:false,
        plan:0,
        updated:"",
        isAcount:false,
      };
      this.$store.dispatch("setUser",user);
    },
    clickArchive(index){
      this.selectedNum = index;
      this.archiveBottom = true;
    },
    clickArrow(del){
      this.selectedNum += del;
      if(this.selectedNum < 0)this.selectedNum = 0;
      else if(this.selectedNum > this.archives.length-1)this.selectedNum = this.archives.length-1;
    },
    clickFrame(frame){
      if(this.point < frame[2]){
        return;
      }

      var params = new URLSearchParams();
      params.append("id", this.User.myid);
      params.append("name",frame[0]);

      axios.post(process.env.VUE_APP_POST_URL+"/acount/set_frame.php",params)      
        .then(response => {
          
        console.log(response.data);
        let res = response.data;
        if(res.updated){
          let user = this.$store.getters.user;
          user.frameName = frame[0];
          user.updated = res.updated;

          this.$store.dispatch("setUser",user);
          this.frameBottom = false;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    getIcon(icon,id){
      if(icon == ""){
        const pre = id.slice(0,1);
        const hex = parseInt(pre,16);

        var num = hex%images.length;
        if(isNaN(num)){
          num = 0;
        }

        return require("@/assets/image/icon/"+images[num]+".png");
      }
      else{
        return process.env.VUE_APP_DOMAIN+"/post_images/icon/"+icon;
      }
    },
    getFrame(frame){
      if(frame == ""){
        return "";
      }
      else{
        return require("@/assets/image/archive/"+frame+".png");
      }
    },
    getMedal(arc){
      if(arc[5])return require("@/assets/image/archive/"+arc[0]+".png");
      else return require("@/assets/image/archive/black.png");
    },
    getArchive(arc){
      if(arc[5])return require("@/assets/image/archive/"+arc[1]+".png");
      else return "";
    },
    getObi(arc){
      if(arc[5])return require('@/assets/image/archive/obi.png');
      else return require('@/assets/image/archive/obiBlack.png');
    },
    getTitle(arc){
      if(arc[5])return arc[2];
      else return "？？？";
    },
    getExplain(arc){
      if(arc[5])return arc[3]+arc[4];
      else return arc[3];
    },
    readImage(e){
      if ( e.target.files.length == 0 ) return;
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        this.mainIcon = fileReader.result;
        const image = new Image();
        image.onload = () => {
          this.createIcon(image);
        };

        image.src = event.target.result;
      };
      fileReader.readAsDataURL(e.target.files[0]);
    },
    fetchAppleAcount(id,mail){
      var params = new URLSearchParams();
      params.append("id", this.$store.getters.user.myid);
      params.append("user",id);
      params.append("mail",mail)

      axios.post(process.env.VUE_APP_POST_URL+"/acount/set_login_with_apple_4pc.php",params)      
        .then(response => {
          
        console.log(response.data);
        if(response.data){
          let res = response.data;
          if(res.isAcount == true){
            res.isLogin = true;
            this.loginBottom = false;
            this.$store.dispatch("setUser",res);
          }
          else{
            let user = this.$store.getters.user;
            user.isLogin = true;
            this.$store.dispatch("setUser",user);
          }
          return;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchGoogleAcount(id,mail){
      var params = new URLSearchParams();
      params.append("id", this.$store.getters.user.myid);
      params.append("user",id);
      params.append("mail",mail)

      axios.post(process.env.VUE_APP_POST_URL+"/acount/set_login_with_google_4pc.php",params)      
        .then(response => {
          
        if(response.data){
          let res = response.data;
          if(res.isAcount == true){
            res.isLogin = true;
            this.loginBottom = false;
            this.$store.dispatch("setUser",res);
          }
          else{
            let user = this.$store.getters.user;
            user.isLogin = true;
            this.$store.dispatch("setUser",user);
          }
          return;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    createIcon(image){
      const canvas = document.createElement("canvas");
      canvas.width = 600;
      canvas.height = 600;
      const context = canvas.getContext("2d");

      const width = image.width;
      const height = image.height;

      var delX = 0;
      var delY = 0;

      if(width > height){
        delX = (width-height)/2;
      }
      else{
        delY = (height-width)/2;
      }

      context.drawImage(image,delX,delY,width,height,0,0,canvas.width,canvas.height);
      const base64 = canvas.toDataURL();
      //this.iconBlob = canvas.toDataURL();
      //this.isNewImage = true;

      var bin = atob(base64.replace(/^.*,/, ''));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
          buffer[i] = bin.charCodeAt(i);
      }
      
      try{
        this.iconBlob = new Blob([buffer.buffer], {
          type: 'image/jpg'
        });
        this.isNewImage = true;
      }
      catch (e){
        return;
      }

    },
  },
  computed:{
  }
}

</script>

<style scoped>
:deep(.icon){
  width:80px;
  height:80px;
  top:8px;
  left:8px;
  background-color:#f3f2f9;
}

:deep(.waku){
  width:96px;
  height:96px;
  position:absolute;
  left:0px;
  top:0px;
}

:deep(.archive){
  width: 80px;
  height: 80px;
  position:relative;
}
:deep(.archive_medal){
  width: 70px;
  height: 70px;
  top:0px;
  left:5px;
  position: absolute;
}
:deep(.archive_obi){
  width: 100%;
  height: 30px;
  bottom:0px;
  left:0px;
  position: absolute;
}
:deep(.archive_title){
  width: 100%;
  height: 18px;
  bottom:0px;
  left:0px;
  position: absolute;
  font-size:11px;
}

:deep(.archive_bg){
  /*margin:0 auto;*/
  width: 160px;
  height: 160px;
  position:relative;
}
:deep(.archive_medal_bg){
  width: 140px;
  height: 140px;
  top:0px;
  left:10px;
  position: absolute;
}
:deep(.archive_obi_bg){
  width: 160px;
  height: 60px;
  bottom:0px;
  left:0px;
  position: absolute;
}
:deep(.archive_title_bg){
  width: 160px;
  height: 36px;
  bottom:0px;
  left:0px;
  position: absolute;
  font-size:22px;
}
</style>