const VALUE = {
    PLACE_NAME:[
        "東京",
        "中山",
        "京都",
        "阪神",
        "新潟",
        "函館",
        "福島",
        "中京",
        "小倉",
        "札幌",
    ],
    PLACE_NUM:{
        "東京":0,
        "中山":1,
        "京都":2,
        "阪神":3,
        "新潟":4,
        "函館":5,
        "福島":6,
        "中京":7,
        "小倉":8,
        "札幌":9,
    },
    PLACE_COLORS:[
        "#ff7e7f",
        "#ffad33",
        "#aacb2e",
        "#6eca63",
        "#58e08b",
        "#59c5f9",
        "#7eb2fe",
        "#997ffe",
        "#e57fff",
        "#fe7fcc",
    ],
    GRADE_COLOR:[
        "#ffffff",
        "#1a77d3",
        "#d51b19",
        "#207604",
    ],
    COURSE_COLOR:{
        "芝":"#009100",
        "ダ":"#6f2200",
        "障":"#00466a",
    },
    WAKU_COLOR:[
        "transparent", //not use but require
        "#fff",
        "#000",
        "#f4655f",
        "#59c5f9",
        "#fff394",
        "#58e08b",
        "#ffba5f",
        "#ff93c5",
    ],
    JYOGAI_CODE:{
        "101":"取消",
        "102":"発除",
        "103":"競徐",
        "104":"中止",
        "105":"失格",
        "106":"再騎",
        "107":"降着",
    },
    WEATHER:{
        "晴":"☀️",
        "曇":"☁️",
        "雨":"☔️",
        "小雨":"🌂",
        "雷":"⚡️",
        "雪":"☃️",
        "小雪":"⛄️",
        "":"",
    },
    BAKEN_COLOR:[
        "#565cb0",
        "#cb5354",
        "#51a456",
        "#875999",
        "#519fa9",
        "#e6a33a",
        "#4a94bb",
        "#e4813d",
    ],
    BAKENS:[
        { "name":"単勝", "knd":0, "key":"tans" },
        { "name":"複勝", "knd":1, "key":"hukus" },
        { "name":"枠連", "knd":2, "key":"wakus"},
        { "name":"馬連", "knd":3, "key":"rens"},
        { "name":"ワイド", "knd":4, "key":"wides"},
        { "name":"馬単", "knd":5, "key":"umatans"},
        { "name":"3連複", "knd":6, "key":"pukus"},
        { "name":"3連単", "knd":7, "key":"rentans"},
    ],
}

export default Object.freeze({
    VALUE
});