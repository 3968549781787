<template>
  <v-list-item class="w-100">
    <div class="w-100 pa-0 d-column">
      <div class="d-flex justify-start">
        <div>
          <v-img :src="News.thum" width="100" height="100" cover></v-img>
        </div>
        <div class="pl-2">
          <p :class='[ News.site.indexOf("pakara") > -1 ? "text-red font-weight-bold" : "teext-black font-weight-bold" ]'>{{ News.title }}</p>
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <p :class='[ News.site.indexOf("pakara") > -1 ? "text-red" : "teext-black" ]' style="font-size: 14px;">{{ News.site }}</p>
        <p style="font-size: 14px;">{{ News.date }}</p>
      </div>
    </div>
    <v-divider class="mx-2 text-h4"></v-divider>
  </v-list-item>
</template>

<script>

export default {
  name: 'News-item',
  props:{
    News:{
      default:{}
    }
  },
  components: {
    
  },
  data: () => ({
  }),
  watch:{
  },
  created:function(){
    
  },
  mounted:function(){
    
  },
  methods:{

  },
  computed:{
  }
}

</script>

<style scoped>


</style>