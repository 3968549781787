<template>
  <v-col cols="12">
    <v-row>
      <v-col>
        <v-sheet class="pa-2" elevation="2">
          <v-col cols="12">
            <v-row class="d-flex justify-space-between">
              <h3 class="d-block align-self-center" >基本情報</h3>
              <v-btn class="mr-3 align-self-center" @click="clickReset1">リセット</v-btn>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>開催地</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle class="w-100 d-block" style="height:80px;" v-model="place" rounded="0" color="primary" mandatory >
                        <v-btn v-for="(place,index) in value.PLACE_NAME" :value="(index)" :key="index" :color="value.PLACE_COLORS[index]" class="rounded-pill ml-2" style="height:40px;" >{{ place }}</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col>
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>コース</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle v-model="course" rounded="0" color="primary" mandatory>
                        <v-btn value="0" class="rounded-pill ml-2" color="#009100" style="height:40px;">芝</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" color="#6f2200" style="height:40px;">ダート</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" color="#00466a" style="height:40px;">障害</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6">
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>状態</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle v-model="jyoutai" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">良</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">稍</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">重</v-btn>
                        <v-btn value="3" class="rounded-pill ml-2" style="height:40px;">不</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6">
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>距離</h4>
                    </v-row>
                    <v-row>
                      <v-slider v-model="distance" class="align-center" max="4000" min="0" step="10" hide-details >
                        <template v-slot:append>
                          <v-text-field v-model="distance" hide-details single-line density="compact" step="10" type="number" style="width: 100px" ></v-text-field>
                        </template>
                      </v-slider>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet class="pa-2" elevation="2">

          <v-col cols="12">
            <v-row class="d-flex justify-space-between">
              <h3 class="d-block align-self-center" >詳細情報(プレミアム機能)</h3>
              <v-btn class="mr-3 align-self-center" @click="clickReset2">リセット</v-btn>
            </v-row>
          </v-col>

          <v-col>
            <v-row>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>頭数</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="count" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">~10</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">11•12</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">12•14</v-btn>
                        <v-btn value="5" class="rounded-pill ml-2" style="height:40px;">14•15</v-btn>
                        <v-btn value="6" class="rounded-pill ml-2" style="height:40px;">16•18</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>クラス</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" v-model="cls" style="height:80px;" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">全て</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">未勝利</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">新馬</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">1勝<br>500万下</v-btn>
                        <v-btn value="5" class="rounded-pill ml-2" style="height:40px;">2勝<br>1000万下</v-btn>
                        <v-btn value="6" class="rounded-pill ml-2" style="height:40px;">3勝<br>1600万下</v-btn>
                        <v-btn value="7" class="rounded-pill ml-2" style="height:40px;">オープン</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>条件</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="jyouken" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">馬齢</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">定量</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">ハンデ</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>1番人気単勝オッズ</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="ninki1" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">~2</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">2~3</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">4~5</v-btn>
                        <v-btn value="5" class="rounded-pill ml-2" style="height:40px;">5~</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>2番人気単勝オッズ</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="ninki2" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">~2</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">2~3</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">4~5</v-btn>
                        <v-btn value="5" class="rounded-pill ml-2" style="height:40px;">5~</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>3番人気単勝オッズ</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="ninki3" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">~2</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">2~3</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">4~5</v-btn>
                        <v-btn value="5" class="rounded-pill ml-2" style="height:40px;">5~</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>検索範囲</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="kikan1" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">近1年</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">近2年</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">近3年</v-btn>
                        <v-btn value="5" class="rounded-pill ml-2" style="height:40px;">近5年</v-btn>
                        <v-btn value="6" class="rounded-pill ml-2" style="height:40px;">近10年</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>検索範囲</h4>
                    </v-row>
                    <v-row>
                      <v-btn-toggle :disabled="!user.isPurchase" class="w-100 d-block" style="height:80px;" v-model="kikan2" rounded="0" color="#03a9f4" mandatory >
                        <v-btn value="0" class="rounded-pill ml-2" style="height:40px;">指定なし</v-btn>
                        <v-btn value="1" class="rounded-pill ml-2" style="height:40px;">春競馬</v-btn>
                        <v-btn value="2" class="rounded-pill ml-2" style="height:40px;">夏競馬</v-btn>
                        <v-btn value="4" class="rounded-pill ml-2" style="height:40px;">秋競馬</v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

            </v-row>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row id="stats_result">
      <v-col v-if="res">
        <v-sheet class="pa-2" elevation="2">
          <h3>統計データ</h3>

          <v-col>
            <v-row>


              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>荒れ率</h4><span>{{ ` : ${res.are} (${res.are_count}/${res.count})` }}</span>
                    </v-row>
                    <v-row>
                      <Doughnut class="ch_title ch_label" data-unit="%" :data="chartDatas[0]" :options="chartOptionNoLeg" style="max-height: 150px;" />
                    </v-row>
                    <v-row>
                      <v-table density="compact" class="w-100">
                        <thead class="">
                          <tr>
                            <th class="text-center" style="width:25%;"></th>
                            <th class="text-center" style="width:25%;">単勝率</th>
                            <th class="text-center" style="width:25%;">連対率</th>
                            <th class="text-center" style="width:25%;">複勝率</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="index in 3" :key="index" class="text-center">
                            <td>{{ `${index}番人気` }}</td>
                            <td colspan="3" v-html="getAreritu(index)" ></td>
                          </tr>
                        </tbody>
                      </v-table>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>配当(倍率)</h4>
                    </v-row>
                    <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-row>
                        <Doughnut class="ch_title ch_label" data-unit="%" :data="chartDatas[1]" :options="chartOptions" style="max-height: 180px;" />
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-row>
                        <Doughnut class="ch_title ch_label" data-unit="%" :data="chartDatas[2]" :options="chartOptions" style="max-height: 180px;" />
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-row>
                        <Doughnut class="ch_title ch_label" data-unit="%" :data="chartDatas[3]" :options="chartOptions" style="max-height: 180px;" />
                      </v-row>
                    </v-col>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>払戻し</h4>
                    </v-row>

                    <v-row>
                      <v-table class="w-100 mtable">
                        <thead>
                          <tr class="font-weight-bold">
                            <th class="text-center" style="width:25%;height:44px;">形式</th>
                            <th class="text-center" style="width:25%;height:44px;">最小</th>
                            <th class="text-center" style="width:25%;height:44px;">最大</th>
                            <th class="text-center" style="width:25%;height:44px;">平均</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="index in 8" :key="index" class="text-center" style="max-height:44px;">
                            <td class="font-weight-bold" :style="`background-color:${value.BAKEN_COLOR[index-1]};color:#fff;height:44px;`">{{ value.BAKENS[index-1].name }}</td>
                            <td style="height:44px;">{{ res.harai[index-1].min.toLocaleString()+"円" }}</td>
                            <td style="height:44px;">{{ res.harai[index-1].max.toLocaleString()+"円" }}</td>
                            <td style="height:44px;">{{ res.harai[index-1].avg.toLocaleString()+"円" }}</td>
                          </tr>
                        </tbody>
                      </v-table>
                    </v-row>

                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>人気-着回数</h4>
                    </v-row>
                    <v-row>
                      <Line :data="ninkiDatas" :options="ninkiOptions" />
                    </v-row>

                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>オッズ-回収率</h4>
                    </v-row>
                    <v-row>
                      <Line :data="oddsDatas" :options="oddsOptions" />
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>体重分布</h4>
                    </v-row>
                    <v-row>
                      <Line :data="weightDatas" :options="weightOptions" />
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>

              <v-col cols="12" md="6" >
                <v-sheet class="pa-2" elevation="1">
                  <v-col>
                    <v-row>
                      <h4>体重増減</h4>
                    </v-row>
                    <v-row>
                      <Line :data="zougenDatas" :options="zougenOptions" />
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>


            </v-row>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>

    <v-btn class="mt-auto text-white rounded-pill font-weight-bold" @click="clickSearch" color="#03a9f4"
      icon="mdi-magnify" style="position:fixed;bottom:20px;right:20px;width:50px;height:50px;font-size:14px;" >
    </v-btn>

    <v-dialog v-model="alertDialog" class="w-dialog">
      <v-alert type="warning">該当のレースがありません</v-alert>
    </v-dialog>
    
  
  </v-col>
</template>

<script>
import value from "../../global.js"
import axios from 'axios'
import { Doughnut, Line} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, LineElement, PointElement, LinearScale, CategoryScale);

var plugin = {
  id: 'charts', 
  beforeDraw: function (chart) {
    if(chart.canvas.className.indexOf("ch_title") > -1){
      var width = chart.chartArea.width;
      var ctx = chart.ctx;

      ctx.restore();
      ctx.font = "bold 1em sans-serif";
      ctx.textBaseline = "middle";

      var text = ""
      if(chart.data.datasets[0] && chart.data.datasets[0].label){
        text = chart.data.datasets[0].label;
      }

      var textX = Math.round((width - ctx.measureText(text).width) / 2);
      var textY = chart.chartArea.top + chart.chartArea.height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  },
  afterDraw: function(chart) {
    if(chart.canvas.className.indexOf("ch_label") > -1){
      let ctx = chart.ctx
      chart.data.datasets.forEach((dataset, i) => {

        let meta = chart.getDatasetMeta(i)
        if (!meta.hidden) {
          meta.data.forEach(function (element, index) {
            if(dataset.data[index] != 0){
              let fontSize = 10;
              ctx.fillStyle = '#393939'
              ctx.font = "bold "+fontSize+"px sans-serif";

              let labelString = chart.data.labels[index]
              let dataString = dataset.data[index].toString() + chart.canvas.dataset.unit;

              ctx.textAlign = 'center'
              ctx.textBaseline = 'middle'

              let padding = -2
              let position = element.tooltipPosition()
              
              ctx.fillText(labelString, position.x, position.y - (fontSize / 2) - padding) // title
              ctx.font = "10px sans-serif";
              ctx.fillText(dataString, position.x, position.y + (fontSize / 2) - padding)  
            }
          })
        }
      })
    }
  },
};
ChartJS.register(plugin);

export default {
	name: 'Stats-race',

	components: {
    Doughnut,
    Line,
	},
  head:{
		title:"競馬の過去10年分の統計データ",
		meta:[
			{ name:"description",content:"JRA中央競馬の過去10年間のレースを分析。条件別に荒れやすさや平均配当、オッズ別回収率を確認できます。" },
			{ property: "og:type", content: "website" },
			{ property: "og:url", content: process.env.VUE_APP_DOMAIN+"/state/race" },
			{ property: "og:title", content: "pakara - 競馬の総合情報サイト" },
			{ property: "og:description", content: "JRA中央競馬の過去10年間のレースを分析。条件別に荒れやすさや平均配当、オッズ別回収率を確認できます。" },
			{ property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
			{ property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
		],
	},
  props:{
    isSearch:{
      default:false,
    },
    Place:{
      default:0,
    },
    Course:{
      default:0,
    },
    Jyoutai:{
      default:0,
    },
    Distance:{
      default:0,
    },
  },
	data: () => ({
    value:value.VALUE,

    user:{},

    res:null,

    place:0,
    course:0,
    jyoutai:0,
    distance:0,
    count:0,
    cls:0,
    jyouken:0,
    ninki1:0,
    ninki2:0,
    ninki3:0,
    kikan1:0,
    kikan2:0,

    alertDialog:false,

    chartDatas:[],

    ninkiDatas:null,
    oddsDatas:null,
    weightDatas:null,
    zougenDatas:null,

    chartOptionNoLeg:{
      responsive: true,
      maintainAspectRatio: false,
      plugins:{
        legend: {
          display:false,
        },
      },
    },

    chartOptions:{
      responsive: true,
      maintainAspectRatio: false,
      plugins:{
        legend: {
          position:"right",
          labels:{boxWidth:10, boxHeight:10,padding:0,font:{size:10}},
        },
      },
    },

    ninkiOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:30, boxHeight:10,font:{size:12}},
        },
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          min:1,
          ticks:{
            stepSize:1,
            callback:(value) => {
              if(value == 1)return value+"人気";
              return value;
            },
          }
        },
        y:{
          id:"y",
          position:"left",
          min:0,
          scaleLabel:{
            display:true,
            fontColor:"#ff0000",
          },
          ticks:{
            stepSize:100,
            callback:(value) => value+"回",
          },
        },
      },
    },

    oddsOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:30, boxHeight:10,font:{size:12}},
        },
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          min:1,
          ticks:{
            stepSize:3,
            callback:(value) => {
              if(value == 1)return "";
              return "~"+value;
            },
          }
        },
        y:{
          id:"y",
          position:"left",
          min:0,
          scaleLabel:{
            display:true,
            fontColor:"#ff0000",
          },
          ticks:{
            stepSize:10,
            callback:(value) => value+"%",
          },
        },
      },
    },

    weightOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:30, boxHeight:10,font:{size:12}},
        },
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          min:360,
          max:550,
          ticks:{
            stepSize:10,
            callback:(value) => {
              return value;
            },
          }
        },
        y:{
          id:"y",
          position:"left",
          min:0,
          scaleLabel:{
            display:true,
            fontColor:"#ff0000",
          },
          ticks:{
            stepSize:200,
            callback:(value) => value+"回",
          },
        },
      },
    },

    zougenOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:30, boxHeight:10,font:{size:12}},
        },
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          min:-14,
          max:14,
          ticks:{
            stepSize:2,
            callback:(value) => {
              return value;
            },
          }
        },
        y:{
          id:"y",
          position:"left",
          min:0,
          scaleLabel:{
            display:true,
            fontColor:"#ff0000",
          },
          ticks:{
            stepSize:200,
            callback:(value) => value+"回",
          },
        },
      },
    },
	}),
  watch:{
    
  },
  created:function(){
    this.user = this.$store.getters.user;
    this.initial();
    if(this.isSearch){
      this.place = this.Place;

      this.distance = this.Distance;

      const cor = ["芝","ダ","障"];
      this.course = cor.indexOf(this.Course);      

      const baba = ["良","梢","重","不"];

      if(this.Jyoutai == undefined || this.Jyoutai == "")this.jyoutai = 0;
      else this.jyoutai = baba.indexOf(this.Jyoutai);

      var that = this;
      setTimeout(function(){
        that.clickSearch();
      },200);
    }
  },
  mounted:function(){
    this.$store.watch(
      (state,getters) => getters.user,
      (newVal) => {
        this.user = newVal;
      }
    );
  },
  methods:{
    initial(){

      let emptyData = {
        labels: [""],
        datasets: [{
          label: "",
          data: [],
          hoverOffset: 4,
        }],
      };

      for(let n=0;n<6;n++){
        this.chartDatas.push(emptyData);
      }

      this.ninkiDatas = null;
      this.oddsDatas = null;
      this.weightDatas = null;
      this.zougenDatas = null;
    },
    getAreritu(num){

      var vals = [];
      var wis = [];
      var cols = ["#7ffefd","#81e9ff","#7fbafe","#ff7e7f"];
      for(let n=0;n<3;n++){
        vals.push(this.res["rn"+num+"_"+(n+1)]);

        let wi = vals[n].replace("%","")-0.0;
        wis.push(wi);
      }

      return `<div class="d-flex flex-column">`+
                `<div class="d-flex justify-space-around">`+
                  `<p>${vals[0]}</p><p>${vals[1]}</p><p>${vals[2]}</p>`+
                `</div>`+
                `<div class="d-flex">`+
                  `<div style="height:10px;width:${wis[0]}%;background-color:${cols[0]}"></div>`+
                  `<div style="height:10px;width:${wis[1]-wis[0]}%;background-color:${cols[1]}"></div>`+
                  `<div style="height:10px;width:${wis[2]-wis[1]}%;background-color:${cols[2]}"></div>`+
                  `<div style="height:10px;width:${100.0-wis[2]}%;background-color:${cols[3]}"></div>`+
                `</div>`+
             `</div>`;

    },
    clickSearch(){
      var params = new URLSearchParams();
      params.append("place", this.place);
      
      const cor = ["芝","ダ","障"];
      params.append("course", cor[this.course]);

      const baba = ["良","梢","重","不"];
      params.append("baba", baba[this.jyoutai]);

      params.append("class", this.cls);
      params.append("tan1", this.ninki1);
      params.append("tan2", this.ninki2);
      params.append("tan3", this.ninki3);
      params.append("distance", this.distance);
      params.append("count", this.count);
      params.append("hand", this.jyouken);
      params.append("range", this.kikan1);
      params.append("season", this.ninki2);

              
      axios.post(process.env.VUE_APP_POST_URL+"/data/get_data_v2.11.4.php",params)      
        .then(response => {
      
          const res = response.data;
          //console.log(res);

          if(res && res.result == "true"){
            this.res = res;

            let are = res.are.replace("%","")-0.0;
            let areData = {
              labels: ["荒","堅"],
              datasets: [{
                label: res.are,
                data: [are,Math.floor((100.0-are)*10)/10],
                hoverOffset: 4,
                backgroundColor:["#ff7e7f","#7ffefd"],
              }],
            };

            this.chartDatas[0] = areData;


            const tanStr = ["~2.5","5","7.5","10","20","30","40","50","60","70","80","90","100~"];
            var tanCol = [];
            for(let n=0;n<5;n++)tanCol.push(this.hsvTohex((0.5+(0.02)*n)*360,50,100));
            for(let n=0;n<10;n++)tanCol.push(this.hsvTohex((0.05-(0.005)*n)*360,50,90));
            
            this.chartDatas[1] = this.getChartData(res.tan_kubun,"単勝",tanStr,tanCol);


            const renStr = ["~10","20","30","40","50","60","70","80","90","100~"];
            var renCol = [];
            for(let n=0;n<7;n++)renCol.push(this.hsvTohex((0.5+(0.1/7)*n)*360,50,100));
            for(let n=0;n<3;n++)renCol.push(this.hsvTohex((0.05-(0.05/3)*n)*360,50,90));
            
            this.chartDatas[2] = this.getChartData(res.ren_kubun,"馬連",renStr,renCol);


            const pukStr = ["~10","20","30","40","50","60","70","80","90","100","250","500","1000~"];
            var pukCol = [];
            for(let n=0;n<9;n++)pukCol.push(this.hsvTohex((0.5+(0.1/9)*n)*360,50,100));
            for(let n=0;n<4;n++)pukCol.push(this.hsvTohex((0.05-(0.05/3)*n)*360,50,90));
            
            this.chartDatas[3] = this.getChartData(res.puku_kubun,"3連複",pukStr,pukCol);
            

          //ninki chart
            let ninData1 = [];
            let ninData2 = [];
            let ninData3 = [];
            let ninCnt1 = 0;
            let ninCnt2 = 0;
            let ninCnt3 = 0;
            for(let n=0;n<18;n++){
              ninData1.push({x:(n+1),y:res.ninki.n1[n]});
              ninData2.push({x:(n+1),y:res.ninki.n2[n]});
              ninData3.push({x:(n+1),y:res.ninki.n3[n]});
              ninCnt1 += res.ninki.n1[n];
              ninCnt2 += res.ninki.n2[n];
              ninCnt3 += res.ninki.n3[n];
            }

            let n1Data = {
              label: "1人気:("+ninCnt1+"頭)",
              data: ninData1,
              borderColor: "#ffb5a2",
              backgroundColor:"#ffb5a2",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            let n2Data = {
              label: "2人気:("+ninCnt2+"頭)",
              data: ninData2,
              borderColor: "#87d3ef",
              backgroundColor:"#87d3ef",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            let n3Data = {
              label: "3人気:("+ninCnt3+"頭)",
              data: ninData3,
              borderColor: "#97c899",
              backgroundColor: "#97c899",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            this.ninkiDatas = {
              datasets: [n1Data,n2Data,n3Data],
            };


          //odds chart
            let tanDatas = [];
            let hukuDatas = [];

            const max = Math.max(res.kaisyu.length,res.kaisyuHuk.length);
            for(let n=1;n<max;n++){
              if(res.kaisyu.length > n)tanDatas.push({x:n,y:Math.round(res.kaisyu[n]*10)/10});
              if(res.kaisyuHuk.length > n)hukuDatas.push({x:n,y:Math.round(res.kaisyuHuk[n]*10)/10});
            }

            let tanDataSet = {
              label: "単勝オッズ",
              data: tanDatas,
              borderColor: "#ffb5a2",
              backgroundColor:"#ffb5a2",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            let hukDataSet = {
              label: "複勝オッズ",
              data: hukuDatas,
              borderColor: "#87d3ef",
              backgroundColor: "#87d3ef",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            this.oddsDatas = {
              datasets: [tanDataSet,hukDataSet],
            }


          //weight chart
            let weg1Datas = [];
            let weg1Ary = res.taiju1.split("-");
            let weg1Cnt = 0;
            let n = 360;
            for(let i=0;i<weg1Ary.length;i++){
              weg1Datas.push({x:n,y:weg1Ary[i]});
              n += 10;
              weg1Cnt += weg1Ary[i]-0;
            }

            let weg2Datas = [];
            let weg2Ary = res.taiju2.split("-");
            let weg2Cnt = 0;
            n = 360;
            for(let i=0;i<weg2Ary.length;i++){
              weg2Datas.push({x:n,y:weg2Ary[i]});
              n += 10;
              weg2Cnt += weg2Ary[i]-0;
            }

            let weg1DataSet = {
              label: `1着:(${weg1Cnt}頭)`,
              data: weg1Datas,
              borderColor: "#ffb5a2",
              backgroundColor:"#ffb5a2",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            let weg2DataSet = {
              label: `2着:(${weg2Cnt}頭)`,
              data: weg2Datas,
              borderColor: "#87d3ef",
              backgroundColor:"#87d3ef",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            this.weightDatas = {
              datasets:[weg1DataSet,weg2DataSet],
            }


          //zougen chart
            let zou1Datas = [];
            let zou1Ary = res.zougen1.split("-");
            let zou1Cnt = 0;
            n = -14;
            for(let i=0;i<zou1Ary.length;i++){
              zou1Datas.push({x:n,y:zou1Ary[i]});
              n += 2;
              zou1Cnt += zou1Ary[i]-0;
            }

            let zou2Datas = [];
            let zou2Ary = res.zougen2.split("-");
            let zou2Cnt = 0;
            n = -14;
            for(let i=0;i<zou2Ary.length;i++){
              zou2Datas.push({x:n,y:zou2Ary[i]});
              n += 2;
              zou2Cnt += zou2Ary[i]-0;
            }

            let zou1DataSet = {
              label: `1着:(${zou1Cnt}頭)`,
              data: zou1Datas,
              borderColor: "#ffb5a2",
              backgroundColor:"#ffb5a2",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            let zou2DataSet = {
              label: `2着:(${zou2Cnt}頭)`,
              data: zou2Datas,
              borderColor: "#87d3ef",
              backgroundColor:"#87d3ef",
              yAxisID:"y",
              borderWidth:2,
              pointStyle:false,
            };

            this.zougenDatas = {
              datasets:[zou1DataSet,zou2DataSet],
            }
            
            setTimeout(function(){
              document.getElementById("stats_result").scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            },500);
            
            /*this.$refs.result.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });*/

          }
          else{
            this.res = null;
            this.initial();
            this.alertDialog = true;
          }

      })
      .catch(error => {
        console.log(error);
      });

    },
    getChartData(kubun,label,strs,cols){

      const kubs = kubun.split("-");
      let sum = 0.0;
      for(let n=0;n<kubs.length;n++)sum += kubs[n]-0.0;
            
      let vals = [];
      for(let n=0;n<kubs.length;n++){
        vals.push(Math.floor(kubs[n]/sum*100*10)/10);
      }

      let data = {
        labels: strs,
        datasets: [{
          label: label,
          data: vals,
          hoverOffset: 4,
          backgroundColor:cols,
        }],
      };

      return data;

    },
    clickReset1:function(){
      this.place = 0;
      this.course = 0;
      this.jyoutai = 0;
      this.distance = 0;
    },
    clickReset2:function(){
      this.count = 0;
      this.cls = 0;
      this.jyouken = 0;
      this.ninki1 = 0;
      this.ninki2 = 0;
      this.ninki3 = 0;
      this.kikan1 = 0;
      this.kikan2 = 0;
    },
    hsvTohex(hue, saturation, value) {
      var result = "#fff";

      if (((hue || hue === 0) && hue <= 360) && ((saturation || saturation === 0) && saturation <= 100) && ((value || value === 0) && value <= 100)) {
        var red   = 0,
            green = 0,
            blue  = 0,
            i     = 0,
            f     = 0,
            q     = 0,
            p     = 0,
            t     = 0;

        hue        = Number(hue)        / 60;
        saturation = Number(saturation) / 100;
        value      = Number(value)      / 100;

        if (saturation === 0) {
          red   = value;
          green = value;
          blue  = value;
        } else {
          i = Math.floor(hue);
          f = hue - i;
          p = value * (1 - saturation);
          q = value * (1 - saturation * f);
          t = value * (1 - saturation * (1 - f));

          switch (i) {
            case 0 :
              red   = value;
              green = t;
              blue  = p;
              break;
            case 1 :
              red   = q;
              green = value;
              blue  = p;
              break;
            case 2 :
              red   = p;
              green = value;
              blue  = t;
              break;
            case 3 :
              red   = p;
              green = q;
              blue  = value;
              break;
            case 4 :
              red   = t;
              green = p;
              blue  = value;
              break;
            case 5 :
              red   = value;
              green = p;
              blue  = q;
              break;
          }
        }

        result = "#" + ("00"+Math.round(red   * 255).toString(16)).slice(-2)+
        ("00"+Math.round(green * 255).toString(16)).slice(-2)+
        ("00"+Math.round(blue  * 255).toString(16)).slice(-2);
      }

      return result;
    },
  },
}
</script>

<style scped>

.mtable thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
.mtable thead th:last-child {
  border-right : 1px solid #CCC;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}
.mtable tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
.mtable tbody td:last-child{
  border-right : 1px solid #CCC;
}

</style>