<template>
  <v-list-item class="w-100" @click="clickComment" >
    <v-col cols="12">
      <v-row>
        <div class="d-flex justify-space-between w-100">
          <div class="d-flex justify-start">
            <div @click.stop.prevent="clickIcon">
              <v-img class="rounded-circle icon" cover :src="getIcon(comment.icon,comment.user_id)" />
              <v-img class="rounded-circle waku" cover :src="getFrame(comment.frame)" />
            </div>
            <div class="mx-2 d-flex flex-column justify-start align-self-center">
              <div class="font-weight-bold" v-html="getName(index,comment)"></div>
            </div>
          </div>
          <div style="font-size:12px;;color:#7e7e7e;" >{{ comment.created_date }}</div>
        </div>
      </v-row>
      <v-row class="d-block">
        <div class="triangle"></div>
        <div class="my-2 px-3 py-3 comment rounded-xl" style="min-width:50px;" v-html="comment.comment.replaceAll('\n','<br>')" ></div>
      </v-row>
    </v-col>
    <v-divider class="mx-2 text-h4"></v-divider>
  </v-list-item>
</template>

<script>
import value from "../../global.js"

export default {
  name: 'News-board-item',
  emits: ['clickIcon'],
  props:{
    currentRace:{
      default:null,
    },
    comment:{
      default:{},
    },
    index:{
      default:0,
    },
    IsNew:{
      default:false,
    },
  },
  components: {
    
  },
  data: () => ({
    value:value.VALUE,
    images:["a","b","c","d","e","f"],
  }),
  watch:{
  },
  created:function(){
    
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    clickIcon(){
      this.$emit("clickIcon",this.comment);
    },
    clickComment(){
      this.$emit("clickComment",this.comment);
    },
    getName(index,comment){
      if(!this.IsNew){
        return `${index}枠 ${decodeURI(comment.userName)}`;
      }
      else{
        let race = comment.race;
        if(race){
          return `<p>${race.date.slice(0,4+"/"+race.date.slice(4,2)+"/"+race.date.slice(6,2))} ${this.value.PLACE_NAME[race.place]} ${race.round}R</p><p>${race.race_name}</p>`;
        }
        else return "";
      }
    },
    getIcon(icon,id){
      if(icon == ""){
        const pre = id.slice(0,1);
        const hex = parseInt(pre,16);

        const num = hex%this.images.length;

        return require("@/assets/image/icon/"+this.images[num]+".png");
      }
      else{
        return process.env.VUE_APP_DOMAIN+"/post_images/icon/"+icon;
      }
    },
    getFrame(frame){
      if(frame == ""){
        return "";
      }
      else{
        return require("@/assets/image/archive/"+frame+".png");
      }
    },
  },
  computed:{
  }
}

</script>

<style scoped>

:deep(.icon){
  width:50px;
  height:50px;
  background-color:#f3f2f9;
}

:deep(.waku){
  width:60px;
  height:60px;
  position:absolute;
  left:12px;
  top:0;
}

:deep(.comment){
  background-color:#80e064;
  width: fit-content;
  margin-left:18px;
  margin-right:8px;
}

.triangle {
  height: 30px;
  width: 60px;
  margin-left: 30px;
  margin-bottom: -20px;
  margin-top:18px;
  background-color:#80e064;
  clip-path: polygon(5% 0, 50% 100%, 0 100%);
}

</style>