<template>
  <v-layout>
    <v-main>
      <v-container>
        <v-col cols="12">
          <v-row>
            <v-tabs class="w-100 mb-tabs" v-model="tab_stats" color="#03a9f4" >
              <v-tab @click="clickRace" value="stats_1">統計</v-tab>
              <v-tab @click="clickHorse" value="stats_2">馬検索</v-tab>
              <v-tab @click="clickKisyu" value="stats_3">騎手検索</v-tab>
            </v-tabs>
          </v-row>
        </v-col>

        <v-row>
          <v-window disabled v-model="tab_stats" class="w-100" style="min-height: 500px;">

            <v-window-item value="stats_1">
              <Race />
            </v-window-item>

            <v-window-item value="stats_2">
              <v-col cols="12">
                <v-text-field v-model="searchStr" clearable outlined @focus="onFocus()" @blur="onOutFocus()" :loading="nowLoad" placeholder="馬名を入力" ></v-text-field>
                <v-list v-show="incItem.length && isFocus" class="dropdaown" elevation="2">
                  <v-list-item v-for="item in incItem" :title="item" @click="clickStr(item)" :key="item"></v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12">
                <Horse @clickHist="clickHist" :isDialog="false" :umaId="umaId" :umaName="umaName" />
              </v-col>
            </v-window-item>

            <v-window-item value="stats_3">
              <v-col cols="12">
                <v-text-field v-model="searchStrKisyu" clearable outlined @focus="onFocusKisyu()" @blur="onOutFocusKisyu()" :loading="kisyuItem.nowLoad" placeholder="騎手名を入力" ></v-text-field>
                <v-list v-show="kisyuItem.incItem.length && kisyuItem.isFocus" class="dropdaown" elevation="2">
                  <v-list-item v-for="item in kisyuItem.incItem" :title="item" @click="clickStrKisyu(item)" :key="item"></v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12">
                <Kisyu  :isDialog="false" :kisyuId="kisyuId" :kisyuName="kisyuName"/>
              </v-col>
            </v-window-item>

          </v-window>
        </v-row>

        <v-dialog v-model="raceDialog" class="w-dialog pa-0">
          <div class="d-flex justify-end">
            <v-btn class="mb-2" icon @click="raceDialog = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <RaceDetaileContainer :CurrentRace="modalRace" :IsModal="true" :key="modalRace.id"/>
        </v-dialog>
          
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
import axios from 'axios'

import Race from "@/components/stats/StatsRace.vue"
import Horse from "@/components/stats/Horse.vue"
import Kisyu from '@/components/stats/Kisyu.vue'

import RaceDetaileContainer from "@/components/race/RaceDetaileContainer.vue"

export default {
	name: 'Stats-top',

	components: {
    Race,
    Horse,
    Kisyu,
    RaceDetaileContainer,
	},
	data: () => ({
		tab_stats:0,
    umaName:"_",
    umaId:-1,
    incItem:[],
    baseItem:[],
    inputStr:"",
    searchStr:"",
    nowLoad:false,
    isFocus:false,


    searchStrKisyu:"",

    kisyuName:"_",
    kisyuId:-1,
    kisyuItem:{
      incItem:[],
      baseItem:[],
      inputStr:"",
      nowLoad:false,
      isFocus:false,
    },

    raceDialog:false,
    modalRace:null,
	}),
  watch:{
    $route(to,from){
      switch(to.name){
        case "Stats":
          this.tab_stats = 0;
          break;
        case "Horse":
          this.tab_stats = 1;
          /*if(this.$route.params.umaId && this.$route.params.umaId != -1){
            this.uma_id = this.$route.params.umaId;
            this.uma_name = this.$route.params.umaName;
          }*/
          break;
        case "Kisyu":
          this.tab_stats = 2;
          if(this.$route.params.kisyuId && this.$route.params.kisyuId != -1){
            this.kisyuId = this.$route.params.kisyuId;
            this.kisyuName = this.$route.params.kisyuName;
          }
          break
      }
      
      console.log(from);
    },
    searchStr(val){
      if(val == null || val == ""){
        this.incItem = [];
        this.baseItem = [];
        this.nowLoad = false;
        //this.$refs.combo.cachedItems = [];
      }
      else{
        if(val.length == 1){
          if(!this.nowLoad && val.match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/)){
            this.fetchInc(val.slice(0,1));
          }
        }
        else if(this.baseItem.length > 0){
          var str = this.hiraToKana(val).toUpperCase();

          this.incItem = this.baseItem.filter(elm => {
            return (elm.indexOf(str) == 0);
          });
        }
      }
    },
    searchStrKisyu(val){
      if(val == null || val == ""){
        this.kisyuItem.incItem = [];
        this.kisyuItem.baseItem = [];
        this.kisyuItem.nowLoad = false;
      }
      else{
        if(val.length == 1){
          if(!this.kisyuItem.nowLoad && val.match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/)){
            this.fetchIncKisyu(val.slice(0,1));
          }
        }
        else if(this.kisyuItem.baseItem.length > 0){
          var str = this.hiraToKana(val).toUpperCase();

          this.kisyuItem.incItem = this.kisyuItem.baseItem.filter(elm => {
            return (elm.indexOf(str) == 0);
          });
        }
      }
    },
  },
  created:function(){
    switch(this.$route.name){
      case "Stats":
        this.tab_stats = 0;
        break;
      case "Horse":
        if(this.$route.params.umaId && this.$route.params.umaId != -1){
          //this.uma_id = this.$route.params.umaId;
          //this.uma_name = this.$route.params.umaName;
        }

        this.tab_stats = 1;
        break;
      case "Kisyu":
        if(this.$route.params.kisyuId && this.$route.params.kisyuId != -1){
          this.kisyuId = this.$route.params.kisyuId;
          this.kisyuName = this.$route.params.kisyuName;
        }

        this.tab_stats = 2;
        break;
    }
  },
  mounted:function(){
    
  },
  methods:{
    onFocus(){
      this.isFocus = true
    },
    onOutFocus(){
      var that = this;
      setTimeout(function(){
        that.isFocus = false;
      },100);
    },
    onFocusKisyu(){
      this.kisyuItem.isFocus = true
    },
    onOutFocusKisyu(){
      var that = this;
      setTimeout(function(){
        that.kisyuItem.isFocus = false;
      },100);
    },
    clickRace(){
      this.$router.push({name:"Stats"});
    },
    clickHorse(){
      this.$router.push({name:"Horse",params:{umaId:this.umaId,umaName:this.umaName}});
    },
    clickKisyu(){
      this.$router.push({name:"Kisyu",params:{kisyuId:this.kisyuId,kisyuName:this.kisyuName}});
    },
    clickHist(race){
      this.modalRace = race;
      this.raceDialog = true;
    },
    clickStr(val){
      if(!val)return;
      const uma = val.split("-");
      if(uma.length >= 2){
        this.umaId = uma[1];
        this.umaName = uma[0];
        this.$router.push({name:"Horse",params:{umaId:uma[1],umaName:uma[0]}});
      }
    },
    clickStrKisyu(val){
      if(!val)return;
      const kisyu = val.split("-");
      if(kisyu.length >= 2){
        this.kisyuId = kisyu[1];
        this.kisyuName = kisyu[0];
        this.$router.push({name:"Kisyu",params:{kisyuId:kisyu[1],kisyuName:kisyu[0]}});
      }
    },
    fetchInc(str){
      this.nowLoad = true;

      var params = new URLSearchParams();
      params.append("str", str);        
              
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_inc_list_4pc.php",params)      
        .then(response => {

        console.log(response.data);
        if(this.nowLoad){
          this.nowLoad = false;
          this.baseItem = response.data;
          this.incItem = this.baseItem.slice(0,100);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchIncKisyu(str){
      this.kisyuItem.nowLoad = true;

      var params = new URLSearchParams();
      params.append("str", str);        
              
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_inc_list_kisyu_4pc.php",params)      
        .then(response => {

        console.log(response.data);
        if(this.kisyuItem.nowLoad){
          this.kisyuItem.nowLoad = false;
          this.kisyuItem.baseItem = response.data;
          this.kisyuItem.incItem = this.kisyuItem.baseItem.slice(0,100);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    hiraToKana(str) {
      return str.replace(/[\u3041-\u3096]/g, function(match) {
          var chr = match.charCodeAt(0) + 0x60;
          return String.fromCharCode(chr);
      });
    },
  },
}
</script>

<style scoped>
.dropdaown{
  position: absolute;
  max-height: 300px;
  width: 98%;
  z-index: 99;
}
</style>