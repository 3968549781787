<template>
  <div class="d-flex w-100 justify-space-between" style="height:44px;min-height:44px;">
    <div class="d-flex" style="">
      <div class="collItem" style="min-width:50px;">
        <div class="d-flex flex-column align-center">
          <p class="font-weight-bold" style="text-align:center;">{{ horse.umaban }}</p>
          <p style="font-size:14px;text-align:center;">{{ horse.name.slice(0,3) }}</p>
        </div>
      </div>
      <div class="collItem d-flex align-center">
        <p style="text-align:center;min-width: 20px;">-</p>
      </div>
    </div>

    <div class="d-flex justify-start" style="width:50%;" >
      <div v-for="index in 3" :class='[ isShow[index-1] == 2 ? "round-check align-self-center" : "align-self-center"]' style="width:33%;" :key="index" >
        <v-checkbox v-if="isShow[index-1] > 0" v-model:model-value="checks[index-1]" @change="clickCheck(index-1)" rounded="xl" class="d-flex align-center" color="#03a9f4"></v-checkbox>
      </div>
    </div>
  </div>
  <v-divider class="mx-0 my-0"></v-divider>
</template>
<script>
import value from "../../global.js"

export default {
  name: 'Baken-item-',
  emits:["clickCheck"],
  props:{
    horse:{
      default:{},
    },
    baken:{
      default:0,
    },
    knd:{
      default:0,
    },
    index:{
      default:0,
    },
    select:{
      default:[false,false,false,false]
    },
    isShow:{
      default:[0,0,0],
    }
  },
  data: () => ({
    value:value.VALUE,
    checks:[false,false,false],
  }),
  created:function(){
    this.initial();
  },
  mounted:function(){
    
  },
  watch:{
    select(){
      this.checks = this.select;
    },
  },
  methods:{
    initial(){
      this.checks = this.select;
    },
    clickCheck(col){
      this.$emit("clickCheck",this.baken,this.knd,this.index,col,this.checks[col]);
    },
  },
};

</script>


<style scoped>
.collItem{
  width: 20%;
}
</style>

<style>
.round-check .mdi-checkbox-blank-outline::before {
  content: "\F0130";
}


.round-check .mdi-checkbox-marked::before {
  content: "\F0133";
}

</style>