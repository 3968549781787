<template>
  <v-card elevation="2" style="min-height:800px;">
    <RaceListItem :race="currentRace" />
    <v-divider class="mx-2"></v-divider>
    <v-col cols="12">

      <v-row>
        <v-tabs class="w-100 mb-tabs" v-model="tab_baken" color="#03a9f4" density="compact">
          <v-tab v-for="baken in value.BAKENS" @click="clickBaken" :key="baken">{{ baken.name }}</v-tab>
        </v-tabs>
        <v-tabs class="w-100 mb-tabs" v-model="tab_knd" color="#03a9f4" density="compact">
          <v-tab v-for="knd in bakenKnds[tab_baken]" @click="clickKnd" :key="knd">{{ knd }}</v-tab>
        </v-tabs>
      </v-row>

      <v-row>
      <!-- left select area -->
        <v-col class="pa-0" cols="6" md="6">
        <!-- select header -->
          <div class="w-100 px-4 mb-padding d-flex justify-space-between" style="width:100%%;height:44px;background-color: #EFEFEF;">
            <div class="d-flex justify-space-between" style="width:50%;">
              <div class="collItem align-self-center" style="min-width:30px;">
                <div class="d-flex flex-column align-center mx-2">馬番</div>
              </div>
              <div class="ml-2" style="width:50%;position:relative">
                <template v-if="headCheck[this.tab_baken][this.tab_knd][3][0] == 2">
                  <div class="d-flex align-center text-center w-100" style="position:absolute;top:2px;left:2px;font-size:12px;">マルチ</div>
                  <v-checkbox class="d-flex align-center round-check" @change="clickMulti" v-model:model-value="headSelects[this.tab_baken][this.tab_knd][3]" color="#03a9f4" style=""></v-checkbox>
                </template>
              </div>
            </div>
            <div class="d-flex justify-start" style="width:50%;" >
              <div v-for="index in 3" class="" style="width:33%;position:relative" :key="index" >
                <template v-if="headCheck[this.tab_baken][this.tab_knd][index-1] > 0 || true">
                  <div v-if="headCheck[this.tab_baken][this.tab_knd][index-1].length > 1" class="d-flex align-center text-center w-100" style="position:absolute;top:0;left:10px;font-size:14px;">{{ headCheck[this.tab_baken][this.tab_knd][index-1][1] }}</div>
                  <v-checkbox v-if="headCheck[this.tab_baken][this.tab_knd][index-1][0] > 0" @change="clickHead(index)" class="d-flex align-center" v-model:model-value="headSelects[this.tab_baken][this.tab_knd][index-1]" color="#03a9f4" style=""></v-checkbox>
                </template>
              </div>
            </div>
          </div>

        <!-- select -->
          <div class="d-flex w-100" :style="adjustHeight" >
          <v-list class="w-100" style="height:100%;border:1px sold black;" >
          <!-- not wakuren -->
            <template v-if="this.tab_baken != 2">
              <v-list-item class="mb-padding" v-for="(horse,index) in horses" :key="horse" style="height:44px;min-height:44px;">
                <BakenItem :horse="horse" :baken="tab_baken" :knd="tab_knd" :index="index" :isShow="isShowCheck[tab_baken][tab_knd]" :select="selects[tab_baken][tab_knd][index].btns" @clickCheck="clickCheck" :key="tab_baken+'-'+tab_knd+'-'+index" />
              </v-list-item>
            </template>
          <!-- wakuren -->
            <template v-else>
              <v-list-item class="mb-padding py-0" v-for="(waku,index) in wakus" :key="waku">
                <BakenWakuItem :Waku="waku" :baken="tab_baken" :knd="tab_knd" :index="index" :isShow="isShowCheck[tab_baken][tab_knd]" :select="selects[tab_baken][tab_knd][index].btns" @clickCheck="clickCheck" :key="tab_baken+'-'+tab_knd+'-'+index" />
              </v-list-item>
            </template>
          </v-list>
          </div>
        </v-col>

      <!-- odds -->
        <v-col class="pa-0" cols="6" md="6" style="border-left: 2px solid #e2e2e2;">
          <v-tabs class="w-100 mb-tabs" v-model="tab_sort" color="#03a9f4" style="height:44px;background-color: #EFEFEF;">
            <v-tab @click="clickSort(0)">馬番</v-tab>
            <v-tab @click="clickSort(1)">降順</v-tab>
            <v-tab @click="clickSort(2)">オッズ</v-tab>
            <v-tab @click="clickSort(3)">降順</v-tab>
          </v-tabs>
          <div class="d-flex w-100" :style="adjustHeight" >
          <v-list class="w-100" style="height:100%;" >
          <!-- < umaren  -->
            <template v-if="this.tab_baken < 3">
              <v-list-item class="mb-padding" v-for="kaime in kaimes" :key="kaime" style="height:44px;min-height:44px;">
                <BakenOddsItem :kaime="kaime" :baken="tab_baken" :key="kaime" />
              </v-list-item>
            </template>
          <!-- >= umaren -->
            <template v-else>
              <v-list-item class="mb-padding" v-for="kaime in kaimes" :key="kaime" style="height:44px;min-height:44px;">
                <BakenOddsItem3Ren :kaime="kaime" :baken="tab_baken" :key="kaime" />
              </v-list-item>
            </template>
          </v-list>
          </div>
        </v-col>

      </v-row>

    </v-col>
  </v-card>
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

import RaceListItem from "@/components/race/RaceListItem.vue"
import BakenItem from "@/components/race/BakenItem.vue"
import BakenWakuItem from "@/components/race/BakenWakuItem.vue"
import BakenOddsItem from "./BakenOddsItem.vue"
import BakenOddsItem3Ren from "./BakenOddsItem3Ren.vue"


export default {
  name: 'Baken-',
  props:{
    currentRace:{
      default:null,
    },
    hors:{
      default:[],
    }
  },
  components: {
    RaceListItem,
    BakenItem,
    BakenWakuItem,
    BakenOddsItem,
    BakenOddsItem3Ren,
  },
  data: () => ({
    value:value.VALUE,
    horses:[],
    wakus:[],
    tab_baken:0,
    tab_knd:0,
    tab_sort:0,
    kaimes:[],
    height:600,
    baseKaime:[[],[],[],[],[],[],[],[]],
    bakenKnds:[["通常"],
              ["通常"],
              ["通常","流し","BOX"],
              ["通常","流し","BOX"],
              ["通常","流し","BOX"],
              ["通常","1着流し","2着流し","BOX"],
              ["通常","軸1流し","軸2流し","BOX"],
              ["通常","1流し","2流し","3流し","1-2流","1-3流","2-3流","BOX"]],
    isShowCheck:[[[1,0,0]],
            [[1,0,0]],
            [[1,1,0],[2,1,0],[1,0,0]],
            [[1,1,0],[2,1,0],[1,0,0]],
            [[1,1,0],[2,1,0],[1,0,0]],
            [[1,1,0],[2,1,0],[1,2,0],[1,0,0]],
            [[1,1,1],[2,1,0],[2,2,1],[1,0,0]],
            [[1,1,1],[2,1,0],[1,2,0],[1,0,2],[2,2,1],[2,1,2],[1,2,2],[1,0,0]],
          ],
    headCheck:[[[[1,"1着"],[0],[0],[0]]],
              [[[1,"全て"],[0],[0],[0]]],
              [[[1,"枠1"],[1,"枠2"],[0],[0]],[[0,"1軸"],[1,"相手"],[0],[0]],[[1,"全て"],[0],[0],[0]]],
              [[[1,"1頭"],[1,"2頭"],[0],[0]],[[0,"軸"],[1,"相手"],[0],[0]],[[1,"全て"],[0],[0],[0]]],
              [[[1,"1頭"],[1,"2頭"],[0],[0]],[[0,"軸"],[1,"相手"],[0],[0]],[[1,"全て"],[0],[0],[0]]],
              [[[1,"1着"],[1,"2着"],[0],[0]],[[0,"1軸"],[1,"相手"],[0],[2]],[[1,"相手"],[0,"2軸"],[0],[2]],[[1,"全て"],[0],[0],[0]]],
              [[[1,"1着"],[1,"2着"],[1,"3着"],[0]],[[0,"1軸"],[1,"相手"],[0],[0]],[[0,"1軸"],[0,"2軸"],[1,"相手"],[0]],[[1,"全て"],[0],[0],[0]]],
              [[[1,"1着"],[1,"2着"],[1,"3着"],[0]],[[0,"1着"],[1,"相手"],[0],[2]],[[1,"相手"],[0,"2着"],[0],[2]],[[1,"相手"],[0],[0,"3着"],[2]],[[0,"1着"],[0,"2着"],[1,"相手"],[2]],[[0,"1着"],[1,"相手"],[0,"3着"],[2]],[[1,"相手"],[0,"2着"],[0,"3着"],[2]],[[1,"全て"],[0],[0],[0]]],
            ],

    selects:[],
    headSelects:[],
    selectBan:[[],[],[]],
    isMulti:false,
    isShowNum:false,
  }),
  watch:{
    currentRace:function(){
      this.fetchData();
    },
  },
  created:function(){
    this.initial();
    this.fetchData(this.tab_baken);
  },
  mounted:function(){
    this.onResize();
  },
  methods:{
    initial(){
      this.horses = JSON.parse(JSON.stringify(this.hors));
      this.horses = this.horses.sort(function(a,b){
        return (a.umaban < b.umaban ? -1 : 1);
      });

      var waku = 0;
      for(let n=0;n<this.horses.length;n++){
        if(this.horses[n].waku != 0){
          if(waku != this.horses[n].waku){
            waku = this.horses[n].waku;
            this.wakus.push({waku:waku,name:[this.horses[n].umaban+this.horses[n].name.slice(0,3)]});
          }
          else{
            this.wakus[this.wakus.length-1].name.push(this.horses[n].umaban+this.horses[n].name.slice(0,3));
          }
        }
      }

      for(let i=0;i<8;i++){
        this.selects.push([]);
        this.headSelects.push([]);
        for(let m=0;m<this.bakenKnds[i].length;m++){
          this.selects[i].push([]);
          this.headSelects[i].push([false,false,false,false]);
          for(let n=0;n<this.horses.length;n++){
            var sele = { isMulti:false, btns:[false,false,false,false] };
            this.selects[i][m].push(sele);
          }
        }
      }
    },
    clickBaken(){
      this.tab_knd = 0;

      if(this.baseKaime[this.tab_baken].length == 0){
        this.fetchData(this.tab_baken);
      }
      else{
        this.checkToselect();
        this.filterOdds();
      }
      
    },
    clickKnd(){
      this.checkToselect();
      this.filterOdds();
    },
    clickMulti(){
      this.isMulti = this.headSelects[this.tab_baken][this.tab_knd][3];

      this.filterOdds();
    },
    clickHead(num){
      var index = num-1;
      var flag = this.headSelects[this.tab_baken][this.tab_knd][index];

      this.selectBan[index] = [];
      if(flag){
        this.selects[this.tab_baken][this.tab_knd].map(function(elm){
          elm.btns[index] = true;
        });
        if(this.tab_baken == 2){
          var last = 0;
          for(let n=0;n<this.horses.length;n++){
            if(last != this.horses[n].waku){
              last = this.horses[n].waku;
              this.selectBan[index].push(""+last);
            }
          }
        }
        else{
          for(let n=0;n<this.horses.length;n++){
            this.selectBan[index].push(""+this.horses[n].umaban);
          }
        }
      }
      else{
        this.selects[this.tab_baken][this.tab_knd].map(function(elm){
          elm.btns[index] = false;
        });
      }

      this.filterOdds();

    },
    clickCheck(baken,knd,index,col,val){
      this.selects[baken][knd][index].btns[col] = val;

      var ban = ""
      if(this.tab_baken == 2){
        ban = ""+this.wakus[index].waku;
      }
      else{
        ban = ""+this.horses[index].umaban;
      }

      if(this.isShowCheck[baken][knd][col] == 2){
        this.selectBan[col] = [];
        this.selects[baken][knd].map(function(elm){
          elm.btns[col] = false;
        });
        this.selects[baken][knd][index].btns[col] = val;
      }
      
      if(val){
        if(this.selectBan[col].indexOf(ban) == -1){
          this.selectBan[col].push(ban);
        }
      }
      else{
        if(this.selectBan[col].indexOf(ban) != -1){
          let ary = this.selectBan[col];
          this.selectBan[col] = ary.filter(n => n !== ban);
        }
      }
      
      this.filterOdds();
    },
    checkToselect(){
      this.selectBan = [[],[],[]];
      for(let n=0;n<this.selects[this.tab_baken][this.tab_knd].length;n++){
        let btns = this.selects[this.tab_baken][this.tab_knd][n].btns;
        for(let i=0;i<3;i++){
          if(btns[i] === true){
            if(this.tab_baken != 2){
              let ban = ""+this.horses[n].umaban;
              this.selectBan[i].push(ban);
            }
            else{
              let waku = ""+this.wakus[n].waku;
              this.selectBan[i].push(waku);
            }
          }
        }
      }

      for(let n=0;n<3;n++){
        var flag = this.headSelects[this.tab_baken][this.tab_knd][n];
        if(flag){
          this.selectBan[n] = [];
          this.selects[this.tab_baken][this.tab_knd].map(function(elm){
            elm.btns[n] = true;
          });
          if(this.tab_baken == 2){
            var last = 0;
            for(let i=0;i<this.horses.length;i++){
              if(last != this.horses[i].waku){
                last = this.horses[i].waku;
                this.selectBan[n].push(""+last);
              }
            }
          }
          else{
            for(let i=0;i<this.horses.length;i++){
              this.selectBan[n].push(""+this.horses[i].umaban);
            }
          }
        }
      }

      this.isMulti = this.headSelects[this.tab_baken][this.tab_knd][3];
    },
    clickSort(knd){

      for(let n=0;n<this.baseKaime.length;n++){
        this.baseKaime[n] = this.sortBase(this.baseKaime[n],knd);
      }   
      //console.log(this.baseKaime[this.tab_baken]);   

      this.filterOdds();
    },
    sortBase(ary,knd){
      if(ary.length == 0)return ary;

      switch(knd){
        case 0:
          return ary.sort(function(a,b){
            return (a.index < b.index ? -1 : 1);
          });
        case 1:
          return ary.sort(function(a,b){
            return (a.index > b.index ? -1 : 1);
          });
        case 2:
          return ary.sort(function(a,b){
            if(isNaN(a.ninki))return -1;
            if(isNaN(b.ninki))return 1;
            return (a.ninki-0 < b.ninki-0 ? -1 : 1);
          });
        case 3:
          return ary.sort(function(a,b){
            if(isNaN(a.ninki))return 1;
            if(isNaN(b.ninki))return -1;
            return (a.ninki-0 > b.ninki-0 ? -1 : 1);
          });
      }
    },
    filterOdds(){

      this.isShowNum = false;
      //var num = 0;
      switch(this.tab_baken){
      //tan huku
        case 0:
        case 1:
          if(this.selectBan[0].length > 0){
            this.isShowNum = true;
            this.kaimes = this.baseKaime[this.tab_baken].filter(n => {
              for(let i=0;i<this.selectBan[0].length;i++){
                if(n.ban == this.selectBan[0][i])return true;
              }
              return false;
            });
          }
          else{
            this.kaimes = this.baseKaime[this.tab_baken];
          }
          break;  
      //waku
        case 2:
          if(this.selectBan[0].length > 0 || this.selectBan[1].length > 0){
          //normal
            if(this.tab_knd == 0){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 1){
                  if(this.selectBan[0].length > 0 && this.selectBan[1].length){
                    if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[1].indexOf(strs[1]) != -1){
                      return true;
                    }
                    else if(this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[1].indexOf(strs[0]) != -1){
                      return true;
                    }
                    return false;
                  }
                  else if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n] || strs[1] == this.selectBan[0][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[0] == this.selectBan[1][n] || strs[1] == this.selectBan[1][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                else return false;
              });
            }
          //wheel
            else if(this.tab_knd == 1){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 1){
                  if(this.selectBan[0].length > 0 && this.selectBan[1].length){
                    if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[1].indexOf(strs[1]) != -1){
                      return true;
                    }
                    else if(this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[1].indexOf(strs[0]) != -1){
                      return true;
                    }
                    return false;
                  }
                  else if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n] || strs[1] == this.selectBan[0][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[0] == this.selectBan[1][n] || strs[1] == this.selectBan[1][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                else return false;
              });
            }
          //box
            else{
              if(this.selectBan[0].length >= 2){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 1 && this.selectBan[0].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[0].length;n++){
                    if(strs[0] == this.selectBan[0][n] && strs[1] != this.selectBan[0][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }
                if(strs.length > 1 && this.selectBan[0].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[0].length;n++){
                    if(strs[1] == this.selectBan[0][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }
                return true;
              });
            }
          }
          else{
            this.kaimes = this.baseKaime[this.tab_baken];
          }
          break;
        case 3:
        case 4:
        //umaren wide
          if(this.selectBan[0].length > 0 || this.selectBan[1].length > 0){
          //normal
            if(this.tab_knd == 0){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 1){
                  if(this.selectBan[0].length > 0 && this.selectBan[1].length){
                    if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[1].indexOf(strs[1]) != -1){
                      return true;
                    }
                    else if(this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[1].indexOf(strs[0]) != -1){
                      return true;
                    }
                    return false;
                  }
                  else if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n] || strs[1] == this.selectBan[0][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[0] == this.selectBan[1][n] || strs[1] == this.selectBan[1][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                else return false;
              });

            }
          //wheeel
            else if(this.tab_knd == 1){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 1){
                  if(this.selectBan[0].length > 0 && this.selectBan[1].length){
                    if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[1].indexOf(strs[1]) != -1){
                      return true;
                    }
                    else if(this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[1].indexOf(strs[0]) != -1){
                      return true;
                    }
                    return false;
                  }
                  else if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n] || strs[1] == this.selectBan[0][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[0] == this.selectBan[1][n] || strs[1] == this.selectBan[1][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                else return false;
              });
            }
          //box
            else{
              if(this.selectBan[0].length >= 2){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 0 && this.selectBan[0].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[0].length;n++){
                    if(strs[0] == this.selectBan[0][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }
                if(strs.length > 1 && this.selectBan[0].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[0].length;n++){
                    if(strs[1] == this.selectBan[0][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }
                return true;
              });
            }
          }
          else{
            this.kaimes = this.baseKaime[this.tab_baken];
          }
          break;
        case 5:
        //umatan
          if(this.selectBan[0].length > 0 || this.selectBan[1].length > 0){
          //normal
            if(this.tab_knd == 0){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split(">");
                if(strs.length > 1){
                  if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[1] == this.selectBan[1][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                else return false;
              });

            }
          //wheeel
            else if(this.tab_knd == 1 || this.tab_knd == 2){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split(">");
                if(strs.length > 1){
                  if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n] || (this.isMulti && strs[1] == this.selectBan[0][n])){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[1] == this.selectBan[1][n] || (this.isMulti && strs[0] == this.selectBan[1][n])){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                else return false;
              });
            }
          //box
            else{
              if(this.selectBan[0].length >= 2){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split(">");
                if(strs.length > 1 && this.selectBan[0].length > 0){
                  if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[0].indexOf(strs[1]) != -1){
                    return true;
                  }
                  else return false;
                }
                return true;
              });
            }
          }
          else{
            this.kaimes = this.baseKaime[this.tab_baken];
          }
          break;
        case 6:
        //renopuku
          if(this.selectBan[0].length > 0 || this.selectBan[1].length > 0 || this.selectBan[2].length > 0){
          //normal
            if(this.tab_knd == 0){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0 && this.selectBan[2].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");

                if(strs.length > 2){
                  if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0 && this.selectBan[2].length > 0){
                    if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[1].indexOf(strs[1]) != -1 && this.selectBan[2].indexOf(strs[2]) != -1)return true;
                    else if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[1].indexOf(strs[2]) != -1 && this.selectBan[2].indexOf(strs[1]) != -1)return true;
                    else if(this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[1].indexOf(strs[0]) != -1 && this.selectBan[2].indexOf(strs[2]) != -1)return true;
                    else if(this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[1].indexOf(strs[2]) != -1 && this.selectBan[2].indexOf(strs[0]) != -1)return true;
                    else if(this.selectBan[0].indexOf(strs[2]) != -1 && this.selectBan[1].indexOf(strs[1]) != -1 && this.selectBan[2].indexOf(strs[0]) != -1)return true;
                    if(this.selectBan[0].indexOf(strs[2]) != -1 && this.selectBan[1].indexOf(strs[0]) != -1 && this.selectBan[2].indexOf(strs[1]) != -1)return true;
                    return false;
                  }
                  else if(this.selectBan[0].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[0].length;n++){
                      if(strs[0] == this.selectBan[0][n] || strs[1] == this.selectBan[0][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  if(this.selectBan[1].length > 0){
                    let flag = false;
                    for(let n=0;n<this.selectBan[1].length;n++){
                      if(strs[0] == this.selectBan[1][n] || strs[1] == this.selectBan[1][n]){
                        flag = true;
                        break;
                      }
                    }
                    if(flag == false)return false;
                  }
                  return true;
                }
                return true;
              });

            }
          //wheeel 1
            else if(this.tab_knd == 1){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 1){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 2){
                  if(this.selectBan[0].length > 0){
                    if(this.selectBan[1].length == 0){
                      if(strs.indexOf(this.selectBan[0][0]) != -1){
                        return true;
                      }
                    }
                    else if(this.selectBan[1].length > 0){
                      if(strs.indexOf(this.selectBan[0][0]) != -1){
                        for(let n=0;n<strs.length;n++){
                          if(this.selectBan[0][0] != strs[n] && this.selectBan[1].indexOf(strs[n]) == -1){
                            return false;
                          }
                        }
                        return true;
                      }
                    }
                  }
                }
                return false;
              });
            }
          //wheel 1,2
            else if(this.tab_knd == 2){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0 && this.selectBan[2].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 2){
                  if(this.selectBan[0].length > 0){
                    if(this.selectBan[1].length == 0){
                      if(strs.indexOf(this.selectBan[0][0]) != -1){
                        return true;
                      }
                    }
                    else if(this.selectBan[1].length > 0){
                      if(this.selectBan[2].length == 0){
                        if(strs.indexOf(this.selectBan[0][0]) != -1 && strs.indexOf(this.selectBan[1][0]) != -1){
                          return true;
                        }
                      }
                      else{
                        if(strs.indexOf(this.selectBan[0][0]) != -1 && strs.indexOf(this.selectBan[1][0]) != -1){
                          for(let n=0;n<this.selectBan[2].length;n++){
                            if(strs.indexOf(this.selectBan[2][n]) != -1){
                              return true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                return false;
              });
            }
          //box
            else{
              if(this.selectBan[0].length > 2){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split("-");
                if(strs.length > 2 && this.selectBan[0].length > 2){
                  if(this.selectBan[0].indexOf(strs[0]) != -1 && this.selectBan[0].indexOf(strs[1]) != -1 && this.selectBan[0].indexOf(strs[2]) != -1){
                    return true;
                  }
                  else return false;
                }
                return false;
              });
            }
          }
          else{
            this.kaimes = this.baseKaime[this.tab_baken];
          }
          break;
        case 7:
        //rentan
          if(this.selectBan[0].length > 0 || this.selectBan[1].length > 0 || this.selectBan[2].length > 0){
          //normal
            if(this.tab_knd == 0){
              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0 && this.selectBan[2].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split(">");

                if(strs.length > 0 && this.selectBan[0].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[0].length;n++){
                    if(strs[0] == this.selectBan[0][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }
                if(strs.length > 1 && this.selectBan[1].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[1].length;n++){
                    if(strs[1] == this.selectBan[1][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }
                if(strs.length > 2 && this.selectBan[2].length > 0){
                  let flag = false;
                  for(let n=0;n<this.selectBan[2].length;n++){
                    if(strs[2] == this.selectBan[2][n]){
                      flag = true;
                      break;
                    }
                  }
                  if(flag == false)return false;
                }

                return true;
              });

            }
          //wheel 1,2,3
            else if(this.tab_knd == 1 || this.tab_knd == 2 || this.tab_knd == 3){

              let rank = this.tab_knd-1;
              let oh = 1;
              let oh1 = 2;

              if(rank == 1){
                oh = 0;
              }
              else if(rank == 2){
                oh = 0;
                oh1 = 1;
              }

              if(this.selectBan[rank].length > 0 && this.selectBan[oh].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split(">");
                if(strs.length > 2){
                  if(this.selectBan[rank].length > 0){
                    if(this.selectBan[oh].length == 0){
                      if(this.selectBan[rank][0] == strs[rank] || (this.isMulti && strs.indexOf(this.selectBan[rank][0]) != 1)){
                        return true;
                      }
                    }
                    else if(this.selectBan[oh].length > 1){
                      if(this.isMulti){
                        if(strs.indexOf(this.selectBan[rank][0]) != -1){
                          if(( this.selectBan[oh].indexOf(strs[0]) != -1 && this.selectBan[oh].indexOf(strs[1]) != -1 )
                            || ( this.selectBan[oh].indexOf(strs[0]) != -1 && this.selectBan[oh].indexOf(strs[2]) != -1 )
                            || ( this.selectBan[oh].indexOf(strs[1]) != -1 && this.selectBan[oh].indexOf(strs[2]) != -1 )){
                            return true;
                          }
                        }
                      }
                      else if(this.selectBan[rank][0] == strs[rank]){
                        if(this.selectBan[oh].indexOf(strs[oh]) != -1 && this.selectBan[oh].indexOf(strs[oh1]) != -1){
                          return true;
                        }
                      }
                    }
                  }
                }
                return false;
              });
            }
          //wheel 1-2 1-3 2-3
            else if(this.tab_knd == 4 || this.tab_knd == 5 || this.tab_knd == 6){

              let rank = 0;
              let rank1 = 1
              let oh1 = 2;

              if(this.tab_knd == 5){
                rank = 0;
                rank1 = 2;
                oh1 = 1;
              }
              else if(this.tab_knd == 6){
                rank = 1;
                rank1 = 2;
                oh1 = 0;
              }

              if(this.selectBan[0].length > 0 && this.selectBan[1].length > 0 && this.selectBan[2].length > 0){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                let strs = kaime.ban.split(">");
                if(strs.length > 2){
                  if(this.selectBan[rank].length > 0){
                    if(this.selectBan[rank1].length == 0){
                      if(this.selectBan[rank][0] == strs[rank] || (this.isMulti && strs.indexOf(this.selectBan[rank][0]) != 1)){
                        if(this.selectBan[oh1].length == 0){
                          return true;
                        }
                        else{
                          if(this.isMulti){
                            for(let n=0;n<strs.length;n++){
                              if(this.selectBan[oh1].indexOf(strs[n]) != -1){
                                return true;
                              }
                            }
                          }
                          else{
                            if(this.selectBan[oh1].indexOf(strs[oh1]) != -1){
                              return true;
                            }
                          }
                        }
                      }
                    }
                    else if(this.selectBan[rank1].length > 0){
                      if(( this.selectBan[rank][0] == strs[rank] && this.selectBan[rank1][0] == strs[rank1] )
                        || (this.isMulti && strs.indexOf(this.selectBan[rank][0]) != -1 && strs.indexOf(this.selectBan[rank1][0]) != -1 )){
                        if(this.selectBan[oh1].length == 0){
                          return true;
                        }
                        else{
                          if(this.isMulti){
                            for(let n=0;n<strs.length;n++){
                              if(this.selectBan[oh1].indexOf(strs[n]) != -1){
                                return true;
                              }
                            }
                          }
                          else{
                            if(this.selectBan[oh1].indexOf(strs[oh1]) != -1){
                              return true;
                            }
                          }
                        }
                      }
                    }
                  }
                  else if(this.selectBan[rank1].length > 0){
                    if(this.selectBan[rank].length == 0){
                      if(this.selectBan[rank1][0] == strs[rank1] || (this.isMulti && strs.indexOf(this.selectBan[rank1][0]) != -1)){
                        if(this.selectBan[oh1].length == 0){
                          return true;
                        }
                        else{
                          if(this.isMulti){
                            for(let n=0;n<strs.length;n++){
                              if(this.selectBan[oh1].indexOf(strs[n]) != -1){
                                return true;
                              }
                            }
                          }
                          else{
                            if(this.selectBan[oh1].indexOf(strs[oh1]) != -1){
                              return true;
                            }
                          }
                        }
                      }
                    }
                    else if(this.selectBan[rank].length > 0){
                      if(( this.selectBan[rank][0] == strs[rank] && this.selectBan[rank1][0] == strs[rank1] )
                        || (this.isMulti && strs.indexOf(this.selectBan[rank][0]) != -1 && strs.indexOf(this.selectBan[rank1][0]) != -1 )){
                        if(this.selectBan[oh1].length == 0){
                          return true;
                        }
                        else{
                          if(this.isMulti){
                            for(let n=0;n<strs.length;n++){
                              if(this.selectBan[oh1].indexOf(strs[n]) != -1){
                                return true;
                              }
                            }
                          }
                          else{
                            if(this.selectBan[oh1].indexOf(strs[oh1]) != -1){
                              return true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                return false;
              });
            }
          //box
            else{
              if(this.selectBan[0].length > 2){
                this.isShowNum = true;
              }

              this.kaimes = this.baseKaime[this.tab_baken].filter(kaime => {
                if(this.selectBan[0].length < 3)return false;
                let strs = kaime.ban.split(">");
                if(strs.length > 2){
                  for(let n=0;n<strs.length;n++){
                    if(this.selectBan[0].indexOf(strs[n]) == -1){
                      return false;
                    }
                  }
                  return true;
                }
                return false;
              });
            }
          }
          else{
            this.kaimes = this.baseKaime[this.tab_baken].slice(0,500);
          }
          break;   
        default:
          this.kaimes = this.baseKaime[this.tab_baken];
          break;   
      }

    },
    fetchData(knd){

      const knds = ["tan","fuku","waku","ren","wide","umatan","puku","rentan"];

      var params = new URLSearchParams();
      params.append("race_id", this.currentRace.id);
      params.append("knd",knds[knd]);
        
              
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_odds_v1.1.1.php",params)      
        .then(response => {
          
        const res = response.data;
        //console.log(res);
        if(res.tan){
          let strs = res.tan.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 2){
                let ka = { index:n, ban:st[0],name:this.horses[st[0]-1].name.slice(0,3), odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.fuku){
          let strs = res.fuku.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 2){
                let ka = { index:n, ban:st[0],name:this.horses[st[0]-1].name.slice(0,3), odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.waku){
          let strs = res.waku.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 2){
                let names = st[0].split("-");
                let name = "";
                let waku0 = "";
                let waku1 = "";
                if(names.length >= 2){
                  let wakuU = names[0];
                  let wakuD = names[1];
                  for(let i=0;i<this.horses.length;i++){
                    if(wakuU == this.horses[i].waku){
                      if(waku0 != "")waku0 += " ";
                      waku0 += this.horses[i].name.slice(0,3);
                    }
                    if(wakuD == this.horses[i].waku){
                      if(waku1 != "")waku1 += " ";
                      waku1 += this.horses[i].name.slice(0,3);
                    }
                  }
                  name = waku0+"<br>"+waku1;
                }
                let ka = { index:n, ban:st[0],name:name, odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.ren){
          let strs = res.ren.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 1){
                let name = "";
                let names = st[0].split("-");
                if(names.length > 1){
                  name = this.horses[names[0]-1].name.slice(0,3)+" "+this.horses[names[1]-1].name.slice(0,3);
                }
                let ka = { index:n, ban:st[0],name:name, odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.wide){
          let strs = res.wide.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 3){
                let name = "";
                let names = st[0].split("-");
                if(names.length > 1){
                  name = this.horses[names[0]-1].name.slice(0,3)+" "+this.horses[names[1]-1].name.slice(0,3);
                }
                let ka = { index:n, ban:st[0],name:name, odd:st[1], odds_low:st[2], ninki:st[3] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.umatan){
          let strs = res.umatan.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 2){
                let name = "";
                let names = st[0].split(">");
                if(names.length > 1){
                  name = this.horses[names[0]-1].name.slice(0,3)+" "+this.horses[names[1]-1].name.slice(0,3);
                }
                let ka = { index:n, ban:st[0],name:name, odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.puku){
          let strs = res.puku.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 2){
                let name = "";
                let names = st[0].split("-");
                if(names.length > 2){
                  name = this.horses[names[0]-1].name.slice(0,3)+" "+this.horses[names[1]-1].name.slice(0,3)+" "+this.horses[names[2]-1].name.slice(0,3)
                }
                let ka = { index:n, ban:st[0],name:name, odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        if(res.rentan){
          let strs = res.rentan.split(",");
          this.baseKaime[knd] = [];
          if(strs.length > 0){
            for(let n=0;n<strs.length;n++){
              let st = strs[n].split("&");
              if(st.length > 2){
                let name = "";
                let names = st[0].split(">");
                if(names.length > 2){
                  name = this.horses[names[0]-1].name.slice(0,3)+" "+this.horses[names[1]-1].name.slice(0,3)+" "+this.horses[names[2]-1].name.slice(0,3)
                }
                
                let ka = { index:n, ban:st[0],name:name, odd:st[1], odds_low:"", ninki:st[2] };
                this.baseKaime[knd].push(ka);
              }
            }
          }
        }
        
        if(this.tab_sort != 0){
          this.baseKaime[knd] = this.sortBase(this.baseKaime[knd],this.tab_sort);
        }
        this.checkToselect();
        this.filterOdds();
        
      })
      .catch(error => {
        console.log(error);
      });
    },
    onResize(){
      this.height = window.innerHeight*0.8-0-48*2;
    },
  },
  computed:{
    adjustHeight(){
      return "height:"+this.height+"px;";
    }
  }
}

</script>

<style scoped>

@media screen and (max-width: 600px) {
  .mb-padding{
    padding-left:4px !important;
    padding-right:4px !important;
  }
}

a {
  text-decoration:none;
}

.collItem{
  width: 20%;
}

:deep(.row3text){
  font-size:12px;
  line-height: 14px;
}

:deep(.row4text){
  font-size:10px;
  line-height: 12px;
}
:deep(.row1text) {
  font-size:12px;
  height: 44px;    
  line-height: 44px;
}


table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  height:44px;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sticky2 {
  position: sticky;
  top: 0;
  left: 60px;
  border-top: none;
  border-bottom: none;
}
.sticky2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

</style>