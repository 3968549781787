<template>
  <v-card  elevation="2" style="overflow-y: scroll;">
    <h2 class="mx-3" style="margin-top:8px;">{{ `${value.PLACE_NAME[currentRace.place]} ${currentRace.course} ${currentRace.distance} ${currentRace.baba}` }}</h2>
    <v-divider class="mx-2"></v-divider>
    <v-col cols="12">
      <v-row>
        <v-col class="" cols="12" md="3">
          <iframe :src='"https://pakara-keiba.com/3d/index.html?place="+currentRace.place' class="w-100" width="400" height="250"></iframe>
        </v-col>
        
        <v-col class="" cols="12" md="3" style="position:relative;">
          <div style="max-height:250px;">
            <v-carousel height="250" show-arrows="hover" hide-delimiter-background cycle style="max-height:250px;background-color:darkgray;">
              <template v-for="image in images" :key="image">
                <v-carousel-item contain :src="image[0]">
                  <p v-if="image[1].length" class="pa-1" style="font-size:12px;background-color:rgb(255 255 255 / 60%);">{{ image[1] }}</p>
                </v-carousel-item>
              </template>
              <div class="pa-1" style="position:absolute;left:0px;bottom:0px;width:100%;background-color:rgb(255 255 255 / 60%)">
                <p v-if="imageDate" style="font-size: 12px;">JRA公式サイトより: {{ imageDate }}</p>
                <p class="d-block" style="font-size:11px;">{{ explain }}</p>
              </div>
            </v-carousel>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet class="w-100 pa-2" elevation="2">
            <div class="d-flex justify-start">
              <h3>クッション値</h3>
              <p v-if="res" class="ml-5 align-self-center">{{ res.condition.cushion }}</p>
            </div>
            <div class="w-100 mt-3 d-flex justify-space-around cusssion_grad" style="height:30px;position:relative;">
              <p v-for="index in 4" :key="index" class="align-self-center font-weight-bold">{{ 12-2*(index-1) }}</p>
              <div v-if="res && res.condition" :style="`position:absolute;width:36px;height:30px;left:${ (100-(res.condition.cushion-5)/8*100) }%;top:-15px;z-index:999;`">
                <img :src='require("@/assets/image/arrow.webp")' style="position:absolute;left:-18px;width:100%;height:100%;" />
                <p class="text-center" style="font-size:14px;position:absolute;left:-18px;top:0px;width:100%;height:100%;">{{ res.condition.cushion }}</p>
              </div>
            </div>
            <div class="w-100 mt-1 d-flex">
              <div v-for="cussion in cussions" class="rounded-pill text-center align-self-center font-weight-bold" :style="cussion[1]" style="font-size:10px;height:22px;line-height: 23px;" :key="cussion">
                {{ cussion[0] }}
              </div>
            </div>

            <h3 class="mt-3">含水率</h3>
            <span class="mr-10">ゴール前:<span v-if="ganG">{{ ganG }}</span></span><span>4コーナー:<span v-if="gan4">{{ gan4 }}</span></span>
            <div class="w-100 my-2 d-flex justify-space-around gansui_grad" style="height:30px;position:relative;" >
              <p v-for="index in 10" :key="index" class="align-self-center font-weight-bold">{{ 6+2*(index-1) }}</p>
            <!-- arrow -->
              <div v-if="ganG" :style="`position:absolute;width:20px;height:25px;left:${ ganG >= 5 ? (ganG-5.0)/20*100 : 0 }%;top:-10px;z-index:999;`">
                <img :src='require("@/assets/image/arrow.webp")' style="position:absolute;left:-10px;width:100%;height:100%;" />
                <p class="text-center font-weight-bold" style="font-size:14px;position:absolute;left:-10px;top:0px;width:100%;height:100%;">G</p>
              </div>
            <!-- arrow -->
              <div v-if="gan4" :style="`position:absolute;width:20px;height:25px;left:${ gan4 >= 5 ? (gan4-5.0)/20*100 : 0 }%;top:15px;z-index:999;`">
                <img :src='require("@/assets/image/arrow_rev.webp")' style="position:absolute;left:-10px;width:100%;height:100%;" />
                <p class="text-center font-weight-bold" style="font-size:14px;position:absolute;left:-10px;top:5px;width:100%;height:100%;">4</p>
              </div>
            </div>
            <div class="w-100 mt-3" style="height:22px;position: relative;" >
              <div v-for="(gansui,index) in this.gansuiRange" class="rounded-pill text-center align-self-center font-weight-bold" :style="`position:absolute;top;0;height:22px;font-size:12px;line-height: 22px;opacity:0.6;border:1px solid #000;left:${gansui[0]}%;width:${gansui[1]}%;background-color:${gansuiBack[index]};`" :key="gansui">
                {{ babas[index] }}
              </div>
            </div>
          </v-sheet>
        </v-col>
        
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12">
          <v-sheet class="w-100 pa-2" elevation="2">
            <v-row class="pa-2">
              <v-tabs class="w-100 mb-tabs" v-model="tab_baba" color="#03a9f4" density="compact" >
                <v-tab @click="clickTab(0)" value="0">全て</v-tab>
                <v-tab @click="clickTab(1)" value="1">良</v-tab>
                <v-tab @click="clickTab(2)" value="2">稍</v-tab>
                <v-tab @click="clickTab(3)" value="3">重</v-tab>
                <v-tab @click="clickTab(4)" value="4">不</v-tab>
              </v-tabs>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-table class="w-100">
                  <thead>
                    <tr>
                      <th v-for="head in tableHeads[0]" :key="head" class="text-center font-weight-bold" style="width:25%;height: 44px;">{{ head }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="index in value.WAKU_COLOR.length-1" class="text-center" :key="index">
                      <td :style="wakuColor(index)" style="height:44px;">{{ index }}枠</td>
                      <td  colspan="3" style="height:44px;">
                        <div class="d-flex flex-column" style="height:44px;">
                          <div class="d-flex justify-space-around text-center" style="height: 26px;">
                            <div v-for="n in 3" :key='index+","+n' class="align-self-center">{{ wakus[index-1][n-1] ? (wakus[index-1][n-1]).toFixed(1)+"%" : "" }}</div>
                          </div>
                          <div class="w-100" style="position:relative">
                            <template v-for="n in 3">
                              <div v-if="wakus[index-1][3-n]" class="anim" :style='"position:absolute;left:0;top:0;height:10px;background-color:"+cols[3-n]+";width:"+wakus[index-1][3-n]+"%;"' :key='index+","+n'></div>
                            </template>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>

              <v-col cols="12" md="6">
                <v-table class="w-100">
                  <thead>
                    <tr>
                      <th v-for="head in tableHeads[1]" :key="head" class="text-center font-weight-bold" style="width:25%;height: 44px;">{{ head }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(kyaku,index) in kyakuStrs" class="text-center" :key="kyaku">
                      <td style="height: 44px;">{{ kyaku }}</td>
                      <td  colspan="3" style="height:44px;">
                        <div class="d-flex flex-column" style="height:44px;">
                          <div class="d-flex justify-space-around text-center" style="height: 26px;">
                            <div v-for="n in 3" :key='index+","+n' class="align-self-center">{{ kyakus[index][n-1] ? (kyakus[index][n-1]).toFixed(1)+"%" : "" }}</div>
                          </div>
                          <div class="w-100" style="position:relative">
                            <template v-for="n in 3">
                              <div v-if="kyakus[index][3-n]" class="anim" :style='"position:absolute;left:0;top:0;height:10px;background-color:"+cols[3-n]+";width:"+kyakus[index][3-n]+"%;"' :key='index+","+n'></div>
                            </template>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>

            </v-row>
          </v-sheet>
        </v-col>
      </v-row>

    </v-col>
  </v-card>
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

export default {
  name: 'Data-corse',
  props:{
    currentRace:{
      default:{},
    },
  },
  components: {
    
  },
  data: () => ({
    value:value.VALUE,
    res:null,
    tab_baba:0,
    cussions:[["硬め","width:12.5%;background-color:#02796a;color:#fff;"],["やや硬め","width:25%;background-color:#b2dfdc;"],
              ["標準","width:25%;background-color:#eaf8f8;"],["やや軟か","width:12.5%;background-color:#b2dfdc;"],
              ["軟か","width:25%;background-color:#02796a;color:#fff;"]],
    tableHeads:[["枠順","単勝率","連対率","複勝率"],
                ["脚質","単勝率","連対率","複勝率"]],
    kyakuStrs:["逃げ","先行","差し","追込"],
    dartVal:["9","7-13","11-16","14"],
    sibaVal:[
        ["19","17-21","18-23","20"],
        ["13","11-15","14-18","17"],
        ["13","11-14","13-16","14"],
        ["12","10-13","12-14","13"],
        ["15","13-17","15-19","17"],
        ["15","14-18","17-21","20"],
        ["15","13-17","15-19","17"],
        ["14","12-16","14-17","16"],
        ["10","8-12","10-14","12"],
        ["15","14-18","17-21","20"],
    ],
    babas:["良","稍","重","不"],
    gansuiRange:[],
    gansuiBack:["#fef1ab","#ede0c0","#c9c3e9","#aba4e9"],
    ganG:null,
    gan4:null,
    images:[['require("@/assets/image/no_image.webp")','']],
    imageDate:null,
    explain:"",
    wakus:[],
    kyakus:[],
    cols:["#7ffefd","#81e9ff","#7fbafe"],
  }),
  watch:{

  },
  created:function(){
    //console.log(this.currentRace);

    for(let n=0;n<8;n++){
      this.wakus.push([]);
      for(let i=0;i<3;i++){
        this.wakus.push([0,0,0]);
      }
    }
    for(let n=0;n<4;n++){
      for(let i=0;i<3;i++){
        this.kyakus.push([0,0,0]);
      }
    }

    if(this.currentRace.course == "ダ"){
      for(let n=0;n<4;n++){
        if(n == 0){
          this.gansuiRange.push([0,(this.dartVal[n]-5.0)/20*100]);
        }
        else if(n == 3){
          let x = (this.dartVal[n]-5.0)/20*100;
          this.gansuiRange.push([x,100-x]);
        }
        else{
          let ary = this.dartVal[n].split("-");
          let x = (ary[0]-5.0)/20*100;
          let wi = (ary[1]-0.0-ary[0])/20*100;
          this.gansuiRange.push([x,wi]);
        }
      }      
    }
    else{
      for(let n=0;n<4;n++){
        if(n == 0){
          this.gansuiRange.push([0,(this.sibaVal[this.currentRace.place][n]-5.0)/20*100]);
        }
        else if(n == 3){
          let x = (this.sibaVal[this.currentRace.place][n]-5.0)/20*100;
          this.gansuiRange.push([x,100-x]);
        }
        else{
          let ary = this.sibaVal[this.currentRace.place][n].split("-");
          let x = (ary[0]-5.0)/20*100;
          let wi = (ary[1]-0.0-ary[0])/20*100;
          this.gansuiRange.push([x,wi]);
        }
      } 
    }

    this.fecthData(0,true);
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    clickTab(index){
      this.fecthData(index,false);
    },
    fecthData(knd,isFirst){

      var params = new URLSearchParams();
      params.append("place", this.currentRace.place);
      params.append("distance", this.currentRace.distance);
      params.append("course", this.currentRace.course);
      params.append("date", this.currentRace.date);

      const bas = ["all","良","梢","重","不"];
      params.append("baba",bas[knd]);

      //console.log(this.currentRace.course);
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_course_v2.11.0.php",params)      
        .then(response => {
        
          const res = response.data;

          //console.log(res);

          this.res = res
          if(res && res.condition){
            if(isFirst){
              if(this.currentRace.course == "ダ"){
                this.ganG = res.condition.dart_goal_wc;
                this.gan4 = res.condition.dart_wc;
              }
              else{
                this.ganG = res.condition.siba_goal_wc;
                this.gan4 = res.condition.siba_wc;
              }

              let imgs = res.image.images.split("'");
              if(imgs.length > 0){
                this.images = [];
                for(let n=0;n<imgs.length;n++){
                  let im = imgs[n].split(",");
                  this.images.push(im);
                }
                this.imageDate = `${res.image.date.slice(0,4)}/${res.image.date.slice(4,6)}/${res.image.date.slice(6,8)}`;
                this.explain = res.image.explan;
              }
              
            }
            
            this.wakus = [];
            for(let n=0;n<8;n++){
              this.wakus.push([]);
              let waku = res.waku["waku"+(n+1)];
              if(waku != null){
                let num = waku.cnt;
                if(num != 0){
                  let vals = [waku.rank1/(num-0.0)*100,(waku.rank1+waku.rank2)/(num-0.0)*100,(waku.rank1+waku.rank2+waku.rank2)/(num-0.0)*100];
                  this.wakus[n] = vals;
                }
              }
            }

            this.kyakus = [];
            let keys = ["nige","senkou","sasi","oikomi"]

            for(let n=0;n<4;n++){
              this.kyakus.push([]);
              let kyaku = res.kyaku[keys[n]];
              if(kyaku != null){
                let num = kyaku.cnt;
                if(num != 0){
                  let vals = [kyaku.rank1/(num-0.0)*100,(kyaku.rank1+kyaku.rank2)/(num-0.0)*100,(kyaku.rank1+kyaku.rank2+kyaku.rank2)/(num-0.0)*100];
                  this.kyakus[n] = vals;
                }
              }
            }
            //console.log(this.kyakus);
            
          }
                
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
  },
  computed:{
    wakuColor(){
      return (index) => {
        if(index == 2){
          return {"background-color":this.value.WAKU_COLOR[index],"color":"#fff"}
        }
        return {"background-color":this.value.WAKU_COLOR[index],"color":"#000" }
      };
    },
  }
}

</script>

<style scoped>

.anim{
  transition: .3s all;
}
.cusssion_grad {
  background: linear-gradient(to right, #4cb7ad,#eaf8f8 37.5%,#eaf8f8 62.5%, #4cb7ad);
}
.gansui_grad {
  background:linear-gradient(to right, #ffe98b,#9894fe);
}

table thead th {
  border-top : 1px solid #CCC;
  /*border-left : 1px solid #CCC;*/
  padding:4px !important;
}
table thead th:first-child {
  border-left : 1px solid #CCC;
  border-right : 1px solid #CCC;
}

table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

</style>