<template>
  <div class="w-100" style="overflow-x: scroll;">
  <v-table ref="Table" class="w-100 mtable" density="compact">
    <thead :key="horses">
      <tr>
        <th v-for="(head,index) in table_headders" :key="head" :class="headStick(index)" v-html="head[0]" :style="head[1]" height="44px" >
        </th>
        <template v-for="blad in blads" :key="blad">
          <th v-html="blad" class="text-center" style="min-width:200px;"></th>
        </template>
      </tr>
    </thead>
    <tbody :key="horses">
      <tr v-for="(horse,index) in horses" :key="horse" class="text-center">
        <td class="font-weight-bold sticky" :style='[index%2 == 1 ? "background-color: #f9f9f9;" : "background-color: #ffff;"]'>
          <p>{{ horse.umaban ? horse.umaban : "" }}</p>
          <a href="#" @click.stop.prevent="clickHorse(horse)"><p>{{ horse.name.slice(0,3) }}</p></a>
        </td>
        <td class="sticky2 font-weight-bold" :style='getMarkStyle(horse,index)'>{{ horse.mark ? decodeURI(horse.mark.mark) : "-" }}</td>
        <td v-html="getBlead(horse)" style="height:44px;overflow-y:scroll;display: block;"></td>
        <template v-for="index in (blads.length)" :key="index">
          <td style="font-size:12px;" v-html="getBlad(horse,index)"></td>
        </template>
      </tr>
    </tbody>
  </v-table>
  </div>

</template>

<script>
import value from "../../global.js"

export default {
  name: 'Race-blad',
  props:{
    horses:{
      default:[],
    },
    currentRace:{
      default:null,
    },
  },
  components: {

  },
  data: () => ({
    value:value.VALUE,
    keturyou:[50.0,25.0,12.5,6.25,3.125],
    table_headders:[["馬番","min-width:60px;"],["印","min-width:40px;"],["血量","min-width:300px;"]],
    blads:["父母 (1代)","父父 (2代)<br>父母 (2代)","母父 (2代)<br>母母 (2代)","父父父 (3代)<br>父父母 (3代)","父母父 (3代)<br>父母母 (3代)","母父父 (3代)<br>母父母 (3代)","母母父 (3代)<br>母母母 (3代)","父父父父 (4代)<br>父父父母 (4代)","父父母父 (4代)<br>父父母母 (4代)","父母父父 (4代)<br>父母父母 (4代)","父母母父 (4代)<br>父母母母 (4代)","母父父父 (4代)<br>母父父母 (4代)","母父母父 (4代)<br>母父母母 (4代)","母母父父 (4代)<br>母母父母 (4代)","母母母父 (4代)<br>母母母母 (4代)"
            ,"父父父父父 (5代)<br>父父父父母 (5代)","父父父母父 (5代)<br>父父父母母 (5代)","父父母父父 (5代)<br>父父母父母 (5代)","父父母母父 (5代)<br>父父母母母 (5代)","父母父父父 (5代)<br>父母父父母 (5代)","父母父母父 (5代)<br>父母父母母 (5代)","父母母父父 (5代)<br>父母母父母 (5代)","父母母母父 (5代)<br>父母母母母 (5代)","母父父父父 (5代)<br>母父父父母 (5代)","母父父母父 (5代)<br>母父父母母 (5代)","母父母父父 (5代)<br>母父母父母 (5代)","母父母母父 (5代)<br>父母母母 (5代)","母母父父父 (5代)<br>母母父父母 (5代)","母母父母父 (5代)<br>母母父母母 (5代)","母母母父父 (5代)<br>母母母父母 (5代)","母母母母父 (5代)<br>母母母母母 (5代)"],
  }),
  watch:{
    horses:function(){
      this.$refs.Table.scrollLeft = 0;
    },
  },
  created:function(){
    //this.initial();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    clickHorse(horse){
      this.$emit("clickHorse",horse);
    },
    getBlead(horse){
      if(!horse.kettou || !horse.kettou.same){
        return "";
      }

      var same = horse.kettou.same;

      if(Object.keys(same) == 0){
        return '<p class="row1text">アウトブリード<p>';
      }
      else{
        var css = "";
        var isSepa = false;

        if(Object.keys(same).length > 4){
          css = "row4text";
          isSepa = true;
        }
        else if(Object.keys(same).length > 2){
          css = "row3text";
        }
        else if(Object.keys(same).length == 1){
          css = "row1text";
        }

        
        var isFirst = true;
        var cnt = 0;

        var res = "";

        for(let key in same){
          let indexs = same[key];
          let str = "";
          let val = 0.0;
          let sepa = "";

          if(key == "-1")return "";
          if(isFirst)isFirst = false;
          else{
            if(!isSepa || cnt%2 == 0)sepa += "<br>";
            else sepa += ", ";
          }

          
          for(let i=0;i<indexs.length;i++){
            val += this.keturyou[indexs[i]-1];
            if(str == "")str += ""+indexs[i];
            else str += "x"+indexs[i];
          }

          let redCss = "";
          if(val == 18.75)redCss = "text-red font-weight-bold";

          res += `<span class="${redCss}">${sepa}${key} ${val.toFixed(2)}% ${str}</span>`;

          cnt += 1;
        }
        return `<p class="${css}">${res}</p>`;
      }
    },
    getBlad(horse,num){
      const index = num-1;

      if(!horse.kettou){
        return "";
      }

      const kettou = horse.kettou.kettou;
      let nu = index*2;
      var osu = "";
      if(kettou.length > nu){
        osu = kettou[nu].kettou;
      }

      var mesu = "";
      if(kettou.length > nu+1){
        mesu = kettou[nu+1].kettou;
      }

      return `<p class="osu">${osu}</p><p class="mesu">${mesu}</p>`;

    },
  },
  computed:{
    headStick(){
      return (index) => {
        if(index == 0){
          return "text-center sticky stick_head_back";
        }
        else if(index == 1){
          return "text-center sticky2 stick_head_back";
        }
        else{
          return "text-center";
        }
      }
    },
    getMarkStyle(){
      return (horse,index) => {
        var str = ""
        if(index%2 == 1){
          str += "background-color: #f9f9f9;"
        } 
        else{
          str += "background-color: #ffff;";
        }
        if(horse.mark && horse.mark.isRed){
          str += "color:red;";
        }

        return str;
      }
    },
  }
}

</script>

<style scoped>
a {
  color:blue;
  text-decoration:none;
}
:deep(.osu){
  font-weight:bold;
  color: #4cc9ff;
}
:deep(.mesu){
  font-weight:bold;
  color: #ff67c2;
}
:deep(.waku){
  width:16px;
  height:12px;
  display:inline-block;
  font-weight:bold;
  border:1px solid lightgray;
  font-size: 10px;
}

:deep(.row3text){
  font-size:12px;
  line-height: 14px;
}

:deep(.row4text){
  font-size:10px;
  line-height: 12px;
}
:deep(.row1text) {
  font-size:12px;
  height: 44px;    
  line-height: 44px;
}

:deep(.kyaku span) {
  display:inline-block;
  width:60px;
}

:deep(.rank_border){
  border:1px solid black;
}
:deep(.rank_box){
  width:44px;
  height: 38px;
}
:deep(.rank_box_rank){
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}
:deep(.rank_box_ninki){
  font-size: 12px;
}

table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}
table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  height:44px;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sticky2 {
  position: sticky;
  top: 0;
  left: 60px;
  border-top: none;
  border-bottom: none;
}
.sticky2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.stick_head_back{
}
</style>