<template>
          <v-card v-if="currentRace" elevation="2" style="min-height:500px;">
            <v-row class="mx-1 my-2" cols="12">
              <div class="w-100 d-flex justify-space-between">
              <h3 class="mx-2">{{ currentRace.date.slice(4,6)+'月'+currentRace.date.slice(-2)+'日 '+(currentRace.time ? currentRace.time+'発走' : '') }}</h3>
              <p v-if="currentRace.state && currentRace.state > 1 && currentRace.state < 5" class="mr-2">{{ updateTime.length > 0 ? updateTime+"更新" : "" }}</p>
              </div>
              <RaceListItem :race="currentRace" />
            </v-row>
            <v-col class="px-4 my-0 mb-padding" cols="12">

              <v-row class="" style="margin-left:0px;margin-top:2px;">
                <v-btn @click="clickBaken" class="mx-2 my-1" style="margin-left:0px;">馬券</v-btn>
                <v-btn @click="clickStats" class="mx-2 my-1">統計</v-btn>
                <v-btn @click="clickCorse" class="mx-2 my-1">コース</v-btn>
                <v-btn @click="clickOdds" class="mx-2 my-1">オッズ変動</v-btn>
                <!-- <v-btn class="mx-2 my-1">レースメモ</v-btn> -->
                <v-btn class="mx-2 my-1" @click="clickMovie">レース映像</v-btn>
                <v-btn @click="clickCorner" class="mx-2 my-1">コーナー順位</v-btn>
              </v-row>

              <v-row class="mx-1" style="margin-top:20px;margin-bottom:10px;">
                <v-tabs class="w-100 mb-tabs" v-model="tab_race" color="#03a9f4" density="compact">
                  <v-tab value="race_1" >出馬表</v-tab>
                  <v-tab @click="clickHashira" value="race_2">馬柱</v-tab>
                  <v-tab @click="clickKiyu" value="race_3">騎手柱</v-tab>
                  <v-tab @click="clickBlad" value="race_4">血統</v-tab>
                  <v-tab @click="clickCyoukyou" value="race_5">調教</v-tab>
                  <v-tab @click="clickSisuu" value="race_6">指数</v-tab>
                  <v-tab value="race_7">掲示板</v-tab>
                </v-tabs>

                <v-tabs class="w-100 mb-tabs" v-model="tab_sort" color="#03a9f4" density="compact">
                  <v-tab v-for="(sort,index) in sorts" :key="sort" @click="changeSort(index)" :value="index">{{ sort }}</v-tab>
                </v-tabs>
              </v-row>

              <v-row class="mx-0">
                <v-img src="https://placehold.jp/2000x200.png"
                    class="ads"
                    contain
                    width="2000"
                    height="100"
                    style="background-color: darkgray;"
                />
              </v-row>

                <v-window disabled v-model="tab_race" style="min-height: 500px;margin-top:20px;">
                  <v-window-item value="race_1">
                    <RaceCard :currentRace="currentRace" :horses="horses" @clickHorse="clickHorse" @clickKisyu="clickKisyuLink" />
                  </v-window-item>
                  
                  <v-window-item value="race_2">
                    <RaceHorseHists ref="Hashira" :currentRace="currentRace" :horses="horses" @clickHorse="clickHorse" @clickHist="clickHist" />
                  </v-window-item>

                  <v-window-item value="race_3">
                    <RaceKisyuHashira ref="Kisyu" :currentRace="currentRace" :horses="horses" @clickHorse="clickHorse" @clickKisyu="clickKisyuLink" @clickHist="clickHist" />
                  </v-window-item>

                  <v-window-item value="race_4">
                    <RaceBlad ref="Blad" :currentRace="currentRace" :horses="horses" @clickHorse="clickHorse" />
                  </v-window-item>

                  <v-window-item value="race_5">
                    <RaceTraining ref="Cyoukyou" :currentRace="currentRace" :horses="horses" @clickWc="clickWc" @clickHorse="clickHorse" />
                  </v-window-item>

                  <v-window-item value="race_6">
                    <RaceIndex ref="Index" :currentRace="currentRace" :horses="horses" @clickHorse="clickHorse" />
                  </v-window-item>

                  <v-window-item value="race_7">
                    <RaceBoard ref="Board" :currentRace="currentRace" />
                  </v-window-item>
                </v-window>

                <v-row class="mx-0 my-2">
                <v-img src="https://placehold.jp/2000x200.png"
                    class="ads"
                    contain
                    width="2000"
                    height="100"
                    style="background-color: darkgray;"
                />
              </v-row>

            </v-col>
          </v-card>


    <!-- baken diaolog -->
      <v-dialog v-model="bakenDialog" class="w-dialog">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="bakenDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <Baken :currentRace="currentRace" :hors="horses" :key="currentRace"/>
      </v-dialog>

    <!-- horse diaolog -->
      <v-dialog v-model="horseDialog" class="w-dialog">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="horseDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <Horse isDialog="true" @clickHist="clickHist" :umaId="horseProps.id" :umaName="horseProps.name" :key="horseProps"/>
      </v-dialog>

    <!-- kisyu diaolog -->
      <v-dialog v-model="kisyuDialog" class="w-dialog">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="kisyuDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <Kisyu isDialog="true" :kisyuId="kisyuProps.id" :kisyuName="kisyuProps.name" />
      </v-dialog>

    <!-- stats diaolog -->
      <v-dialog v-model="statsDialog" class="w-dialog">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="statsDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card>
          <StatsRace isSearch="true" :Place="currentRace.place" :Course="currentRace.course" :Jyoutai="currentRace.baba" :Distance="currentRace.distance" />
        </v-card>
      </v-dialog>

    <!-- corse diaolog -->
      <v-dialog v-model="corseDialog" class="w-dialog">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="corseDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card>
          <Corse  :currentRace="currentRace" :key="currentRace"/>
        </v-card>
      </v-dialog>

    <!-- odds diaolog -->
      <v-dialog v-model="oddsDialog" class="w-dialog">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="oddsDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card>
          <OddsHist  :currentRace="currentRace" :Horses="horses" :key="currentRace"/>
        </v-card>
      </v-dialog>

      <!-- race diaolog -->
      <v-dialog v-model="raceDialog" class="w-dialog pa-0">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="raceDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <RaceDetaileContainer :CurrentRace="modalRace" :IsModal="true" :key="modalRace.id"/>
      </v-dialog>

      <!-- corner diaolog -->
      <v-dialog v-model="cornerDialog" class="w-dialog pa-0">
        <div class="d-flex justify-end">
          <v-btn class="mb-2" icon @click="cornerDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <Corner :CurrentRace="currentRace" :Horses="horses" :key="currentRace"/>
      </v-dialog>
  
</template>

<script>
import RaceListItem from "../../components/race/RaceListItem.vue"
import RaceCard from "../../components/race/RaceCard.vue"
import RaceHorseHists from "../../components/race/RaceHorseHists.vue"
import RaceKisyuHashira from "@/components/race/RaceKisyuHashira.vue"
import RaceBlad from "@/components/race/RaceBlad.vue"
import RaceTraining from "@/components/race/RaceTraining.vue"
import RaceIndex from "@/components/race/RaceIndex.vue"
import RaceBoard from "@/components/race/RaceBoard.vue"

import Baken from "@/components/race/Baken.vue"
import Horse from "@/components/stats/Horse.vue"
import Kisyu from "@/components/stats/Kisyu.vue"
import StatsRace from "@/components/stats/StatsRace.vue"
import Corse from "@/components/race/Corse.vue"
import OddsHist from "@/components/race/OddsHist.vue"
import Corner from "./Corner.vue"

import RaceDetaileContainer from "@/components/race/RaceDetaileContainer.vue"

//import Coming from "@/components/Coming.vue"

import value from "../../global.js"
import axios from 'axios'


export default {
  name: 'Race-detaile-cont',
  props:{
    CurrentRace:{
      default:null,
    },
    IsModal:{
      default:false,
    },
    TabKnd:{
      default:0,
    },
  },
  components: {
    RaceListItem,
    RaceCard,
    RaceHorseHists,
    RaceKisyuHashira,
    RaceBlad,
    RaceTraining,
    RaceIndex,
    RaceBoard,

    Baken,
    Horse,
    Kisyu,
    StatsRace,
    Corse,
    OddsHist,
    Corner,

    RaceDetaileContainer,
    //Coming,
  },
  data: () => ({
    value:value.VALUE,
    currentRace:null,
    updateTime:"",
    interval:null,
    tab_race:0,
    tab_sort:0,
    horses:[],
    sorts:["着順","馬番","印","体重","増減","残3f","人気"],
    bakens:[],
    isHashira:false,
    isKisyuhasira:false,
    isBlad:false,
    isCyoukyou:false,
    isCyoukyouWc:false,
    isSisuu:false,

    bakenDialog:false,
    horseDialog:false,
    statsDialog:false,
    corseDialog:false,
    kisyuDialog:false,
    oddsDialog:false,
    cornerDialog:false,
    raceDialog:false,

    modalRace:null,

    horseProps:{
      id:0,
      name:"",
    },
    kisyuProps:{
      id:0,
      name:"",
    }
  }),
  watch:{
    CurrentRace(){

      if(this.interval != null){
        clearInterval(this.interval);
        this.interval = null;
      }

      this.currentRace = this.CurrentRace;
      this.isHashira = false;
      this.isKisyuhasira = false;
      this.isBlad = false;
      this.isCyoukyou = false;
      this.isCyoukyouWc = false;
      this.isSisuu = false;

      this.tab_race = 0;

      this.horses = [];

      this.initial();
    },
  },
  created:function(){
    if(this.tab_race != this.TabKnd){
      var that = this;
      setTimeout(function(){
        that.tab_race = that.TabKnd;
      },300);
    }
    
    if(this.CurrentRace != null){
      this.currentRace = this.CurrentRace;
      this.initial();
    }
  },
  mounted:function(){

  },
  methods:{
    initial:function(){  
      this.getRaceHorses(this.currentRace.id);
    },
    clickHashira(){
      if(!this.isHashira){
        this.isHashira = true;

        var params = new URLSearchParams();
        params.append("date", this.currentRace.date);
        params.append("round", this.currentRace.round);
        params.append("place", this.currentRace.place);
        
        if(this.horses.length > 0){
          for(let n=0;n<this.horses.length;n++){
            params.append("name"+n, this.horses[n].uma_id);
          }
        }
              
        axios.post(process.env.VUE_APP_POST_URL+"/race/get_hashira_v2.8.0.php",params)      
          .then(response => {
          
            const res = response.data;

            if(res.length > 0){
              for(let n=0;n<this.horses.length;n++){
                let index = res.findIndex(e => e.name === this.horses[n].name);
                
                if(index != -1){
                  this.horses[n].hashiras = res[index].hashira;
                }
              }
            }

            this.$refs.Hashira.initial();
          })
          .catch(error => {
            console.log(error);
          }
        );
      }
    },
    clickHist(race){
      this.modalRace = race;
      this.raceDialog = true;
    },
    clickKiyu(){
      if(!this.isKisyuhasira){
        this.isKisyuhasira = true;

        var params = new URLSearchParams();
        params.append("date", this.currentRace.date);
        params.append("round", this.currentRace.round);
        params.append("place", this.currentRace.place);
        
        if(this.horses.length > 0){
          for(let n=0;n<this.horses.length;n++){
            params.append("name"+n, this.horses[n].kisyu_id);
          }
        }
              
        axios.post(process.env.VUE_APP_POST_URL+"/race/get_kisyu_v2.8.0.php",params)      
          .then(response => {
          
            const res = response.data;
            //console.log(res);

            if(res.length > 0){
              for(let n=0;n<this.horses.length;n++){
                let index = res.findIndex(e => e.name === this.horses[n].kisyu_id);
                
                if(index != -1){
                  this.horses[n].kisyuHashiras = res[index].hashira;
                }
              }
            }

            this.$refs.Kisyu.initial();
          })
          .catch(error => {
            console.log(error);
          }
        );
      }
    },
    clickBlad(){
      if(!this.isBlad){
        this.isBlad = true;

        var params = new URLSearchParams();
        params.append("date", this.currentRace.date);
        params.append("round", this.currentRace.round);
        params.append("place", this.currentRace.place);
        
        if(this.horses.length > 0){
          for(let n=0;n<this.horses.length;n++){
            params.append("name"+n, this.horses[n].uma_id);
          }
        }
              
        axios.post(process.env.VUE_APP_POST_URL+"/race/get_keitou_v2.6.0.php",params)      
          .then(response => {
          
            const res = response.data;

            if(res.length > 0){
              for(let n=0;n<res.length;n++){
                //console.log(res[n]);
                if(!res[n].kettou)continue;

                var sameId = [];
                var dic = {};
                for(let i=0;i<res[n].kettou.length;i++){
                  let id = res[n].kettou[i].kettou;
                  if(id && id != ""){
                    if(dic[id] == undefined)dic[id] = 0;
                    dic[id] += 1;
                    if(dic[id] == 2)sameId.push(id);
                  }
                  else{
                    sameId.push("-1");
                    continue;
                  }
                }

                res[n].inbliead = sameId;

                let same = {};
                for(let i=0;i<sameId.length;i++){
                  let id = sameId[i];
                  if(id == "-1"){
                    same[id] = [-1];
                    continue;
                  }

                  for(let m=0;m<62;m++){
                    if(res[n].kettou.length > m){
                      let name = res[n].kettou[m].kettou;
                      if(name && id == name){
                        let index = 1;
                        if(m > 29)index = 5;
                        else if(m > 13)index = 4;
                        else if(m > 5)index = 3;
                        else if(m > 1)index = 2;

                        if(same[name] == undefined)same[name] = [];
                        same[name].push(index);
                      }
                    }
                  }
                }

                res[n].same = same;
                
              }


              for(let n=0;n<this.horses.length;n++){
                let index = res.findIndex(e => e.name === this.horses[n].uma_id);
                
                if(index != -1){
                  this.horses[n].kettou = res[index];
                }
              }
            }

            this.$refs.Blad.initial();
          })
          .catch(error => {
            console.log(error);
          }
        );
      }
    },
    clickCyoukyou(){
      if(!this.isCyoukyou){
        this.isCyoukyou = true;

        var params = new URLSearchParams();
        params.append("date", this.currentRace.date);
        params.append("round", this.currentRace.round);
        params.append("place", this.currentRace.place);
        
        if(this.horses.length > 0){
          for(let n=0;n<this.horses.length;n++){
            params.append("name"+n, this.horses[n].uma_id);
          }
        }
              
        axios.post(process.env.VUE_APP_POST_URL+"/race/get_cyoukyou.php",params)      
          .then(response => {
          
            const res = response.data;
            //console.log(res);

            if(res.length > 0){
              for(let n=0;n<this.horses.length;n++){
                let index = res.findIndex(e => e.name === this.horses[n].uma_id);
                
                if(index != -1){
                  this.horses[n].cyoukyou = res[index].cyoukyou;
                }
              }
            }

            this.$refs.Cyoukyou.initial();
          })
          .catch(error => {
            console.log(error);
          }
        );
      }
    },
    clickWc(){
      if(!this.isCyoukyouWc){
        this.isCyoukyouWc = true;

        var params = new URLSearchParams();
        params.append("date", this.currentRace.date);
        params.append("round", this.currentRace.round);
        params.append("place", this.currentRace.place);
        
        if(this.horses.length > 0){
          for(let n=0;n<this.horses.length;n++){
            params.append("name"+n, this.horses[n].uma_id);
          }
        }
              
        axios.post(process.env.VUE_APP_POST_URL+"/race/get_cyoukyou_wc.php",params)      
          .then(response => {
          
            const res = response.data;
            //console.log(res);

            if(res.length > 0){
              for(let n=0;n<this.horses.length;n++){
                let index = res.findIndex(e => e.name === this.horses[n].uma_id);
                
                if(index != -1){
                  this.horses[n].cyoukyouWc = res[index].cyoukyou;
                }
              }
            }

            this.$refs.Cyoukyou.initial();
          })
          .catch(error => {
            console.log(error);
          }
        );
      }
    },
    clickSisuu(){
      if(!this.isSisuu){
        this.isSisuu = true;

        var params = new URLSearchParams();
        params.append("date", this.currentRace.date);
        params.append("round", this.currentRace.round);
        params.append("place", this.currentRace.place);
        
        if(this.horses.length > 0){
          for(let n=0;n<this.horses.length;n++){
            params.append("name"+n, this.horses[n].uma_id);
          }
        }
              
        axios.post(process.env.VUE_APP_POST_URL+"/speed/race_speed2.php",params)      
          .then(response => {
          
            const res = response.data;
            //console.log(res);

            if(res.length > 0){
              for(let n=0;n<this.horses.length;n++){
                let index = res.findIndex(e => e.name === this.horses[n].uma_id);
                
                if(index != -1){
                  this.horses[n].speed = res[index].speed;
                }
              }
            }

            this.$refs.Index.initial();
          })
          .catch(error => {
            console.log(error);
          }
        );
      }
    },
    clickMovie(){
      if(this.currentRace && this.currentRace.jra_id){
        let id = this.currentRace.jra_id;

        let mid = String(id.slice(0,4)+id.slice(-6).slice(0,2));
        let mid2 = String(id.slice(-8).slice(0,2)+id.slice(-4));
        
        window.open("https://pakara-keiba.com/race_movie/index.php?target="+mid+mid2,"movie","menubar=0,width=500,height=328,top=50,left=100000");
        //console.log(mid+mid2);
      }
    },
    clickBaken(){
      this.bakenDialog = true;
    },
    clickHorse(horse){
      this.horseProps = {
        id:horse.uma_id,
        name:horse.name,
      },

      this.horseDialog = true;
    },
    clickKisyuLink(horse){
      this.kisyuProps = {
        id:horse.kisyu_id,
        name:horse.kisyu,
      }
      this.kisyuDialog = true;
    },
    clickStats(){
      this.statsDialog = true;
    },
    clickCorse(){
      this.corseDialog = true;
    },
    clickOdds(){
      this.oddsDialog = true;
    },
    clickCorner(){
      this.cornerDialog = true;
    },
    changeTab:function(index){
      console.log(this.tab+","+index);
      
    },
    changeSort:function(knd){

      this.horses = this.horses.sort(function(a,b){
        return (a.umaban < b.umaban ? -1 : 1);
      });
      
      switch(knd){
        case 0:
          this.horses = this.horses.sort(function(a,b){
            return (a.rank < b.rank ? -1 : 1);
          });
          break;
        case 1:
          return;
        case 2:
          this.horses = this.horses.sort(function(a,b){
            return (a.umaban < b.umaban ? -1 : 1);
          });
          break;
        case 3:
          this.horses = this.horses.sort(function(a,b){
            return (a.weight < b.weight ? 1 : -1);
          });
          break;
        case 4:
          this.horses = this.horses.sort(function(a,b){
            return (Math.abs(a.zougen) < Math.abs(b.zougen) ? -1 : 1);
          });
          break;
        case 5:
          this.horses = this.horses.sort(function(a,b){
            return (a.f3 < b.f3 ? -1 : 1);
          });
          break;
        case 6:
          this.horses = this.horses.sort(function(a,b){
            return (a.ninki < b.ninki ? -1 : 1);
          });
          break;
        default:
          break;
      }
    },
    getRaceHorses:function(raceId){
      this.horses = [];

      var params = new URLSearchParams();
      params.append("race_id", raceId);

      if(this.$store.getters.user.isPurchase){
        params.append("myid",this.$store.getters.user.myid);
      }
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_race_detail_v3.0.0.php",params)      
        .then(response => {
        
          const res = response.data;
          
          if(res.race){
            this.currentRace = res.race;
            
            if(this.currentRace.state > 1 && this.currentRace.state < 5){
              if(this.interval == null){
                this.interval = setInterval(() =>{
                  this.getRaceHorses(this.currentRace.id);
                },60000);
              }
            }
          }

          if(res.horses.length > 0){
            this.horses = res.horses;
            this.changeSort(this.tab_sort);

            let time = this.horses[0].update.split(" ");
            if(time.length > 1){
              this.updateTime = time[1].slice(0,5);
            }
          }

          if(res.marks && res.marks.length > 0){
            for(let n=0;n<res.marks.length;n++){
              let hor = this.horses.find(elem => {
                if(elem.uma_id == res.marks[n].umaId){
                  return true;
                }
                return false;
              });
              hor.mark = res.marks[n];
            }
            console.log(this.horses);
          }
                
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
    getDays(date){
      console.log(date);
      var params = new URLSearchParams();
      params.append("date", date);
            
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_race_day.php",params)      
        .then(response => {
        
          const res = response.data;

          console.log(res);
          if(res){
            this.day = res;
            this.initial();
          }   
        })
        .catch(error => {
          console.log(error);
        }
      );
    },
  },
  computed:{
    backColor(){
      return (place) => {
        return {"background-color":this.value.PLACE_COLORS[place]};
      };
    },
    backTabColor(){
      return (index,place) => {
        if(index == this.tab){
          return {"background-color":this.value.PLACE_COLORS[place],"color":"#fff"};
        }
        else{
          return {"background-color":"#fff","color":this.value.PLACE_COLORS[place]};
        }
      };
    },
    adjustHeight(){
      return "height:"+this.height+"px;";
    },
  }
}
</script>

<style scoped>
.w-dialog{
  width:90%;
}

.ads{
  display: none;
}


@media screen and (max-width: 600px) {
  .w-dialog{
    width:100%;
  }
  .v-container{
    padding:0;
  }
  .mb-padding{
    padding-left:0px !important;
    padding-right:0px !important;
  }
  
  /*.v-overlay__content{
    left:0;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
  }*/
}

</style>