<template>
  <v-carousel class="only_pc" height="300" hide-delimiters cycle :show-arrows="false">
		<v-carousel-item cover>
			<v-img cover height="300" src="@/assets/image/header.webp" gradient="to top right, rgba(10,115,201,.10), rgba(5,42,72,.7)" alt="競馬ヘッダー0"></v-img>
		</v-carousel-item>
		<v-carousel-item cover>
			<v-img cover height="300" src="@/assets/image/header2.webp" gradient="to top right, rgba(10,115,201,.10), rgba(5,42,72,.7)" alt="競馬ヘッダー1"></v-img>
		</v-carousel-item>
		<v-carousel-item cover>
			<v-img cover height="300" src="@/assets/image/header1.webp" gradient="to top right, rgba(10,115,201,.10), rgba(5,42,72,.7)" alt="競馬ヘッダー2"></v-img>
		</v-carousel-item>
  </v-carousel>

	<!-- <v-img class="only_sp" cover height="150" src="@/assets/image/headerSp.webp" gradient="to top right, rgba(10,115,201,.10), rgba(5,42,72,.7)" alt="競馬ヘッダー0"></v-img> -->

  <v-container style="overflow-y: hidden;">
    <v-row>
			<!-- left -->
      <v-col cols="12" md="8" class="px-0" style="min-height:1500px;">
        <!-- this week race -->
        <v-col style="min-height:500px;">
          <v-card title="今週の注目レース" class="" elevation="2">
            <v-list v-if="g1Races.length > 0" style="padding:0;">
              <template v-for="(item,sec) in g1Races" :key="item">
                <v-list-subheader><p class="text-h7">{{ item.title }}</p></v-list-subheader>
                <template v-for="(race,row) in item.races" :key="race">
                  <RaceListItem @clickRace="clickRace(sec,row)" :race="race"/>
                </template>
              </template>
            </v-list>
					</v-card>
        </v-col>
            
        <!-- race date -->
        <v-col style="min-height:1000px;">
					<!-- <v-btn @click="calenderDialog = true;">日付から選択</v-btn> -->
          <v-card title="レース日程" class="my-5" elevation="2">
            <v-list v-if="races.length > 0">
              <template v-for="(months,sec) in races" :key="months">
                <v-list-subheader style="background-color:rgb(235 235 235);"><p class="text-h6 font-weight-bold">{{ months.title }}</p></v-list-subheader>
                <template v-for="(day,row) in months.days" :key="day">
                  <RaceDateItem @clickPlace="clickPlace" :day="day" :sec="sec" :row="row"/>
                </template>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-col>

      <!-- right -->
      <v-col class="my-3" cols="12" md="4">
        
				<v-row>
					<v-col cols="12">
						<v-sheet class="w-100 px-2" style="border: 1px solid #f0f0f0;">
							<h3>アプリ配信中</h3>
							<a href="https://pakara-keiba.com/useragent.html">
								<v-img src="@/assets/store.webp" width="448" height="123" class="w-100" alt="pakaraダウンロード"/>
							</a>
						</v-sheet>
						<v-parallax src="@/assets/image/back.webp" class="text-center text-white" gradient="to bottom right, rgba(0,0,0,.0), rgba(0,0,0,.60)" alt="背景">
							<h3 class="mt-10 d-inline-block pb-1" style="border-bottom:1px double #fff;">pakara 競馬の予想、投票と収支管理</h3>
							<p class="my-3" style="font-size: 0.9rem;">JRA中央競馬のオッズやデータをリアルタイムにお届け</p>
							<div class="my-10 text-black" >
								<v-btn prepend-icon="mdi-apple" class="mx-2 my-2" href="https://itunes.apple.com/jp/app/id1588373836?mt=8" style="width:150px;max-width:150px;">AppStore</v-btn>
								<v-btn prepend-icon="mdi-google-play" class="mx-2 my-2" href="https://play.google.com/store/apps/details?id=jp.co.rsquare.pakara" style="width:150px;max-width:150px;">Google Play</v-btn>
							</div>
							<div style="width:70%;margin:100px auto;position:relative;">
								<v-img src="@/assets/image/iphone.webp" width="261" height="500" style="width:100%;z-index:9999;" alt="pakaraスクリーンショット"/>
								<v-carousel :show-arrows="false" hide-delimiters cycle style="position:absolute;left:6.5%;top:2.5%;width:87.5%;height:98.9%;border-radius:25px;">
									<v-carousel-item cover >
										<v-img src="@/assets/image/back0.webp" width="1179" height="2556" cover alt="出馬表"/>
									</v-carousel-item>
									<v-carousel-item cover>
										<v-img src="@/assets/image/back1.webp" width="1179" height="2556" cover alt="馬詳細データ"/>
									</v-carousel-item>
									<v-carousel-item cover >
										<v-img src="@/assets/image/back2.webp" width="1179" height="2556" cover alt="オッズ変動"/>
									</v-carousel-item>
								</v-carousel>
							</div>
						</v-parallax>
						<div class="w-100 px-2 py-5 text-center" style="border: 1px solid #f0f0f0;" >
							<h3 class="mb-5 d-inline-block pb-1" style="border-bottom:1px double #000;">pakaraについて</h3>
							<v-expansion-panels class="w-100 mb-3 text-start">
								<v-expansion-panel title="レース情報"
									text="オッズや人気をリアルタイムに表示。馬柱、騎手柱、調教、血統、指数に加えてレースの枠傾向、統計情報が表示されます">
								</v-expansion-panel>
								<v-expansion-panel title="レース映像"
									text="全てのレースの映像が見れる。パトロール映像も表示。">
								</v-expansion-panel>
								<v-expansion-panel class="my-" title="統計情報"
									text="細かい条件指定ができ、レースの荒れ率、単勝率、連対率、複勝率、配当割合、体重分布、体重増減が分かります">
								</v-expansion-panel>
								<v-expansion-panel class="my-" title="馬柱"
									text="無料版でも前9走を表示。有料版では全レースが表示されます">
								</v-expansion-panel>
								<v-expansion-panel class="my-" title="馬券オッズ表示"
									text="馬番を選択すると、リアルタイムでオッズと点数を表示します。選択した馬券は馬番、オッズ順で並び替えもできます">
								</v-expansion-panel>
								<v-expansion-panel class="my-" title="投票"
									text="アプリ版には投票機能あり。資金の自動配分機能も">
								</v-expansion-panel>
								<v-expansion-panel class="my-" title="収支管理"
									text="アプリ版には収支管理機能あり。馬券QRの読み込みや、履歴も自動で読み込め収支の分析まであります。">
								</v-expansion-panel>
								<v-expansion-panel title="ニュース"
									text="競馬関連の最新情報や、まとめ、掲示板を表示。">
								</v-expansion-panel>
							</v-expansion-panels>
						</div>
						<v-parallax src="@/assets/image/back3.webp" class="text-center pa-2 text-white" gradient="to bottom right, rgba(0,0,0,.0), rgba(0,0,0,.60)" alt="背景">
							<v-img src="@/assets/logo.webp" width="256" height="256" class="mt-8" style="width:30%;margin:0 auto;" alt="pakaraアイコン"></v-img>
							<h3 class="mt-5 d-inline-block pb-1" style="border-bottom:1px double #fff;">pakara インストール</h3>
							<p class="my-3" style="font-size: 0.9rem;">iOS、Android 配信中</p>
							<div class="my-8 text-black" >
								<v-btn prepend-icon="mdi-apple" class="mx-2 my-2" href="https://itunes.apple.com/jp/app/id1588373836?mt=8" style="width:150px;max-width:150px;">AppStore</v-btn>
								<v-btn prepend-icon="mdi-google-play" class="mx-2 my-2" href="https://play.google.com/store/apps/details?id=jp.co.rsquare.pakara" style="width:150px;max-width:150px;">Google Play</v-btn>
							</div>
						</v-parallax>
					</v-col>
				</v-row>
			
      </v-col>
			
    </v-row>
  </v-container>

	<v-dialog v-model="calenderDialog" class="w-dialog pa-0">
    <div class="d-flex justify-end">
      <v-btn class="mb-2" icon @click="calenderDialog = false" >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
		<Calender />
  </v-dialog>

</template>


<script>
/* eslint-disable no-console */
import RaceListItem from "@/components/race/RaceListItem.vue"
import RaceDateItem from "@/components/race/RaceDateItem.vue"
import Calender from "@/components/race/Calender.vue"

import axios from 'axios';
import router from "@/router";


export default {
  name: 'top-',

  components: {
    RaceListItem,
    RaceDateItem,
		Calender,
  },
	head:{
		title:"pakara - 競馬の総合情報サイト",
		meta:[
			{ name:"description",content:"pakaraは競馬総合情報サイトです。JRA全レースの出馬表やオッズ、レース映像が見られます。競走馬や騎手のデータベースや統計など予想に役立つ情報が満載" },
			{ property: "og:type", content: "website" },
			{ property: "og:url", content: process.env.VUE_APP_DOMAIN+"/" },
			{ property: "og:title", content: "pakara - 競馬の総合情報サイト" },
			{ property: "og:description", content: "pakaraは競馬総合情報サイトです。JRA全レースの出馬表やオッズ、レース映像が見られます。競走馬や騎手のデータベースや統計など予想に役立つ情報が満載" },
			{ property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
			{ property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
		],
	},
  data: () => ({
    races:[],
    g1Races:[],
    lastLoadDate:"",
		calenderDialog:false,
  }),
  created:function(){
    this.getRaceDateList("0");
  },
	methods:{
    clickRace:function(sec,row){

			var race = this.g1Races[sec].races[row]
			var isHit = false;
			var day = null;

			for(var n=0;n<this.races.length;n++){
				for(var i=0;i<this.races[n].days.length;i++){
					if(this.races[n].days[i].date == race.date){
						isHit = true;
						day = this.races[n].days[i];
						break;
					}
				}
				if(isHit){
					break;
				}
			}

			if(day != null){
				this.$store.dispatch("setDay",day);
				this.$router.push({name:"Race",params:{date:day.date,place:race.place,round:race.round,name:race.race_name}});
			}

		},
		getUrl(){
			return this.url;
		},
		clickPlace:function(sec,row,place){
			var day = this.races[sec].days[row];
							
			this.$store.dispatch("setDay",day);
			router.push({name:"Race",params:{date:day.date,place:day.places[place].place,round:1,name:day.places[place].races[0].race_name}});
		},
		getRaceDateList:function(date){
			if(date == this.lastLoadDate){
				return;
			}

			var params = new URLSearchParams();
			params.append('page', "0");
			params.append('date', date);
							
			axios.post(process.env.VUE_APP_POST_URL+"/race/get_race_data_v2.8.0.php",params)
				.then(response => {
					
				const res = response.data;
				//console.log(res);

				const now = new Date();

				var isFuture = true;
				var secTitle = "";

				//console.log(now);

				if(res.length > 0){
					for(let n=0;n<res.length;n++){
					//for(let n=0;n<2;n++){
						let da = res[n];

						if(isFuture){
							var raceDate = new Date(da.year+"/"+da.day.slice(0,2)+"/"+da.day.slice(-2)+" 23:59");
							//raceDate.setDate(raceDate.getDate()+3);
							if(raceDate != null && raceDate > now){
								da.isFuture = true;
							}
							else{
								da.isFuture = false;
								isFuture = false;
							}
						}
						else{
							da.isFuture = false;
						}

						const dateStr = da.year+"年"+da.date.slice(-4).slice(0,2)+"月";
						if(secTitle != dateStr){
							secTitle = dateStr;
							let sec = [];
							sec.title = dateStr;
							sec.days = [da];
							this.races.push(sec);
						}
						else{
							this.races[this.races.length-1].days.push(da);
						}

						if(isFuture){
							if(da.places.length > 0){
								let g1Sec = [];
								g1Sec.title = dateStr+da.day.slice(-2)+"日("+da.week+")";
								g1Sec.races = [];
								let isHit = false;
								for(let i=0;i<da.places.length;i++){
									let ras = da.places[i].races;
									if(ras.length > 0){
										for(let m=0;m<ras.length;m++){
											if(ras[m].grade != 0 || ras[m].round == 11){
												g1Sec.races.push(ras[m]);
												isHit = true;
											}
										}
									}
								}

								if(isHit){
									this.g1Races.push(g1Sec);
								}
							}
						}
					}

					//console.log(this.races);
				}
									
			})
			.catch(error => {
				console.log(error);
			});
		},
	},  
}
</script>

<style scoped>

.only_pc{
	display: block;
}
.only_sp{
	display: none;
}

@media screen and (max-width: 600px) {
  .v-container{
    padding:2px !important;
		overflow-x: hidden;
  }
	.only_pc{
		display: none;
	}
	.only_sp{
		display: block;
	}
}
</style>