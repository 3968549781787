<template>
  <div class="w-100" style="overflow-x: scroll;">
    <!-- <v-row cols="12">
      <v-col cols="9">
        <v-textarea clearable label="気軽に投稿してね🎵" style="height:70px;" ></v-textarea>
      </v-col>
      <v-col class="py-7">
        <v-btn>送信</v-btn>
      </v-col>
    </v-row> -->
    <v-list style="height:600px;">
      <template v-for="(comment,index) in comments" :key="comment">
        <CommentItem :comment="comment" :index="comments.length-index" :currentRace="currentRace" @clickIcon="clickIcon"/>
      </template>
    </v-list>
  </div>

  <!-- acount diaolog -->
  <v-dialog v-model="acountDialog" class="w-dialog" style="max-width: 750px;">
    <div class="d-flex justify-end">
      <v-btn class="mb-2" icon @click="acountDialog = false" >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card>
      <OtherAcount :UserId="userId" />
    </v-card>
  </v-dialog>

</template>

<script>
import value from "../../global.js"
import axios from 'axios'

import CommentItem from "../../components/news/CommentItem.vue"
import OtherAcount from "../acount/OtherAcount.vue"

export default {
  name: 'Race-board',
  props:{
    currentRace:{
      default:null,
    },
  },
  components: {
    CommentItem,
    OtherAcount,
  },
  data: () => ({
    value:value.VALUE,
    comments:[],
    acountDialog:false,
    userId:"",
  }),
  watch:{
    currentRace:function(){
      this.fetchData();
    },
  },
  created:function(){
    this.fetchData();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    clickIcon(comment){
      this.userId = comment.user_id;
      this.acountDialog = true;
    },
    fetchData(){
      this.comments = [];

      var params = new URLSearchParams();
      params.append("page", "0");
      params.append("race_id", this.currentRace.id);
        
              
      axios.post(process.env.VUE_APP_POST_URL+"/news/get_race_board_v3.0.0.php",params)      
        .then(response => {
          
        const res = response.data;

        this.comments = res;
      })
      .catch(error => {
        console.log(error);
      });
    },
    clickHorse(){

    },
  },
  computed:{
  }
}

</script>

<style scoped>
a {
  text-decoration:none;
}

:deep(.row3text){
  font-size:12px;
  line-height: 14px;
}

:deep(.row4text){
  font-size:10px;
  line-height: 12px;
}
:deep(.row1text) {
  font-size:12px;
  height: 44px;    
  line-height: 44px;
}


table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  height:44px;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sticky2 {
  position: sticky;
  top: 0;
  left: 60px;
  border-top: none;
  border-bottom: none;
}
.sticky2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

</style>