//import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router"


import Top from "./views/Top.vue"
import News from "./views/News.vue"

import Race from "./views/race/RaceDetaile.vue"

import Stats from "./views/Stats.vue"

//import Horse from "@/components/stats/Horse.vue"

import Coming from "./components/Coming.vue"

const router = createRouter({
  history:createWebHistory(),
  //base:"/dist/",
  routes:[
    { path:"/",name:"Top",component:Top },
    { path:"/stats/race",name:"Stats",component:Stats },
    { path:"/expenses",name:"expenses",component:Coming },
    { path:"/news",name:"News",component:News },
    { path:"/race/:date/:place/:round/:name",name:"Race",component:Race },
    { path:"/stats/horse/:umaId/:umaName",name:"Horse",component:Stats },
    { path:"/stats/kisyu/:kisyuId/:kisyuName",name:"Kisyu",component:Stats },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } 
    else {
      return { top: 0 }
    }
  },
})

export default router
//Redirect permanent /index.html https://pakara-keiba.com/info/